import AppHelper from '@/helpers'

export class InterviewService {
  constructor (Vue) {
    this.Vue = Vue
  }

  get (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http

    var promise = $http.get(AppHelper.genUrl(vue.$mcf.api.interview.get, {id: data.id}), {
      params: data
    })

    return promise
  }

  list (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.get(vue.$mcf.api.interview.list, {
      params: data
    })

    return promise
  }
}


