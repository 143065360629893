import AppHelper from '@/helpers'

export class CourseService {
  caches = {};
  constructor (Vue) {
    this.Vue = Vue
  }

  getCache(key) {
    return this.caches[key];
  }

  setCache(key, value) {
    this.caches[key] = value;
  }

  get (data) {
    let sv = this
    let vue = sv.Vue
    let $http = sv.Vue.$http
    let promise = $http.get(AppHelper.genUrl(vue.$mcf.api.course.get, {id: data.id}), {params: data})

    return promise
  }

  list (data) {
    let sv = this
    let vue = sv.Vue
    let $http = sv.Vue.$http
    let promise = $http.get(vue.$mcf.api.course.list, {params: data})
    return promise
  }

  create (data) {
    let sv = this
    let vue = sv.Vue
    let $http = sv.Vue.$http
    let promise = $http.post(vue.$mcf.api.course.create, data)
    return this.handleResponse(promise);
  }

  update (data) {
    let sv = this
    let vue = sv.Vue
    let $http = sv.Vue.$http
    let promise = $http.patch(AppHelper.genUrl(vue.$mcf.api.course.update, {id: data.id}), data)
    return this.handleResponse(promise);
  }

  order (data) {
    let sv = this
    let vue = sv.Vue
    let $http = sv.Vue.$http
    let promise = $http.patch(vue.$mcf.api.course.order, data)
    return this.handleResponse(promise);
  }

  delete (data) {
    let sv = this
    let vue = sv.Vue
    let $http = sv.Vue.$http
    let promise = $http.delete(AppHelper.genUrl(vue.$mcf.api.course.delete, {id: data.id}), data)
    return promise
  }

  duplicate (id) {
    let sv = this
    let vue = sv.Vue
    let $http = sv.Vue.$http
    let promise = $http.post(AppHelper.genUrl(vue.$mcf.api.course.duplicate, {id: id})).then((res) => res.data)
    return promise
  }

  courseCategories () {
    let sv = this
    let vue = sv.Vue
    let $http = sv.Vue.$http
    const cached = this.getCache('courseCategories');
    if (cached) return cached;
    let promise = $http.get(vue.$mcf.api.course.categories);
    this.setCache('courseCategories', promise);
    return promise.then(res => res.data);
  }

  simpleCourseList() {
    let sv = this
    let vue = sv.Vue
    let $http = sv.Vue.$http
    const cached = this.getCache('courseSimpleList');
    if (cached) return cached;
    let promise = $http.get(vue.$mcf.api.course.list, {params: {
        simple: 1
      }});
    this.setCache('courseSimpleList', promise);
    return promise.then(res => res.data);
  }

  handleResponse (promise) {
    let sv = this
    let vue = sv.Vue
    promise.catch((error) => {
      const res = error.response;
      if (res && res.data.data && res.data.data.errors && res.data.data.errors) {
        if (res.data.data.errors instanceof Array) {
          res.data.data.errors.forEach((err) => {
            vue.$toasted.error(`Error: ${err.message}`);
          });
        }
      }
    });
    return promise
    .then(res => {
      vue.$toasted.success('Course has been saved!');
      return res;
    });
  }
}
