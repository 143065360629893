// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import Vuex from 'vuex'
import App from './App'
import router from './router'

import axios from 'axios'
import VueAxios from 'vue-axios'
import VeeValidate from 'vee-validate'

import VueLocalStorage from 'vue-local-storage'
import * as uiv from 'uiv'
import draggable from 'vuedraggable'
import VueCollapse from 'vue2-collapse'
import BootstrapVue from 'bootstrap-vue'

import moment from 'moment'
import VueMomentJS from 'vue-momentjs'

import mainConfig from './config'
import OdinAuth from './auth'
import GlobalComponent from './components/global'
import mainServices from './services'
import UUID from 'vue-uuid'
import Toasted from 'vue-toasted';

import './assets/scss/style.scss'

import jQuery from 'jquery'

global.jQuery = jQuery
let $ = global.jQuery;
window.$ = $;
window.jQuery = $;

Vue.config.productionTip = false;
Vue.use(Toasted, {
  duration: 4000,
  position: 'bottom-center'
})
Vue.use(uiv);
Vue.use(draggable);
Vue.use(VueCollapse);
Vue.use(UUID);
Vue.use(BootstrapVue);

Vue.use(Vuex);
Vue.use(VueLocalStorage);
Vue.use(VueAxios, axios);
Vue.use(VueMomentJS, moment);

Vue.use(mainConfig);
Vue.use(mainServices, router);
Vue.use(OdinAuth, {fireBase: Vue.mcf.fireBase});
Vue.use(GlobalComponent);

const configVeeValidate = {
  errorBagName: 'errors', // change if property conflicts
  fieldsBagName: 'fields',
  delay: 0,
  locale: 'en',
  dictionary: null,
  strict: true,
  classes: true,
  classNames: {
    touched: 'touched', // the control has been blurred
    untouched: 'untouched', // the control hasn't been blurred
    valid: 'valid', // model is valid
    invalid: 'invalid', // model is invalid
    pristine: 'pristine', // control has not been interacted with
    dirty: 'dirty' // control has been interacted with
  },
  events: 'input|blur',
  inject: true,
  validity: false,
  aria: true
};

Vue.use(VeeValidate, configVeeValidate);
// Vue.config.productionTip = false
// Vue.use(uiv)
// Vue.use(draggable)
// Vue.use(VueCollapse)
// Vue.use(BootstrapVue);

Vue.config.productionTip = false;

let app;
if (!OdinAuth.getCloudFlareToken()) {
  app = new Vue({
    el: '#app',
    router,
    components: { App },
    template: '<App/>'
  });
} else {
  OdinAuth.getAppTokenFromCloudFlareToken().then(res => {
    if (res.status === 200) {
      OdinAuth.setAccessToken(res.data.data.token);
      OdinAuth.setRefreshToken(res.data.data.refresh_token);
      OdinAuth.initApp().then(res => {
        if (res && !app) {
          app = new Vue({
            el: '#app',
            router,
            components: { App },
            template: '<App/>'
          });
        }
      })
    } else {
      try {
        document.querySelector('.loading-div').style.display = 'none';
        setTimeout(() => {
          alert(res.response.data.data);
          OdinAuth.logout();
        }, 100);
      } catch (e) {
        setTimeout(() => {
          alert('Something went wrong');
        }, 100)
      }
    }
  })
}
