<template>
	<div>
    <Navbar></Navbar>
    <game-list></game-list>
	</div>
</template>

<script>
  import Navbar from '@/components/Navbar'
  import GameList from '@/components/game/GameList'

  export default {
    components: {Navbar, GameList},
    name: 'Game',
    data: function () {
      return {

      }
    },
    methods: {

    }
  }
</script>

