<template>
  <svg width="29px" height="16px" viewBox="0 0 29 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 46.2 (44496) - http://www.bohemiancoding.com/sketch -->
    <title>Triangle</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="SignUp_01B" transform="translate(-655.000000, -445.000000)" stroke-width="2" stroke="#E2E2E2">
        <g id="Group-2" transform="translate(186.000000, 293.000000)">
          <polygon id="Triangle" transform="translate(483.500000, 159.729167) rotate(180.000000) translate(-483.500000, -159.729167) " points="483.5 153 495 166.458333 472 166.458333"></polygon>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'IconTriangle'
  }
</script>
