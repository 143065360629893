<template>
    <nav class="center">
        <ul class="pagination" v-bind:class="{disabled: active}">
            <li class="page-item cus-pointer" v-bind:class="{disabled: (pagination.current_page===1)}" v-on:click="jumpToPage(1)">
                <span class="page-link">Previous</span>
            </li>
            <li class="page-item cus-pointer" v-if="(pagination.current_page-3) >= 1">
                <span class="page-link" v-on:click="jumpToPage(pagination.current_page-3)">{{pagination.current_page - 3}}</span>
            </li>
            <li class="page-item cus-pointer" v-if="(pagination.current_page-2) >= 1">
                <span class="page-link" v-on:click="jumpToPage(pagination.current_page-2)">{{pagination.current_page - 2}}</span>
            </li>
            <li class="page-item cus-pointer" v-if="(pagination.current_page-1) >= 1">
                <span class="page-link" v-on:click="jumpToPage(pagination.current_page-1)">{{pagination.current_page - 1}}</span>
            </li>
            <li class="page-item active"><span class="page-link">{{pagination.current_page}}</span></li>

            <li class="page-item cus-pointer" v-if="(pagination.current_page+1) <= pagination.last_page">
                <span class="page-link" v-on:click="jumpToPage(pagination.current_page+1)">{{pagination.current_page+1}}</span>
            </li>
            <li class="page-item cus-pointer" v-if="(pagination.current_page+2) <= pagination.last_page">
                <span class="page-link" v-on:click="jumpToPage(pagination.current_page+2)">{{pagination.current_page+2}}</span>
            </li>
            <li class="page-item cus-pointer" v-if="(pagination.current_page+3) <= pagination.last_page">
                <span class="page-link" v-on:click="jumpToPage(pagination.current_page+3)">{{pagination.current_page+3}}</span>
            </li>
            <li class="page-item cus-pointer" v-bind:class="{disabled: (pagination.current_page===pagination.last_page)}">
                <span class="page-link" v-on:click="jumpToPage(pagination.last_page)">Next</span>
            </li>
        </ul>
    </nav>
</template>
<script>

export default {
  name: 'Pagination',
  props: {
    active: {
      type: Boolean
    },
    pagination: {
      type: Object
    },
    onChangePage: {
      type: Function
    }
  },
  methods: {
    jumpToPage (page) {
      if (!this.active) return;
      this.$router.replace({ query: { ...this.$route.query, page } });
      this.onChangePage(page);
    }
  }
}
</script>
