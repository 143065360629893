import AppHelper from '@/helpers'

export class PlaylistVideoService {
  constructor (Vue) {
    this.Vue = Vue
  }

  get(data, params = null) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http

    var promise = $http.get(AppHelper.genUrl(vue.$mcf.api.video.get, {id: data.id}), {
      params
    });

    return promise
  }

  list(data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.get(vue.$mcf.api.video.list, {
      params: data
    })

    return promise
  }

  search(data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.post(vue.$mcf.api.video.search, data)

    return promise
  }

  create(data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.post(vue.$mcf.api.video.create, data)

    return promise
  }

  update(data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.patch(AppHelper.genUrl(vue.$mcf.api.video.update, {id: data.id || data.id}), data)

    return promise
  }

  delete (id) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.delete(AppHelper.genUrl(vue.$mcf.api.video.update, {id: id}))

    return promise
  }
}
