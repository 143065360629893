<template>
  <svg class="i-svg" width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 46.2 (44496) - http://www.bohemiancoding.com/sketch -->
    <title>Stroke 3059 + Stroke 3060 + Stroke 3061</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linejoin="round">
      <g id="SignUp_01B" transform="translate(-263.000000, -523.000000)" stroke="#F04135" stroke-width="1.5">
        <g id="Group-4" transform="translate(186.000000, 500.000000)">
          <g id="Stroke-3059-+-Stroke-3060-+-Stroke-3061" transform="translate(78.000000, 24.000000)">
            <path d="M15.6507826,7.86754783 C15.7238261,12.0936348 12.226087,15.5795478 8,15.653287 C3.7746087,15.7270261 0.42226087,12.3600696 0.349217391,8.13398261 C0.275478261,3.9085913 3.7746087,0.422678261 8,0.34893913 C12.2253913,0.2752 15.5777391,3.64285217 15.6507826,7.86754783 L15.6507826,7.86754783 Z" id="Stroke-3059" stroke-linecap="round"></path>
            <path d="M8,9.04347826 L8,4.17391304" id="Stroke-3060" stroke-linecap="round"></path>
            <path d="M8.34782609,11.4782609 C8.34782609,11.6702609 8.192,11.826087 8,11.826087 C7.808,11.826087 7.65217391,11.6702609 7.65217391,11.4782609 C7.65217391,11.2862609 7.808,11.1304348 8,11.1304348 C8.192,11.1304348 8.34782609,11.2862609 8.34782609,11.4782609 L8.34782609,11.4782609 Z" id="Stroke-3061"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'IconExclamation'
  }
</script>
