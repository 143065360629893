<template>
  <div>
    <nav class="navbar navbar-fixed-top">
      <div class="container-fluid">
        <div class="navbar-header">
          <button type="button" class="navbar-toggle" ref="navCollapseButton" @click="toggleMobileNav('nav-open')">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar icon-bar-1"></span>
            <span class="icon-bar icon-bar-2"></span>
            <span class="icon-bar icon-bar-3"></span>
          </button>
          <router-link :to="{name: 'home'}" class="navbar-brand">
            <img src="../assets/logo-45.svg">
          </router-link>
        </div>
        <collapse v-model="isShow" id="navbar" class="navbar-collapse collapse">
          <div>
            <ul class="nav">
              <li v-for="nav in menu" v-if="!nav.hidden">
                <router-link v-if="nav.routeName"
                             :to="{name: nav.routeName}"
                             :class="{ active: currentRoute === nav.routeName }">{{ nav.title }}</router-link>
                <a :class="{ active: currentRoute === nav.routeName }"
                   @click="nav.onClick && nav.onClick($event)"
                   v-if="nav.url" v-bind:href="nav.url" class="">{{ nav.title }}</a>
              </li>
            </ul>
          </div>
        </collapse>
      </div>
    </nav>
    <div class="loading-div" v-show="isLoading" style="opacity: 0.8">
      <icon-loading></icon-loading>
    </div>
  </div>
</template>

<script>
  import IconLoading from '@/components/global/IconLoading';

  export default {
    name: 'Navbar',
    props: [],
    components: {IconLoading},
    data () {
      return {
        isShow : false,
        downloadURL: null,
        isLoading: false,
        isCoursesAdmin: false,
        isAdmin: false,
        isModerator: false,
        isUsersAdmin: false,
        menu: [
          {
            id: 'courses',
            title: 'Courses',
            routeName: 'home'
          },
          // {
          //   title: 'Game Changers',
          //   routeName: 'game'
          // },
          {
            id: 'fittest',
            title: 'Fit Test moderation',
            routeName: 'moderation',
          },
          {
            id: 'exportUserData',
            title: 'Export user data',
            url: 'null'
          },
          {
            id: 'user',
            title: 'Users',
            routeName: 'users'
          },
          {
            id: 'certifications',
            title: 'Certifications',
            routeName: 'certificates'
          },
          {
            id: 'logout',
            title: 'Logout',
            url: '#'
          }
        ],
        currentRoute: ''
      }
    },
    methods: {
      toggleMobileNav: function (toggleClass) {
        this.isShow = !this.isShow;

        if (this.$refs.navCollapseButton.classList.contains(toggleClass)) {
          this.$refs.navCollapseButton.classList.remove(toggleClass)
        }
        else {
          this.$refs.navCollapseButton.classList.add(toggleClass)
        }
      },
      forceFileDownload (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a');
        const date = this.$moment();
        link.href = url
        link.setAttribute('download', `users_data_${date.format('YYYYMMDD')}.csv`) // or any other extension
        document.body.appendChild(link)
        link.click()
      },
      async exportUsers () {
        this.isLoading = true;
        try {
          const res = await this.$services.User.exportUsers();
          this.forceFileDownload(res);
        } catch (e) {
          console.log(e)
        } finally {
          this.isLoading = false;
        }
      }
    },
    beforeCreate () {
      // console.log(this.menu);
      // if ()
      // let vm = this;
      // if (vm.menu[3]) {
      //   vm.menu[3].url = '#';
      //   vm.menu[3].onClick = (e) => {
      //     e.preventDefault();
      //     this.exportUsers();
      //   };
      // }
    },
    created () {
      this.isCoursesAdmin = this.$auth.isAuthenticatedAsCoursesAdmin();
      this.isAdmin = this.$auth.isAuthenticatedAsAdmin();
      this.isModerator = this.$auth.isAuthenticatedAsModerator();
      this.isUsersAdmin = this.$auth.isAuthenticatedAsUsersAdmin();
      this.currentRoute = this.$router.history.current.name;
      let vm = this;

      vm.menu.forEach(tab => {
        tab.hidden = true;
        switch (tab.id) {
          case 'logout':
            tab.hidden = false;
            tab.onClick = (e) => {
              e.preventDefault();
              this.$auth.logout();
            };
            break;
          case 'fittest':
            if (vm.$mcf.disableFittestFeature) {
              tab.hidden = true;
            }
            if (this.isModerator || this.isAdmin) {
              tab.hidden = false;
            }
            break;
          case 'exportUserData': tab.hidden = !this.isAdmin;
            tab.url = '#';
            tab.onClick = (e) => {
              e.preventDefault();
              this.exportUsers();
            };
            break;
          case 'courses': tab.hidden = !this.isCoursesAdmin; break;
          case 'user': tab.hidden = !this.isUsersAdmin; break;
          case 'certifications': tab.hidden = !this.isAdmin; break;
        }
      })
    }
  }
</script>
