import AppHelper from '@/helpers'

export class UserService {
  constructor (Vue) {
    this.Vue = Vue
  }

  get (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http

    if (sv.Vue.$mcf.useFakeData) {
      return new Promise((resolve, reject) => {
        resolve({
          data: {
            data: {
              success: false,
              user: {
                email: 'johnny@shiroad.com',
                password: 'incorrect',
                type: 'personal_trainer',
                first_name: 'Johnny',
                last_name: 'Sm',
                gender: 'male',
                dob: '1989-10-10',
                phone: '098696969',
                country: 'AU',
                state: 'CO',
                city: 'Sydney',
                height: '150',
                height_unit: 'ft',
                weight: '100',
                weight_unit: 'kg',
                photo: {
                  id: 1,
                  url: 'https://3.img-dpreview.com/files/p/TS600x450~sample_galleries/7104657277/0963506908.jpg'
                },

                experience_most_recent_resume_id: 12,
                experience_looking_for_job: 1,
                experience_willing_to_relocate: 1,
                experience_years_of_experience: 4,
                experience_certifications: ['Zend', 'JAVA'],
                experience_competitions_entered: ['Google', 'AWS', 'Oracle'],
                experience_competitions_won: ['AWS'],
                experience_level_of_education: 3,

                interview: [
                  {
                    id: 1,
                    name: 'Why did you become a trainer?',
                    video_id: '073f6f94b332827d6e883bff2922dd1e'
                  },
                  {
                    id: 2,
                    name: 'What do you love about group training?',
                    video_id: '32f5af6ebb55a93b552e298498482fda'
                  },
                  {
                    id: 3,
                    name: 'What is your coaching style?',
                    video_id: '44e5408abbd063b7dc67a94234209c59'
                  },
                  {
                    id: 4,
                    name: 'What do you do to keep yourself motivated?',
                    video_id: null
                  },
                  {
                    id: 5,
                    name: 'In a training session, what are your top 3 priorities in order of importance?',
                    video_id: '44e5408abbd063b7dc67a94234209c59'
                  },
                  {
                    id: 6,
                    name: 'Name 5 critical qualities of a great trainer',
                    video_id: null
                  }
                ],
              }
            }
          }
        })
      })
    }

    var promise = $http.get(AppHelper.genUrl(vue.$mcf.api.user.get, {id: data._uid}), {
      params: data
    })

    return promise
  }

  list (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.get(vue.$mcf.api.user.list, {
      params: data
    })
    return promise.then((res) => {
      return this.toUserObject(res);
    });
  }

    activeStaff (id, status) {
        var sv = this;
        var vue = sv.Vue;
        var $http = sv.Vue.$http;
        var promise = $http.post(AppHelper.genUrl(vue.$mcf.api.user.activeStaff, { id: id }), {status: status});
        return promise.then(res => res.data);
    }

    deactiveStaff (id, status) {
        var sv = this;
        var vue = sv.Vue;
        var $http = sv.Vue.$http;
        var promise = $http.post(AppHelper.genUrl(vue.$mcf.api.user.deactiveStaff, { id: id }), {status: status});
        return promise.then(res => res.data);
    }

  toUserObject (res) {
    const data = res.data;
    data.data.items = data.data.items.map((item) => {
      if (item.user_studio_details && item.user_studio_details.length > 0) {
        item.studio_detail = item.user_studio_details[0];
      }
      return item;
    });
    return data;
  }

  create (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.post(vue.$mcf.api.user.create, data)

    return promise
  }

  update (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.patch(AppHelper.genUrl(vue.$mcf.api.user.update, {id: data.id}), data)

    return promise
  }

  getCurrentUser () {
    let sv = this;
    let vue = sv.Vue;
    let $http = sv.Vue.$http;
    let $auth = sv.Vue.$auth;

    if ($auth.isAuthenticated()) {
      let promise = $http.get(vue.$mcf.api.user.getCurrentUser);
      return promise
    } else {
      return Promise.reject(false)
    }
  }

  forgot (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.post(vue.$mcf.api.user.forgot, data)

    return promise
  }

  reset (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.post(vue.$mcf.api.user.reset, data)

    return promise
  }

  changePassword (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.patch(AppHelper.genUrl(vue.$mcf.api.user.changePassword, {id: data.id}), data)

    return promise
  }


  //-----------------------------------------------------------------------------------------------------------
  //------------ Interview
  /**
   * Get a specific question-interview of user
   * @param data
   * @returns {*}
   */
  getInterView (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.get(AppHelper.genUrl(vue.$mcf.api.user.interview.get, {user_id: data._uid, id: data.id}), {
      params: data
    })

    return promise
  }


  /**
   * Update a specific question-interview of user
   * @param data
   * @returns {*}
   */
  updateInterView (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.patch(AppHelper.genUrl(vue.$mcf.api.user.interview.update, {user_id: data._uid, id: data.id}), data)

    return promise
  }

  /**
   * Create a question-interview of user
   * @param data
   * @returns {*}
   */
  createInterView (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.post(AppHelper.genUrl(vue.$mcf.api.user.interview.create, {user_id: data._uid}), data)

    return promise
  }

  /**
   * Get list question-interview of user
   * @param data
   * @returns {*}
   */
  listInterView (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.get(AppHelper.genUrl(vue.$mcf.api.user.interview.list, {user_id: data._uid, id: data.id}), {
      params: data
    })

    return promise
  }


  /**
   * Update a specific question-interview of user
   * @param data
   * @returns {*}
   */
  deleteInterView (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.delete(AppHelper.genUrl(vue.$mcf.api.user.interview.delete, {user_id: data._uid, id: data.id}), data)

    return promise
  }


  //-----------------------------------------------------------------------------------------------------------
  //------------ Fit test
  /**
   * get all user fit test
   * @param data
   * @returns {*}
   */
  getUserFitTestList (data) {
    let sv = this
    let vue = sv.Vue
    let $http = sv.Vue.$http
    return $http.get(vue.$mcf.api.user.user_fit_test.get, {params: {
      page: data.page,
      status: data.status,
      search: data.email
      }})
  }

  /**
   * Get a specific fit test of user
   * @param data
   * @returns {*}
   */
  getUserFitTest (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.get(AppHelper.genUrl(vue.$mcf.api.user.fit_test.get, {user_id: data._uid, id: data.id}), {
      params: data
    })

    return promise
  }
  /**
   * Update a specific fit test of user
   * @param data
   * @returns {*}
   */
  updateUserFitTest (data) {
    let sv = this
    let vue = sv.Vue
    let $http = sv.Vue.$http
    return $http.post(AppHelper.genUrl(vue.$mcf.api.user.user_fit_test.update, {user_fit_test_id: data.user_fit_test_id}), data)
  }

  /**
   * Create a fit test of user
   * @param data
   * @returns {*}
   */
  createUserFitTest (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.post(AppHelper.genUrl(vue.$mcf.api.user.fit_test.create, {user_id: data._uid}), data)

    return promise
  }

  /**
   * Get list fit test of user
   * @param data
   * @returns {*}
   */
  listUserFitTest (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.get(AppHelper.genUrl(vue.$mcf.api.user.fit_test.list, {user_id: data._uid, id: data.id}), {
      params: data
    })

    return promise
  }


  /**
   * Delete a specific fittest of user
   * @param data
   * @returns {*}
   */
  deleteUserFitTest (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.delete(AppHelper.genUrl(vue.$mcf.api.user.fit_test.delete, {user_id: data._uid, id: data.id}), data)

    return promise
  }


  //-----------------------------------------------------------------------------------------------------------
  //------------ Course
  /**
   * ( This API for get data from user_courses_answers )
   * List user course
   * @param data
   * @returns {*}
   */
  listUserCourse (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.get(AppHelper.genUrl(vue.$mcf.api.user.course.list, {user_id: data._uid}), {
      params: data
    })

    return promise
  }

  /**
   * ( This API for get data from user_courses )
   * Get all course that user has completed or is learning
   *  - user id
   *  - course id
   *  - completed flag
   *  - progress
   *  - certificate file
   *
   * @param data
   * @returns {*}
   */
  getUserCourses (userId, params = {}) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.get(AppHelper.genUrl(vue.$mcf.api.user.course.list, {user_id: userId}), {
      params
    })

    return promise.then((res) => res.data && res.data.data);
  }

  completeUserCourses (userId, courseIds) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.post(AppHelper.genUrl(vue.$mcf.api.user.course.complete, {user_id: userId}), {
      courses: courseIds,
    })
    return promise.then((res) => res.data && res.data.data);
  }

  uncompleteUserCourses (userId, params = {}) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.get(AppHelper.genUrl(vue.$mcf.api.user.course.get_uncomplete, {user_id: userId}), {
      params
    })

    return promise.then((res) => res.data && res.data.data);
  }

  /**
   * Get user course
   * @param data
   * @returns {*}
   */
  getUserCourse (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.get(AppHelper.genUrl(vue.$mcf.api.user.course.get, {user_id: data._uid, id: data.id}), {
      params: data
    })

    return promise
  }

  /**
   * Get user course
   * @param data
   * @returns {*}
   */
  getUserCoursesInfo (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.get(AppHelper.genUrl(vue.$mcf.api.user.course.info, {user_id: data._uid}), {
      params: data
    })

    return promise
  }

  /**
   * Update user course
   * @param data
   * @returns {*}
   */
  updateUserCourse (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.patch(AppHelper.genUrl(vue.$mcf.api.user.course.update, {user_id: data._uid, id: data.id}), data)

    return promise
  }

  /**
   * Create user course
   * @param data
   * @returns {*}
   */
  createUserCourse (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.post(AppHelper.genUrl(vue.$mcf.api.user.course.create, {user_id: data._uid}), data)

    return promise
  }

  /**
   * Create firebase account
   * @param data
   * @returns {*}
   */
  createFirebase (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.post(vue.$mcf.api.user.firebase.create, data)

    return promise
  }


  profile (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.get(AppHelper.genUrl(vue.$mcf.api.user.profile, {id: data._uid}), {
      params: data
    })

    return promise
  }
  exportUsers () {
    var sv = this;
    var vue = sv.Vue;
    var $http = sv.Vue.$http;
    var promise = $http.post(vue.$mcf.api.user.export)
    return promise;
  }

  showOnlineInduction (id, force = true) {
    return this.update({
      id: id,
      show_online_induction: force
    });
  }

  toggleShowEntrepreneur (id, force = true) {
    return this.update({
      id: id,
      show_entrepreneur: force
    });
  }

  toggleShowMilitary (id, force = true) {
    return this.update({
      id: id,
      show_military: force
    });
  }

  showProgdigy (id, force = true) {
    return this.update({
      id: id,
      show_prodigy: force
    });
  }

  getUserTypes (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.get(AppHelper.genUrl(vue.$mcf.api.user.types, {}), {
      params: data
    })
    return promise
  }

}
