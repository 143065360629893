<template>
  <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 46.2 (44496) - http://www.bohemiancoding.com/sketch -->
    <title>Group</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
      <g id="SignUp_01B" transform="translate(-393.000000, -439.000000)" stroke-width="2" stroke="#E2E2E2">
        <g id="Group-2" transform="translate(186.000000, 293.000000)">
          <g id="Group" transform="translate(208.000000, 147.000000)">
            <polyline id="Stroke-3531" points="4.5 2.5 0.5 2.5 0.5 23.5 23.5 23.5 23.5 2.5 19.5 2.5"></polyline>
            <polygon id="Stroke-3532" points="7.5 4.5 4.5 4.5 4.5 0.5 7.5 0.5"></polygon>
            <polygon id="Stroke-3533" points="19.5 4.5 16.5 4.5 16.5 0.5 19.5 0.5"></polygon>
            <path d="M7.5,2 L16.5,2" id="Stroke-3534"></path>
            <path d="M0.5,7 L23.5,7" id="Stroke-3535"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'IconCalendar'
  }
</script>
