import AppHelper from '@/helpers'

export class FitTestService {
  constructor (Vue) {
    this.Vue = Vue
  }

  get (data) {
    let sv = this
    let vue = sv.Vue
    let $http = sv.Vue.$http

    return $http.get(AppHelper.genUrl(vue.$mcf.api.fittest.get, {id: data.id}), {
      params: data
    })
  }

  list (data) {
    let sv = this
    let vue = sv.Vue
    let $http = sv.Vue.$http

    return $http.get(vue.$mcf.api.fittest.list, {
      params: data
    })
  }
}
