<template>
  <div>
    <div class="user-type user-cate-style">
      <div class="clearfix">
        <div class="row">
          <div class="col-md-10 col-sm-10 col-xs-10 user-type-label">
            User Categories Availability
          </div>
          <div class="col-md-2 col-sm-2 col-xs-2 text-right user-type-action">
            <div class="dropdown-style">
              <b-dropdown id="dropdown-1" text="Dropdown Button" class="m-md-2" right>
                <b-dropdown-item
                  v-for="(user_type, user_type_index) in userTypes"
                  v-bind:key="'user_type_a' + user_type_index"
                  v-if="!checkUsedUserType(user_type.role_id)"
                  v-on:click="addItem(user_type)"
                >{{ user_type.name }}</b-dropdown-item>
              </b-dropdown>
            </div>
<!--            <span v-on:click="addItem" class="icon add-icon cursor-pointer"></span>-->
          </div>
        </div>
        <div class="row user-type-option" style="margin-bottom: 15px">
          <div class="col-md-12" v-for="(item, index) in items" v-bind:key="'item' + index">
            <div class="group-input mb-10">
              <div class="form-group mb-0 ">
                <div class="input-with-icon" v-on:click="setItemActive(index)">
                  <p>{{userTypes.find(userType => {return userType.role_id === item.role_id}).name}}</p>
                  <span class="icon remove-icon cursor-pointer" v-on:click="removeItem(index)"></span>
                </div>
              </div>
<!--              <div v-if="items[index].is_active === true">-->
              <div v-bind:class="{ collapse: activeItem !== index }">
                <div class="sub-list-user">
                  <div class="sub-list-user__header">
                    Categories this course appears in
                  </div>
                  <div class="sub-list-user__body">
                    <div class="row-cate" v-for="(category, category_index) in item.categories">
                      <div class="item-sub-cate">
                        <div class="ui-select">
                          <select v-model="item.categories[category_index].category_id" v-on:change="courseHasUpdate()">
                            <option
                              v-for="(item_category, cat_index) in options"
                              v-bind:key="'category_' + index + '_' + cat_index"
                              :value="item_category.id"
                              :disabled="isCategoryDisabled(index, item_category.id) && item_category.id !== item.categories[category_index].category_id"
                            >{{ item_category.name }}</option>
                          </select>
                        </div>
                        <div class="order-box" style="display: flex; width: 70%;padding-left: 10px">
                          <span style="font-size: 13px; margin-top: 6px; margin-right: 10px">Order</span>
                          <input type="number" v-model="item.categories[category_index].order" v-on:change="courseHasUpdate()" style="width: 100%; font-size: 15px">
                        </div>
                        <label class="myCheckbox mt-0 checkbox-oneline" >
                          <input type="checkbox" v-model="item.categories[category_index].is_required" v-on:change="courseHasUpdate()"/>
                          <span></span>
                          <div>Required</div>
                        </label>
                      </div>
                      <span v-if="items[index].categories.length > 1"
                            v-on:click="removeCategory(index, category_index)"
                            class="icon remove-icon cursor-pointer"></span>
                    </div>
                  </div>
                  <div class="add-more">
                    <span  v-on:click="addAnotherCategory(index)" >Add another category</span>
                  </div>
                </div>

                <div class="sub-list-user">
                  <div class="sub-list-user__header">
                    Pre-requisite courses
                  </div>
                  <div class="sub-list-user__body">
                    <div class="row-cate" v-for="(preCourse, preCourseIndex) in item.pre_requisite_courses">
                      <div class="item-sub-cate">
                        <div class="ui-select">
                          <select v-model="items[index].pre_requisite_courses[preCourseIndex]" v-on:change="onChange(item, $event)">
                            <option
                              v-for="(coursex, coursexIndex) in courses"
                              v-bind:key="'pre_course' + coursexIndex + '_' + index"
                              :value="coursex.id"
                              :selected="preCourse"
                              :disabled="(items[index].pre_requisite_courses.includes(coursex.id)
                                && coursex.id !== item.pre_requisite_courses[preCourseIndex])
                                || course.id === coursex.id"
                            >
                              {{ coursex.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <span  class="icon remove-icon cursor-pointer" v-on:click="removePreCourse(index, preCourseIndex)"></span>
                    </div>
                  </div>
                  <p class="add-more">
                    <span v-on:click="addAnotherPreRequisitesCourse(index)" >Add another pre-requisite course</span>
                  </p>
                </div>

                <div class="sub-list-user">
                  <div class="sub-list-user__header">
                    Pre-requisite categories
                  </div>
                  <div class="sub-list-user__body">
                    <div class="row-cate" v-for="(pre_category, pre_category_index) in item.pre_requisite_categories">
                      <div class="item-sub-cate">
                        <div class="ui-select">
                          <select v-model="items[index].pre_requisite_categories[pre_category_index]" v-on:change="onChange(item, $event)">
                            <option
                              v-for="(item_category, cat_index) in options"
                              v-bind:key="'pre_category_' + index + '_' + cat_index"
                              :value="item_category.id"
                              :selected="pre_category"
                              :disabled="isCategoryDisabled(index, item_category.id) && item_category.id !== item.pre_requisite_categories[pre_category_index]"
                            >{{ item_category.name }}</option>
                          </select>
                        </div>
                      </div>
                      <span  class="icon remove-icon cursor-pointer" v-on:click="removePreCategory(index, pre_category_index)"></span>
                    </div>
                  </div>
                  <p class="add-more">
                    <span v-on:click="addAnotherPreRequisitesCategory(index)" >Add another pre-requisite categories</span>
                  </p>
                </div>
                <div class="sub-list-user">
                  <div class="sub-list-user__header">
                    Required for Playbook Launch Pathway
                  </div>
                  <div class="sub-list-user__body">
                    <div class="add-more__required">
                      <label class="myCheckbox mt-0 checkbox-oneline" >
                        <input type="checkbox" v-model="item.required_for_orientation" v-on:change="courseHasUpdate()"/>
                        <span></span>
                        <div>Required for Orientation</div>
                      </label>
                      <label class="myCheckbox mt-0 checkbox-oneline" >
                        <input type="checkbox" v-model="item.required_for_induction" v-on:change="courseHasUpdate()"/>
                        <span></span>
                        <div>Required for Induction</div>
                      </label>
                      <label class="myCheckbox mt-0 checkbox-oneline" >
                        <input type="checkbox" v-model="item.required_for_opening" v-on:change="courseHasUpdate()"/>
                        <span></span>
                        <div>Required for Opening</div>
                      </label>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CourseCategories',
    props: ['course'],
    data() {
      const requiredFor = this.course.required_for || [];
      const userTypes = [
        {
          name: "Owner",
          key: 'owner',
          type: 1001,
          role_id: 1,
          required: false,
          selected: 3
        },
        {
          name: "Coach",
          key: 'trainer',
          type: 1004,
          role_id: 2,
          required: false,
          selected: 3
        },
        {
          name: "Head Coach",
          key: 'head_trainer',
          type: 1002,
          role_id: 4,
          required: false,
          selected: 3
        },
        {
          name: "Membership Adviser",
          key: 'marketer',
          type: 1003,
          role_id: 5,
          required: false,
          selected: 3
        },
        {
          name: "Studio Manager",
          key: 'studio_manager',
          type: 1006,
          role_id: 6,
          required: false,
          selected: 3
        },
        {
          name: "Entrepreneur Studio Manager",
          key: 'entrepreneur_studio_manager',
          type: 1007,
          role_id: 7,
          required: false,
          selected: 3
        },
        {
          name: "Military Entrepreneur",
          key: 'military_entrepreneur',
          type: 1008,
          role_id: 8,
          required: false,
          selected: 3
        },
        {
          name: "Military Coach",
          key: 'military_trainer',
          type: 1009,
          role_id: 9,
          required: false,
          selected: 3
        },
        {
          name: "After Program",
          key: 'after_program',
          type: 1010,
          role_id: 10,
          required: false,
          selected: 3
        },
        {
          name: "College Fitness Manager",
          key: 'college_fitness_manager',
          type: 1011,
          role_id: 11,
          required: false,
          selected: 3
        },
        {
          name: "College Coach",
          key: 'college_trainer',
          type: 1012,
          role_id: 12,
          required: false,
          selected: 3
        },
        {
          name: "Prospective Coach",
          key: 'prospective_coach',
          type: 1013,
          role_id: 13,
          required: false,
          selected: 3
        },
      ];
      return {
        key: 'roles',
        options: [],
        userTypes,
        items: [],
        courses  :[],
        selectedUserType: [],
        activeItem : -1,
      };
    },
    created() {
      this.getCourseCategories();
      this.getSimpleCourseList();
    },
    methods: {
      async getCourseCategories() {
        const res = await this.$services.Course.courseCategories();
        this.options = res.data.categories;
      },
      async getSimpleCourseList() {
        const res = await this.$services.Course.simpleCourseList();
        this.courses = res.data.data;
      },
      onChange(type, event) {
        // changed
        this.course[this.key] = this.items;
        this.$emit('change', this.items);
      },
      onCheck(type, event) {
        // if (!this.course.required_for) {
        //   this.course.required_for = [];
        // }
        // const index = this.course.required_for.indexOf(type.type);
        // if (event.target.checked && index === -1) {
        //   this.course.required_for.push(type.type);
        // } else if (index > -1) {
        //   this.course.required_for.splice(index, 1);
        // }
        // type.checkbox = true;

        // changed
        this.course[this.key] = this.items;
        this.$emit('change', this.items);
      },
      onChangeCategory(item, index, event) {
        const opt = this.options.find(o => o.value === item.category_id);
        this.items[index].category_slug = opt.slug;
        // changed
        this.course[this.key] = this.items;
        this.$emit('change', this.items);
      },
      courseHasUpdate() {
        this.course[this.key] = this.items;
        this.$emit('change', this.items);
      },
      addItem(userType) {
        const defaultCategory = this.options[0];
        this.items.push({
          role_id: userType.role_id,
          categories: [{category_id: defaultCategory.id, is_required: false, order: 0}],
          pre_requisite_courses: [],
          pre_requisite_categories: [],
          required_for_induction: false,
          required_for_orientation: false,
          required_for_opening: false
        });
        // added
        this.course[this.key] = this.items;
        this.$emit('change', this.items);
      },
      addAnotherCategory(index) {
        const used = this.items[index].categories.map((item) => {
          return item.category_id;
        });
        var available_categories = this.options.filter((item) => {
          if(used.includes(item.id) === false && !this.isCategoryDisabled(index, item.id)) {
            return item
          }
        })
        if(!available_categories.length) return
        this.items[index].categories.push({category_id: available_categories[0].id, is_required: false, order: 0})
        this.$emit('change', this.items);
      },
      removeCategory(index, category_index) {
        this.items[index].categories.splice(category_index, 1)
        this.$emit('change', this.items);
      },
      addAnotherPreRequisitesCourse(index) {
        console.log('this.courses', this.courses);
        var available_courses = this.courses.filter((item) => {
          if(this.items[index].pre_requisite_courses.includes(item.id) === false && item.id !== this.course.id) {
            return item
          }
        })
        if(!available_courses.length) return
        this.items[index].pre_requisite_courses.push(available_courses[0].id)
        this.$emit('change', this.items);
      },
      removePreCourse(index, pre_course_index) {
        this.items[index].pre_requisite_courses.splice(pre_course_index, 1)
        this.$emit('change', this.items);
      },
      addAnotherPreRequisitesCategory(index) {
        var available = this.options.filter((item) => {
          if(this.items[index].pre_requisite_categories.includes(item.id) === false && !this.isCategoryDisabled(index, item.id)) {
            return item
          }
        })
        if(!available.length) return
        this.items[index].pre_requisite_categories.push(available[0].id)
        this.$emit('change', this.items);
      },
      removePreCategory(index, pre_category_index) {
        this.items[index].pre_requisite_categories.splice(pre_category_index, 1)
        this.$emit('change', this.items);
      },
      removeItem(idx) {
        this.items.splice(idx, 1);

        // removed
        this.course[this.key] = this.items;
        this.$emit('change', this.items);
      },
      isCategoryDisabled(index, category_id) {
        const category_used = this.items[index].categories.filter((item) => {
          return item.category_id === category_id;
        })
        const pre_category_used = this.items[index].pre_requisite_categories.filter((item) => {
          if(item === category_id) {
            return item;
          }
        })
        return category_used.length > 0 || pre_category_used.length > 0;
      },
      isPreCategoryDisabled(index, category_id) {
        const used = this.items[index].categories.map((item) => {
          return item.category_id;
        })
        return this.items[index].pre_requisite_categories.includes(category_id)
          || used.includes(category_id);
      },
      checkUsedUserType(role_id) {
        const used = this.items.filter((item) => {
          return item.role_id === role_id;
        })
        return used.length > 0;
      },
      setItemActive(index) {
        this.activeItem = this.activeItem === index ? -1 : index;
      }
    },
    watch: {
      course: {
        handler(newValue, oldValue) {
          if (newValue && newValue.roles) {
            this.items = newValue.roles.map((item) => {

              item.categories.map((subItem) => {
                if(typeof subItem.order == "undefined") {
                  subItem.order = 0;
                }
                return subItem
              })
              return item;
            });
          } else {
            this.items = [];
          }
        },
        immediate: true
      }
    },
    computed: {
    }
  }
</script>
