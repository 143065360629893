<template>
  <div class="embed-responsive embed-responsive-16by9">
    <div v-bind:id="prContainerId" class="embed-responsive-item"></div>
  </div>
</template>

<script>
  export default {
    name: 'ZigPlayer',
    components: {},
    props: [
      'prVideoId',
      'prContainerId'
    ],
    data: function () {
      return {
        ziggeoApp: null,
        player: null

      }
    },
    computed: {},
    methods: {


      //***---------------------------------------------------------------------------------------------------------------
      //-----     Init
      //-------------------------------------------------------
      init: function () {
        var vm = this

        if (!vm.prVideoId) {
          console.log('------==== Zig Player:  video id is invalid')
          return
        }

        vm.ziggeoApp = ZiggeoApi.V2.Application.getDefault()
        if (!vm.ziggeoApp) {
          vm.ziggeoApp = new ZiggeoApi.V2.Application({token: vm.$mcf.ziggeo.token})
        }
        vm.ziggeoApp.on('ready', function () {
          setTimeout(function () {
            vm.player = new ZiggeoApi.V2.Player({
              element: document.getElementById(vm.prContainerId),
              attrs: {
                width: '100%',
                height: '100%',
                theme: 'modern',
                themecolor: 'red',
                video: vm.prVideoId
              }
            })

            vm.player.activate()
          }, 500)
        })
      }
    },

    beforeCreate () {

    },

    created () {

    },

    mounted () {
      var vm = this

      setTimeout(function () {
        vm.init()
      }, 500)
    },

    watch: {
      'prVideoId': function () {
        this.init()
      }
    },

    beforeDestroy () {
      if (this.player) {
        this.player.stop()
        this.player.destroy()
      }
    }
  }
</script>
