<template>
  <div class="svg-loading__wrap">
    <svg class="svg-loading" viewBox="25 25 50 50">
      <circle class="svg-path" cx="50" cy="50" v-bind:r="size" fill="none" stroke-width="4" stroke-miterlimit="10"/>
    </svg>
  </div>
</template>

<script>
  export default {
    props: {
      size: {
        type: Number,
        default: 20
      },
      // visible: {
      //   type: Boolean
      // },
    },
    name: 'IconLoading'
  }
</script>
