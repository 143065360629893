
export class FileService {
  constructor (Vue) {
    this.Vue = Vue
  }

  upload (data) {
    let sv = this;
    let vue = sv.Vue;
    let $http = sv.Vue.$http;
    let promise = $http.post(vue.$mcf.api.file.upload, data);
    return promise
  }

}


