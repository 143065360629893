const config = {
  baseApiUrl: 'https://newstaging.api.f45academy.com/api/v1',
  studioApiUrl: 'https://studio.api.f45training.com/v1',
  baseUrl: '',
  appUrl: 'https://app.f45academy.com',
  cloudAccessUrl: 'https://f45hq.cloudflareaccess.com/',
  disableFittestFeature: false,
  isDevelopmentMode: false,
  useFakeData: false,
  isStgApi: true,
  xAuthToken: 'uqxOmgROHNWcEV2NixgwzBo4LLMsjxcSIDOx2zchGrXKh4x55ux6fM2NN1HSVzva',
  academyApiKey: 'pCMAZnGGq7dDiJ5i2jzbtyYYuhRw3vZ2AytN8lP8GIwZndlm9ZJxgIasTGXTVW12',
  api: {},
  fireBase: {
    apiKey: 'AIzaSyC95O4FprjtyPw-1TfRX1Xmp2TH9g35PEQ',
    authDomain: 'f45-contect-develop.firebaseapp.com',
    databaseURL: 'https://f45-contect-develop.firebaseio.com',
    projectId: 'f45-contect-develop',
    storageBucket: 'f45-contect-develop.appspot.com',
    messagingSenderId: '113119111990'
  },
  ziggeo: {
    token: 'f822d04e547240bd53d148b539f01c09' // for dev
  },
  hashIds: {
    salt: 'f45',
    min_hash_length: 4,
    alphabet: 'abcdefghijklmnopqrstuvwxyz123456789'
  },
  studio: {
    maxValue: 5000
  },
  certificateStates: {
    rejected: 2,
    approved: 1
  },
  countries: [
    'Australia',
    'United States',
    'New Zealand'
  ],
  languages: [
    {
      'name': 'English',
      'code': 'en',
    },
    {
      'name': 'French',
      'code': 'fr',
    },
    {
      'name': 'Spanish',
      'code': 'es',
    },
    {
      'name': 'Slovenian',
      'code': 'sl',
    },
    // {
    //   'name': 'German',
    //   'code': 'de',
    // }
  ],
  live: {
    baseApiUrl: 'https://newstaging.api.f45academy.com/api/v1',
    studioApiUrl: 'https://studio.api.f45training.com/v1',
    appUrl: 'https://app.f45academy.com',
    fireBase: {
      apiKey: "AIzaSyD7roefsEg81u5znR5TyUm_tWK3IMitoCs",
      authDomain: "f45-connect-live.firebaseapp.com",
      databaseURL: "https://f45-connect-live.firebaseio.com",
      projectId: "f45-connect-live",
      storageBucket: "f45-connect-live.appspot.com",
      messagingSenderId: "664730701619"
    },
    api: {}
  }
}
module.exports = config;
