/**
 * Global components
 *
 *
 * @type {}
 */

import IconExclamation from './IconExclamation'
import IconCalendar from './IconCalendar'
import IconTriangle from './IconTriangle'
import IconLoading from './IconLoading'
import IconList from './IconList'
import ErrorMessage from './ErrorMessage'

export default function (Vue) {
  let components = {
    'i-exclamation': IconExclamation,
    'i-calendar': IconCalendar,
    'i-triangle': IconTriangle,
    'i-loading': IconLoading,
    'i-list': IconList,
    'error-message': ErrorMessage
  };

  Object.keys(components).forEach(key => {
    Vue.component(key, components[key])
  })
}
