/**
 * Service for getting data from external resource
 *
 *
 * @type {}
 */
import AppHelper from '@/helpers'
import { UserService } from './user'
import { CourseService } from './course'
import { SectionService } from './section'
import { LessonService } from './lesson'
import { QuestionService } from './question'
import { AnswerService } from './answer'
import { InterviewService } from './interview'
import { FileService } from './file'
import { FitTestService } from './fit_test'
import { PlaylistService } from './playlist'
import { PlaylistVideoService } from './playlist_video'
import { StudioService } from './studio'
import { CertificateService } from './certificate';

export default function (Vue, $router) {
  var $http = Vue.prototype.$http
  var $toasted = Vue.prototype.$toasted
  var $mcf = Vue.prototype.$mcf

  // Interceptors:  Show error
  // $http.interceptors.response.use(response => {
  //   console.log('response', response);
  //   return response
  // }, error => {
  //   var msg = error
  //   var action = {}
  //
  //   // TODO: Need to refresh token if token is expired.  Currently, just redirect to login page
  //   if (error.response && error.response.status === 401) {
  //     AppHelper.log('Your token expired')
  //
  //   }
  //
  //   // if (error.response && error.response.data && error.response.data.data) {
  //   //   if (error.response.data.data.error && error.response.data.data.error.message) {
  //   //     msg = error.response.data.data.error.message
  //   //     action = {
  //   //       text: error.response.data.data.error.code,
  //   //       onClick: (e, toastObject) => {
  //   //         toastObject.goAway(0)
  //   //       }
  //   //     }
  //   //   } else if (error.response.data.data.errors && error.response.data.data.errors.length) {
  //   //     var tmpErr = error.response.data.data.errors[0]
  //   //     msg = tmpErr.message
  //   //     action = {
  //   //       text: tmpErr.code,
  //   //       onClick: (e, toastObject) => {
  //   //         toastObject.goAway(0)
  //   //       }
  //   //     }
  //   //   }
  //   //
  //   // }
  //
  //   // $toasted.show(msg, {
  //   //   action: action
  //   // })
  //
  //   // console.log('-==============Start.Error================')
  //   // console.log('msg: ' + msg)
  //   // console.log('code: ' + action.text)
  //   // console.log('-______________End.Error________________')
  //
  //   return Promise.reject(error)
  // })

  Object.defineProperties(Vue.prototype, {
    $services: {
      get: function () {
        return {
          User: new UserService(this),
          Course: new CourseService(this),
          Section: new SectionService(this),
          Interview: new InterviewService(this),
          File: new FileService(this),
          FitTest: new FitTestService(this),
          Quiz: new QuestionService(this),
          Lesson: new LessonService(this),
          Question: new QuestionService(this),
          Answer: new AnswerService(this),
          Playlist: new PlaylistService(this),
          PlaylistVideo: new PlaylistVideoService(this),
          Studio: new StudioService(this),
          Certificate: new CertificateService(this)
        }
      }
    },
  })
}
