<template>
    <div>
        <form @submit.prevent="submitForm('formCourse')" v-bind:class="{'form-has-error': formHasError}">
            <div class="wrap-sections">
                <div class="left-main-section">
                    <div class="nav-left">
                        <div class="head">Courses</div>
                        <draggable class="ul ul_courses" v-model="courses" element="ul" v-if="isReady"
                                   :options="dragOptionsCourse" @end="onEndCourse">
                            <transition-group type="transition" :name="'flip-list'">
                                <li v-for="(element, indexCourse) in courses" :key="element.id"
                                    v-bind:class="{ active : (currentCourse!== null && currentCourse.id === element.id)}"
                                    v-show="element.view_status !== STATUS.del">
                                    <span class="icon-draggable"></span>
                                    <div class="detail-course" v-on:click="detailCourse(indexCourse)">{{element.name}}
                                    </div>
                                    <div class="course-actions">
                                        <a href="#" class="delete-course" v-on:click="deleteCourse(indexCourse)">
                                            <i class="fa fa-delete"></i>
                                        </a>
                                        <a href="#" class="duplicate-course" v-bind:class="{'duplicating': duplicatingStages[element.id] }" v-on:click="duplicateCourse($event, indexCourse)" title="Duplicate course">
                                          <i v-if="!duplicatingStages[element.id]" class="fa"></i>
                                          <icon-loading v-bind:size="10" v-if="duplicatingStages[element.id]"></icon-loading>
                                        </a>
                                    </div>
                                </li>
                            </transition-group>
                        </draggable>
                        <div class="div-cta add-course">
                            <button type="button" class="btn bt-big" v-on:click="addCourse()">Add course</button>
                        </div>
                    </div>
                </div>
                <div class="right-main-section">
                    <div>
                        <div class="course-info" v-if="currentCourse">
                            <div class="head">Course Info</div>
                            <div class="course-info-detail">
                                <div class="course-frame">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="frm-ad-course">
                                                <div class="form-group"
                                                     v-bind:class="{'is-invalid': !currentCourse.name}">
                                                    <label>Name</label>
                                                    <input tabindex="1" type="text" name="course_name"
                                                           v-model="currentCourse.name" v-on:change="onChangeCourseName"
                                                           class="form-control"
                                                           autocomplete="off"
                                                    />
                                                </div>
                                                <div class="form-group">
                                                    <label>Intro Video</label>
                                                    <input type="text" name="course_video_id"
                                                           v-model="currentCourse.intro_video"
                                                           v-on:change="onChangeVideoId" class="form-control"/>
                                                </div>
                                                <div class="form-group"
                                                     v-bind:class="{'is-invalid': !currentCourse.commitment}">
                                                    <label>Commitment</label>
                                                    <input type="text" name="course_commitment"
                                                           v-model="currentCourse.commitment"
                                                           v-on:change="onChangeCommitment" class="form-control"/>
                                                </div>
                                                <div class="form-group" v-bind:class="{'is-invalid': !goodFor}">
                                                    <label>Good for</label>
                                                    <input type="text" name="course_good_for" v-model="goodFor"
                                                           v-on:change="onChangeGoodFor" class="form-control"/>
                                                </div>

                                                <div class="form-group">
                                                    <div class="div-cta upload-pdf">
                                                        <button type="button" class="btn-transparent"
                                                                v-show="!(uploadingPDF === NOTE_TYPES.COURSE)">UPLOAD NOTES
                                                            <vue-dropzone class="btn-dropzone dropzone_thumbnail_photo"
                                                                          id="dropzone_course_note"
                                                                          :ref="NOTE_TYPES.COURSE"
                                                                          :options="dropzoneOptionsPDF"
                                                                          v-on:vdropzone-sending="() => uploadingNote(NOTE_TYPES.COURSE)"
                                                                          v-on:vdropzone-thumbnail="callBackAddedFilePDF"
                                                                          v-on:vdropzone-error="callBackFileErrorPDF"
                                                                          v-on:vdropzone-success="(f, r) => uploadNoteSuccess(f, r, NOTE_TYPES.COURSE)"
                                                                          v-on:vdropzone-complete="callBackUploadCompletePDF"
                                                                          :destroyDropzone="false">
                                                            </vue-dropzone>
                                                        </button>
                                                        <button class="btn-transparent" v-show="uploadingPDF === NOTE_TYPES.COURSE">&nbsp;<icon-loading></icon-loading>
                                                        </button>

                                                        <div class="notes course-notes">
                                                            <div v-for="(note, indexNote) in currentCourse.notes"
                                                                 :key="indexNote"
                                                                 class="note col-md-12 col-sm-12 col-xs-12">
                                                                <div class="row file">
                                                                    <div class="col-md-2 col-sm-2 col-xs-3 note-label">
                                                                        <span>File</span>
                                                                    </div>
                                                                    <div class="col-sm-8 col-xs-8 col-md-8 note-name">
                                                                        <p>{{getNoteNameFromNotePath(note.file_path)}}</p>
                                                                    </div>
                                                                    <div class="col-md-2 col-sm-2 col-xs-1 note-actions">
                                                                        <p>
                                                                            <a v-on:click="removeCourseNote(indexNote)"
                                                                               class="state-hover delete-course">
                                                                                <i class="fa fa-delete"></i>
                                                                            </a>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div class="row label">
                                                                    <div class="col-md-2 col-sm-2 col-xs-3 note-label">
                                                                        <span>Label</span>
                                                                    </div>
                                                                    <div class="col-md-8 col-sm-8 col-xs-8 note-name">
                                                                        <div v-if="labelEdittingID === editingId(NOTE_TYPES.COURSE, currentCourse.id, indexNote)" class="form-group">
                                                                            <input v-model="note.label"
                                                                                   v-on:blur="onBlurNoteLabel"
                                                                                   v-on:change="(e) => onChangeNoteLabel(e, currentCourse, indexNote)"
                                                                                   :ref="editingId(NOTE_TYPES.COURSE, currentCourse.id, indexNote)"
                                                                                   type="text"
                                                                                   class="form-control input-section"
                                                                                   autofocus/>
                                                                        </div>
                                                                        <p v-on:click="onEditNoteLabel(currentCourse, NOTE_TYPES.COURSE, indexNote)"
                                                                           v-if="labelEdittingID !== editingId(NOTE_TYPES.COURSE, currentCourse.id, indexNote)">{{ note.label }}</p>
                                                                    </div>
                                                                    <div class="col-md-offset-2 col-xs-offset-1">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div class="status-course clearfix">
                                                        <label class="myCheckbox">
                                                            <input type="checkbox" name="coming_soon"
                                                                   v-model="currentCourse.coming_soon"
                                                                   v-on:change="onChangeIsComingSoon"/>
                                                            <span></span>
                                                            <div>Coming soon</div>
                                                        </label>
                                                        <label class="myCheckbox">
                                                            <input type="checkbox" name="show_on_site"
                                                                   v-model="currentCourse.show_on_site"
                                                                   v-on:change="onChangeIsShowOnSite"/>
                                                            <span></span>
                                                            <div>Show on site</div>
                                                        </label>
                                                        <label class="myCheckbox">
                                                            <input type="checkbox" name="show_on_homepage"
                                                                   v-model="currentCourse.show_on_homepage"
                                                                   v-on:change="onChangeIsShowOnHome"/>
                                                            <span></span>
                                                            <div>Show on homepage</div>
                                                        </label>
                                                    </div>
                                                </div>


                                                <div class="form-group"
                                                     v-bind:class="{'is-invalid': !currentCourse.detail}">
                                                    <label>Detail</label>
                                                    <textarea class="area-description" name="course_detail"
                                                              v-model="currentCourse.detail"
                                                              v-on:change="onChangeDetail"/>
                                                </div>
                                                <div class="form-group"
                                                     v-bind:class="{'is-invalid': !currentCourse.description}">
                                                    <label>Description</label>
                                                    <textarea class="area-description" name="course_description"
                                                              v-model="currentCourse.description"
                                                              v-on:change="onChangeDescription"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="generate-course-info">
                                                <ul class="ul clearfix ul-photo">
                                                    <li>
                                                        <div class="wrap-img-thumb"><img
                                                                v-bind:src="getThumbnailPhoto()" class="img-thumb"/>
                                                        </div>
                                                        <h5 class="name-images">Thumbnail</h5>
                                                        <p class="descript-images">This image will be used on the
                                                            homepage and courses page.</p>
                                                        <div class="div-cta">
                                                            <button type="button" class="btn-transparent"
                                                                    v-show="!uploadingThumbnailPhoto">Upload a photo
                                                                <vue-dropzone
                                                                        class="btn-dropzone dropzone_thumbnail_photo"
                                                                        id="dropzone_thumbnail_photo"
                                                                        :options="dropzoneOptionsThumb"
                                                                        v-on:vdropzone-sending="callBackSendingThumbnailPhoto"
                                                                        v-on:vdropzone-thumbnail="callBackAddedFileThumbnailPhoto"
                                                                        v-on:vdropzone-error="callBackFileErrorThumbnailPhoto"
                                                                        v-on:vdropzone-success="callBackUploadSuccessThumbnailPhoto"
                                                                        v-on:vdropzone-complete="callBackUploadCompleteThumbnailPhoto"
                                                                        :destroyDropzone="true">
                                                                </vue-dropzone>
                                                            </button>
                                                            <button class="btn-transparent"
                                                                    v-show="uploadingThumbnailPhoto">Upload a photo
                                                                <icon-loading></icon-loading>
                                                            </button>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="wrap-img-thumb"><img v-bind:src="getFeaturePhoto()"
                                                                                         class="img-thumb"/></div>
                                                        <h5 class="name-images">Feature image</h5>
                                                        <p class="descript-images">This image will be used on the
                                                            homepage and courses page.</p>
                                                        <div class="div-cta">
                                                            <button type="button" class="btn-transparent"
                                                                    v-show="!uploadingFeaturePhoto">
                                                                Upload a photo
                                                                <vue-dropzone
                                                                        class="btn-dropzone dropzone_feature_photo"
                                                                        id="dropzone_feature_photo"
                                                                        :options="dropzoneOptionsFeatured"
                                                                        v-on:vdropzone-sending="callBackSendingFeaturePhoto"
                                                                        v-on:vdropzone-thumbnail="callBackAddedFileFeaturePhoto"
                                                                        v-on:vdropzone-error="callBackFileErrorFeaturePhoto"
                                                                        v-on:vdropzone-success="callBackUploadSuccessFeaturePhoto"
                                                                        v-on:vdropzone-complete="callBackUploadCompleteFeaturePhoto"
                                                                        :destroyDropzone="true">
                                                                </vue-dropzone>
                                                            </button>
                                                            <button class="btn-transparent"
                                                                    v-show="uploadingFeaturePhoto">Upload a photo
                                                                <icon-loading></icon-loading>
                                                            </button>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <div class="email-completion">
                                                  <div class="email-completion__title">Email On Completion <span>(Sending an email to the email address when any users have completed this course)</span></div>
                                                  <div class="email-completion__inner">
                                                      <label class="myCheckbox">
                                                        <input type="checkbox" name="emailCheck"
                                                               v-model="currentCourse.email_on_completion.checked"
                                                               v-on:change="onChangeCheckboxEmail"/>
                                                        <span></span>
                                                        <div>Email On Completion</div>
                                                      </label>
                                                      <div class="form-group">
                                                        <label>Email Address</label>
                                                        <input name="email_address"
                                                               v-model="currentCourse.email_on_completion.email"
                                                               v-on:change="onChangeEmailAddress" class="form-control"
                                                               autocomplete="off"
                                                        />
                                                      </div>
                                                      <div class="form-group">
                                                        <label>Email Subject</label>
                                                        <input type="text" name="email_subject"
                                                               v-model="currentCourse.email_on_completion.subject"
                                                               v-on:change="onChangeEmailSubject" class="form-control"
                                                               autocomplete="off"
                                                        />
                                                      </div>
                                                      <div>
                                                        <span class="invalid-email">{{ getFailureMessageEmailOnCompletion() }}</span>
                                                      </div>
                                                    </div>
                                                </div>
                                                <course-categories v-on:change="onChangeCourseCategory($event)" v-bind:course="currentCourse"></course-categories>
                                                <div class="user-type studio-available">
                                                    <div class="clearfix">
                                                        <div class="row">
                                                            <div class="col-md-10 col-sm-10 col-xs-10 user-type-label">
                                                                Geographical / Studio Availability
                                                            </div>
                                                            <div class="col-md-2 col-sm-2 col-xs-2 text-right user-type-action">
                                                                <span v-on:click="addRestriction" class="icon add-icon cursor-pointer"></span>
                                                            </div>
                                                        </div>
                                                        <div v-for="(restriction, index) in currentCourse.location_availabilities" :key="index" class="row studio-option">
                                                            <div class="col-md-4 col-sm-4 col-xs-4">
                                                                <div class="ui-select">
                                                                    <select v-model="restriction.type"
                                                                            v-on:change="onChangeRestriction(index)">
                                                                        <option :value="RESTRICTION_TYPES.COUNTRY">Country</option>
                                                                        <option :value="RESTRICTION_TYPES.STUDIO">Studio</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-6 col-xs-6 options">
                                                                <div v-if="restriction.type === RESTRICTION_TYPES.STUDIO">
                                                                    <input v-model="restriction.value"
                                                                           type="text"
                                                                           v-on:change="onChangeRestriction(index)"
                                                                           class="form-control input-section">
                                                                </div>
                                                                <div v-if="restriction.type === RESTRICTION_TYPES.COUNTRY" class="ui-select">
                                                                    <select v-model="restriction.value"
                                                                            v-on:change="onChangeRestriction(index)">
                                                                        <!--<option v-if="restriction.value" selected :value="restriction.value">{{ restriction.value }}</option>-->
                                                                        <option v-for="(country, index) in countryOptions"
                                                                                v-if="selectedCountry(country, restriction)"
                                                                                :key="index" :value="country">
                                                                            {{ country }}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-2 col-sm-2 col-xs-2 text-right action" style="line-height: 32px;">
                                                                <span v-on:click="removeRestriction(index)" class="icon remove-icon cursor-pointer"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="user-type studio-available">
                                                  <div class="clearfix">
                                                    <div class="row">
                                                      <div class="col-md-10 col-sm-10 col-xs-10 user-type-label">
                                                        Languages
                                                      </div>
                                                      <div class="col-md-2 col-sm-2 col-xs-2 text-right user-type-action">
                                                        <span v-on:click="addLanguage" class="icon add-icon cursor-pointer"></span>
                                                      </div>
                                                    </div>
                                                    <div v-for="(language, lang_index) in currentCourse.languages" :key="lang_index" class="row studio-option">
                                                      <div class="col-md-10 col-sm-10 col-xs-10">
                                                        <div class="ui-select">
                                                          <select
                                                            v-model="currentCourse.languages[lang_index].language"
                                                            v-on:change="onChangeLanguage(lang_index)">
                                                            <option v-for="(lang, index) in $mcf.languages"
                                                                    :key="index"
                                                                    :disabled="isDisabledLanguage(lang.code)"
                                                                    :value="lang.code">
                                                              {{ lang.name }}
                                                            </option>
                                                          </select>
                                                        </div>
                                                      </div>
                                                      <div class="col-md-2 col-sm-2 col-xs-2 text-right action" style="line-height: 32px;">
                                                        <span v-on:click="removeLanguage(lang_index)" class="icon remove-icon cursor-pointer"></span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
<!--                                                <div class="user-type studio-available">-->
<!--                                                    <div class="clearfix">-->
<!--                                                        <div class="row">-->
<!--                                                            <div class="col-md-10 col-sm-10 col-xs-10 user-type-label">-->
<!--                                                                Language-->
<!--                                                            </div>-->
<!--                                                        </div>-->
<!--                                                        <div class="row studio-option">-->
<!--                                                            <div class="col-md-12 col-sm-12 col-xs-12">-->
<!--                                                                <div class="ui-select">-->
<!--                                                                    <select-->
<!--                                                                            v-model="currentCourse.language"-->
<!--                                                                            v-on:change="onChangeLanguage">-->
<!--                                                                        <option v-for="(lang, index) in $mcf.languages"-->
<!--                                                                                v-bind:selected="lang.code === currentCourse.language"-->
<!--                                                                                :key="index" :value="lang.code">-->
<!--                                                                            {{ lang.name }}-->
<!--                                                                        </option>-->
<!--                                                                    </select>-->
<!--                                                                </div>-->
<!--                                                            </div>-->
<!--                                                        </div>-->
<!--                                                    </div>-->
<!--                                                </div>-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="course-info" v-if="currentCourse">
                            <div class="head">Course Content</div>
                            <div class="course-info-detail">
                                <draggable class="ul ul_section" v-model="currentCourse.sections" element="ul"
                                           :options="dragOptionsSection" :move="onMove" @end="onEnd" @start="onStart">
                                    <transition-group type="transition">
                                        <li class="li-section" v-for="(section, indexSection) in currentCourse.sections"
                                            :key="section.id" v-bind:class="{ active : section.isActive}"
                                            v-show="section.view_status !== STATUS.del">
                                            <div class="collapse-wrapper">
                                                <!-- Section -->
                                                <div class="header header-section">
                                                    <span class="icon-draggable"></span>
                                                    <div class="detail-course"
                                                         v-bind:class="{'is-invalid': !section.name}">
                                                        <input type="text" v-model="section.name" placeholder="Section name"
                                                               v-on:change="onChangeSectionName(indexSection)"
                                                               class="form-control input-section"/>
                                                    </div>
                                                    <a class="state-hover arrow-collapse"
                                                       v-on:click="section.isActive = !section.isActive"></a>
                                                    <a class="state-hover delete-course"
                                                       v-on:click="deleteSection(indexSection)"><i
                                                            class="fa fa-delete"></i></a>
                                                </div>
                                                <!-- Section Content-->
                                                <div class="content content-section v-collapse-content-d">
                                                    <div class="clearfix">
                                                        <div class="form-group">
                                                            <div class="div-cta upload-pdf">
                                                                <button type="button"
                                                                        class="btn-transparent"
                                                                        v-show="!(uploadingPDF === NOTE_TYPES.SECTION+section.id)"
                                                                >
                                                                    UPLOAD NOTES
                                                                    <vue-dropzone class="btn-dropzone dropzone_thumbnail_photo"
                                                                                  :id="NOTE_TYPES.SECTION + section.id"
                                                                                  :ref="NOTE_TYPES.SECTION + section.id"
                                                                                  :options="dropzoneOptionsPDF"
                                                                                  v-on:vdropzone-sending="() => uploadingNote(NOTE_TYPES.SECTION+section.id)"
                                                                                  v-on:vdropzone-thumbnail="callBackAddedFilePDF"
                                                                                  v-on:vdropzone-error="callBackFileErrorPDF"
                                                                                  v-on:vdropzone-success="(f, r) => uploadNoteSuccess(f, r, NOTE_TYPES.SECTION, indexSection)"
                                                                                  v-on:vdropzone-complete="callBackUploadCompletePDF"
                                                                                  :destroyDropzone="false">
                                                                    </vue-dropzone>
                                                                </button>
                                                                <button class="btn-transparent" v-show="uploadingPDF === NOTE_TYPES.SECTION+section.id">&nbsp;<icon-loading></icon-loading>
                                                                </button>

                                                                <div class="notes section-notes">
                                                                    <div v-for="(note, indexNote) in section.notes"
                                                                         :key="indexNote"
                                                                         class="note col-md-12 col-sm-12 col-xs-12">
                                                                        <div class="row file">
                                                                            <div class="col-md-2 col-sm-2 col-xs-3 note-label">
                                                                                <span>File</span>
                                                                            </div>
                                                                            <div class="col-sm-8 col-xs-8 col-md-8 note-name">
                                                                                <p>{{getNoteNameFromNotePath(note.file_path)}}</p>
                                                                            </div>
                                                                            <div class="col-md-2 col-sm-2 col-xs-1 note-actions">
                                                                                <p>
                                                                                    <a v-on:click="removeSectionNote(indexSection, indexNote)"
                                                                                       class="state-hover delete-course">
                                                                                        <i class="fa fa-delete"></i>
                                                                                    </a>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row label">
                                                                            <div class="col-md-2 col-sm-2 col-xs-3 note-label">
                                                                                <span>Label</span>
                                                                            </div>
                                                                            <div class="col-md-8 col-sm-8 col-xs-8 note-name">
                                                                                <div v-if="labelEdittingID === editingId(NOTE_TYPES.SECTION, section.id, indexNote)"
                                                                                     class="form-group">
                                                                                    <input v-on:blur="onBlurNoteLabel"
                                                                                           v-model="note.label"
                                                                                           v-on:change="(e) => onChangeNoteLabel(e, section, indexNote, 'isSectionNote')"
                                                                                           :ref="editingId(NOTE_TYPES.SECTION, section.id, indexNote)"
                                                                                           type="text"
                                                                                           class="form-control input-section"
                                                                                           autofocus/>
                                                                                </div>
                                                                                <p v-on:click="onEditNoteLabel(section, NOTE_TYPES.SECTION, indexNote)"
                                                                                   v-if="labelEdittingID !== editingId(NOTE_TYPES.SECTION, section.id, indexNote)"
                                                                                >{{ note.label }}</p>
                                                                            </div>
                                                                            <div class="col-md-offset-2 col-xs-offset-1">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <draggable class="ul ul_lesson" v-model="section.lessons"
                                                               element="ul" :options="dragOptionsLesson"
                                                               @end="onEndLesson(indexSection)">
                                                        <transition-group type="transition">
                                        <li class="li-lesson" v-for="(lesson, indexLesson) in section.lessons"
                                            :key="lesson.id" v-bind:class="{ active : lesson.isActive}"
                                            v-show="lesson.view_status !== STATUS.del">
                                            <div class="collapse-wrapper">
                                                <!-- Lesson -->
                                                <div class="header header-lesson" v-collapse-toggle>
                                                    <span class="icon-draggable"></span>
                                                    <div class="detail-course"
                                                         v-bind:class="{'is-invalid': !lesson.name}">
                                                        <input type="text" v-model="lesson.name" placeholder="Lesson name"
                                                               v-on:change="onChangeLessonName(indexSection, indexLesson)"
                                                               class="form-control input-lesson"/>
                                                    </div>
                                                    <a class="state-hover arrow-collapse"
                                                       v-on:click="lesson.isActive = !lesson.isActive"></a>
                                                    <a class="state-hover delete-course"
                                                       v-on:click="deleteLesson(indexSection, indexLesson)"><i
                                                            class="fa fa-delete"></i></a>
                                                </div>
                                                <!-- Lesson Content -->
                                                <div class="content content-lesson" v-collapse-content>
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <div class="video-input">
                                                                <div class="form-group">
                                                                    <label>Video: </label>
                                                                    <input type="text" class="form-control" placeholder="Lesson video"
                                                                           v-on:change="onChangeLessonVideoId(indexSection, indexLesson)"
                                                                           v-model="lesson.video"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-8">
                                                            <div class="clearfix">
                                                                <div class="form-group">
                                                                    <div class="div-cta upload-pdf">
                                                                        <button type="button" class="btn-transparent"
                                                                                v-show="!(uploadingPDF === NOTE_TYPES.LESSON+lesson.id)">UPLOAD NOTES
                                                                            <vue-dropzone class="btn-dropzone dropzone_thumbnail_photo"
                                                                                          :id="NOTE_TYPES.LESSON+lesson.id"
                                                                                          :ref="NOTE_TYPES.LESSON+lesson.id"
                                                                                          :options="dropzoneOptionsPDF"
                                                                                          v-on:vdropzone-sending="() => uploadingNote(NOTE_TYPES.LESSON+lesson.id)"
                                                                                          v-on:vdropzone-thumbnail="callBackAddedFilePDF"
                                                                                          v-on:vdropzone-error="callBackFileErrorPDF"
                                                                                          v-on:vdropzone-success="(f, r) => uploadNoteSuccess(f, r, NOTE_TYPES.LESSON, indexSection, indexLesson)"
                                                                                          v-on:vdropzone-complete="callBackUploadCompletePDF"
                                                                                          :destroyDropzone="false">
                                                                            </vue-dropzone>
                                                                        </button>
                                                                        <button class="btn-transparent" v-show="uploadingPDF === NOTE_TYPES.LESSON+lesson.id">&nbsp;<icon-loading></icon-loading>
                                                                        </button>

                                                                        <div class="notes lesson-notes">
                                                                            <div v-for="(note, indexNote) in lesson.notes"
                                                                                 :key="indexNote"
                                                                                 class="note col-md-12 col-sm-12 col-xs-12">
                                                                                <div class="row file">
                                                                                    <div class="col-md-2 col-sm-2 col-xs-3 note-label">
                                                                                        <span>File</span>
                                                                                    </div>
                                                                                    <div class="col-sm-8 col-xs-8 col-md-8 note-name">
                                                                                        <p>{{getNoteNameFromNotePath(note.file_path)}}</p>
                                                                                    </div>
                                                                                    <div class="col-md-2 col-sm-2 col-xs-1 note-actions">
                                                                                        <p>
                                                                                            <a v-on:click="removeLessonNote(indexSection, indexLesson, indexNote)"
                                                                                               class="state-hover delete-course">
                                                                                                <i class="fa fa-delete"></i>
                                                                                            </a>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row label">
                                                                                    <div class="col-md-2 col-sm-2 col-xs-3 note-label">
                                                                                        <span>Label</span>
                                                                                    </div>
                                                                                    <div class="col-md-8 col-sm-8 col-xs-8 note-name">
                                                                                        <div v-if="labelEdittingID === editingId(NOTE_TYPES.LESSON, lesson.id, indexNote)"
                                                                                             class="form-group">
                                                                                            <input v-on:blur="onBlurNoteLabel"
                                                                                                   v-model="note.label"
                                                                                                   :ref="editingId(NOTE_TYPES.LESSON, lesson.id, indexNote)"
                                                                                                   v-on:change="(e) => onChangeNoteLabel(e, lesson, indexNote, 'isSectionNote')"
                                                                                                   type="text"
                                                                                                   class="form-control input-section"
                                                                                                   autofocus/>
                                                                                        </div>
                                                                                        <p v-on:click="onEditNoteLabel(lesson, NOTE_TYPES.LESSON, indexNote)"
                                                                                           v-if="labelEdittingID !== editingId(NOTE_TYPES.LESSON, lesson.id, indexNote)">
                                                                                            {{ note.label }}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div class="col-md-offset-2 col-xs-offset-1">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p class="questions-lbl">QUESTIONS</p>
                                                            <div class="right-question">
                                                                <draggable class="ul ul_question"
                                                                           v-model="lesson.questions" element="ul"
                                                                           :options="dragOptionsQuestion"
                                                                           @end="onEndQuestion(indexSection, indexLesson)">
                                                                    <transition-group type="transition">
                                        <li class="li-question" v-for="(question, indexQuestion) in lesson.questions"
                                            :key="question.id" v-show="question.view_status !== STATUS.del">
                                            <!-- Question -->
                                            <div class="header header-question">
                                                <span class="icon-draggable"></span>
                                                <div class="detail-course"
                                                     v-bind:class="{'is-invalid': !question.name}">
                                                    <input type="text" v-model="question.name" placeholder="Question name"
                                                           v-on:change="onChangeQuestionName(indexSection, indexLesson, indexQuestion)"
                                                           class="form-control input-question"/>
                                                </div>
                                                <a class="state-hover delete-course"
                                                   v-on:click="deleteQuestions(indexSection, indexLesson, indexQuestion)">
                                                    <i class="fa fa-delete"></i>
                                                </a>
                                            </div>
                                            <!-- Question Content-->
                                            <div class="content content-question">
                                                <draggable class="ul ul_answer" v-model="question.answers"
                                                           :options="dragOptionsAnswer" element="ul"
                                                           @end="onEndAnswer(indexSection, indexLesson, indexQuestion)">
                                                    <transition-group type="transition">
                                        <li class="li-answer" v-for="(answer, indexAnswer) in question.answers"
                                            :key="answer.id" v-show="answer.view_status !== STATUS.del">
                                            <div class="answer-div">
                                                <span class="icon-draggable"></span>
                                                <div class="detail-course" v-bind:class="{'is-invalid': !answer.content}">
                                                    <input type="text" v-model="answer.content" placeholder="Answer name"
                                                           v-on:change="onChangeAnswerName(indexSection, indexLesson, indexQuestion, indexAnswer)"
                                                           class="form-control input-answer"/>
                                                </div>
                                            </div>
                                            <div class="check-result">
                                                <label class="myCheckbox">
                                                    <input type="checkbox" v-model="answer.is_right"
                                                           v-on:change="onChangeAnswerIsRight(indexSection, indexLesson, indexQuestion, indexAnswer)"/>
                                                    <span></span>
                                                </label>
                                            </div>
                                            <button type="button" class="state-hover delete-course"
                                                    v-on:click="deleteAnswer(indexSection, indexLesson, indexQuestion, indexAnswer) ">
                                                <i class="fa fa-delete"></i>
                                            </button>
                                        </li>
                                    </transition-group>
                                </draggable> <!--/ draggable answer -->
                                <div class="div-add-answer">
                                    <button type="button" class="bt-big pull-right btn-small"
                                            v-on:click="addAnswer(indexSection, indexLesson, indexQuestion)">Add answer
                                    </button>
                                </div>
                            </div>
                            </li>
                            </transition-group>
                            </draggable> <!--/ draggable question -->
                            <div class="div-add-answer">
                                <button type="button" class="bt-big pull-right btn-small"
                                        v-on:click="addQuestion(indexSection, indexLesson)">Add Question
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    </li>
    </transition-group>
    </draggable> <!--/ draggable lesson -->
    <div class="div-add-answer">
        <button type="button" class="bt-big pull-right btn-small" v-on:click="addLesson(indexSection)">Add Module
        </button>
    </div>
    </div>
    </div>
    </li>
    </transition-group>
    </draggable> <!--/ draggable section -->
    <div class="div-add-answer">
        <button type="button" class="bt-big pull-right btn-small" v-on:click="addSection(currentCourse.id)">Add
            Section
        </button>
    </div>
    </div> <!--/ course-info-detail -->
    </div><!--/ End course content-->
    </div>
    </div>
    </div>
    <div class="nav-submit" v-show="isSave">
        <!--<button v-show="!isSaving" class="btn bt-big" type="button" v-on:click="saveCoure">Save</button>-->
        <button v-show="!isSaving" class="btn bt-big" type="button" v-on:click="submitForm('formCourse')">Save</button>
        <button v-show="isSaving" class="btn bt-big" type="button">
            <icon-loading></icon-loading>
        </button>
    </div>
    <modal class="modal-confirm center" v-model="modalPrevent" :title="modalPreventTitle" :footer="false">
        <i class="icon-close" @click="modalPrevent = false"></i>
        <button type="button" class="btn btn-default left" v-on:click="selectCourseConfirm">OK</button>
        <button type="button" class="btn btn-default left" v-on:click="modalPrevent = false">CANCEL</button>
    </modal>
    <modal class="modal-confirm center" v-model="modalDelete" :title="modalDeleteTitle" :footer="false">
        <i class="icon-close" @click="cancelDeleteCourse"></i>
        <button type="button" class="btn btn-default left" v-on:click="confirmDeleteCourse">OK</button>
        <button type="button" class="btn btn-default left" v-on:click="cancelDeleteCourse">CANCEL</button>
    </modal>
    </form>
    <div class="loading-div" v-show="isLoading">
        <icon-loading></icon-loading>
    </div>
    </div>

</template>

<script>

  import draggable from 'vuedraggable'
  import vue2Dropzone from 'vue2-dropzone'
  import {uuid} from 'vue-uuid'
  import IconLoading from '@/components/global/IconLoading';
  import CourseCategories from '@/components/global/CourseCategories';
  import AppHelper from '@/helpers'

  const STATUS = {
    origin: 0,
    new: 1,
    update: 2,
    del: 3
  };

  const RESTRICTION_TYPES = {
    COUNTRY: 1,
    STUDIO: 2,
  };

  const NOTE_TYPES = {
    COURSE: 'course_note',
    SECTION: 'section_note',
    LESSON: 'lesson_note',
  };

  export default {
    components: {
      draggable,
      vueDropzone: vue2Dropzone,
      IconLoading,
      CourseCategories
    },
    name: 'CourseList',
    data() {
      return {
        isLoading: true,
        STATUS: STATUS,
        isSectionActive: false,
        isDragging: false,
        isSaving: false,
        editable: true,
        delayedDragging: false,
        formHasError: false,
        courseStatus: {
          isName: false,
          isVideoId: false,
          isCommitment: false,
          isGoodFor: false,
          isDetail: false,
          isDescription: false,
          isComingSoon: false,
          isShowOnSite: false,
          isShowHome: false,
          isThumbnailPhoto: false,
          isFeaturePhoto: false,
          isSection: false,
          for_owner: false,
          for_head_trainer: false,
          for_marketer: false,
          for_trainer: false,
          for_studio_manager: false,
          isPDF: false,
          isChangeLanguage: false,
          isRequiredFor: false,
          userTypeCategories: false,
          emailCheck: false,
          emailAddress: false,
          emailSubject: false
        },

        currentCourseId: null,
        currentIdxCourse: null,
        deleteIdxCourse: null,
        currentCourse: null,
        tempCourse: null,
        courses: [],
        coursesBackup: [],
        isLoadCourses: false,
        isReady: false,

        modalPrevent: false,
        modalPreventTitle: 'If you open other link, current data was lost?',

        modalDelete: false,
        modalDeleteTitle: 'Are you sure delete this course?',

        dropzoneOptions: {
          url: this.$mcf.api.file.upload,
          maxFilesize: 5,
          headers: {
            "Cache-Control": false,
            'X-Authorization': this.$mcf.xAuthToken
          },
          autoProcessQueue: true,
          acceptedFiles: ".jpg,.jpeg,.png,.gif",
        },

        dropzoneOptionsFeatured: {
          url: this.$mcf.api.file.uploadFeatured,
          maxFilesize: 5,
          headers: {
            "Cache-Control": false,
            'X-Authorization': this.$mcf.xAuthToken
          },
          autoProcessQueue: true,
          acceptedFiles: ".jpg,.jpeg,.png,.gif",
        },
        dropzoneOptionsThumb: {
          url: this.$mcf.api.file.uploadThumb,
          maxFilesize: 5,
          headers: {
            "Cache-Control": false,
            'X-Authorization': this.$mcf.xAuthToken
          },
          autoProcessQueue: true,
          acceptedFiles: ".jpg,.jpeg,.png,.gif",
        },
        dropzoneOptionsPDF: {
          url: this.$mcf.api.file.upload,
          maxFilesize: 100,
          timeout: 300000, // 300s
          headers: {
            "Cache-Control": false,
            'X-Authorization': this.$mcf.xAuthToken
          },
          autoProcessQueue: true,
          acceptedFiles: ".pdf,.txt,.docx,.doc,.zip,.rar",
        },

        uploadingThumbnailPhoto: false,
        uploadingFeaturePhoto: false,
        uploadingPDF: false,
        newThumbnailPhoto: null,
        newFeaturePhoto: null,
        newPDF: null,
        pdfFileName: null,
        labelEdittingID: false,
        NOTE_TYPES,
        RESTRICTION_TYPES,
        duplicatingStages: {}
      }
    },
    methods: {

      // utility
      getCourseOld(courseId) {
        let vm = this;
        let findCourse = null;
        if (vm.coursesBackup.length) {
          for (let idxCourse = 0; idxCourse < vm.coursesBackup.length; idxCourse++) {
            if (vm.coursesBackup[idxCourse].id === courseId) {
              findCourse = vm.coursesBackup[idxCourse];
              break;
            }
          }
        }
        return findCourse;
      },
      getSectionOld(sectionId) {
        let vm = this;
        let findSection = null;
        if (vm.tempCourse.sections.length) {
          for (let idxSection = 0; idxSection < vm.tempCourse.sections.length; idxSection++) {
            if (vm.tempCourse.sections[idxSection].id === sectionId) {
              findSection = vm.tempCourse.sections[idxSection];
              break;
            }
          }
        }
        return findSection;
      },
      getLessonOld(sectionId, lessonId) {
        let vm = this;
        let findLesson = null;
        let section = vm.getSectionOld(sectionId);

        if (section && section.lessons.length) {
          for (let idxLesson = 0; idxLesson < section.lessons.length; idxLesson++) {
            if (section.lessons[idxLesson].id === lessonId) {
              findLesson = section.lessons[idxLesson];
              break;
            }
          }
        }
        return findLesson;
      },
      getQuestionOld(sectionId, lessonId, questionId) {
        let vm = this;
        let findQuestion = null;
        let lesson = vm.getLessonOld(sectionId, lessonId);

        if (lesson && lesson.questions.length) {
          for (let idxQuestion = 0; idxQuestion < lesson.questions.length; idxQuestion++) {
            if (lesson.questions[idxQuestion].id === questionId) {
              findQuestion = lesson.questions[idxQuestion];
              break;
            }
          }
        }
        return findQuestion;
      },
      getAnswerOld(sectionId, lessonId, questionId, answerId) {
        let vm = this;
        let findAnswer = null;
        let question = vm.getQuestionOld(sectionId, lessonId, questionId);

        if (question && question.answers.length) {
          for (let idxAnswer = 0; idxAnswer < question.answers.length; idxAnswer++) {
            if (question.answers[idxAnswer].id === answerId) {
              findAnswer = question.answers[idxAnswer];
              break;
            }
          }
        }

        return findAnswer;
      },

      getIdxCourse(courseId) {
        let vm = this;
        let findCourseIdx = 0;
        if (vm.courses.length) {
          for (let idxCourse = 0; idxCourse < vm.courses.length; idxCourse++) {
            if (vm.courses[idxCourse].id === courseId) {
              findCourseIdx = idxCourse;
              break;
            }
          }
        }
        return findCourseIdx;
      },

      // get data
      getThumbnailPhoto() {
        if (this.newThumbnailPhoto) {
          return this.newThumbnailPhoto;
        }
        if (this.currentCourse && this.currentCourse.thumbnail_path) {
          return this.currentCourse.thumbnail_path;
        }
        return ''
      },
      getFeaturePhoto() {
        if (this.newFeaturePhoto) {
          return this.newFeaturePhoto;
        }
        if (this.currentCourse) {
          return this.currentCourse.feature_image_path;
        }
        return ''
      },

      // On Change course
      onChangeCourseName() {
        let vm = this;
        if (vm.tempCourse.name === vm.currentCourse.name) {
          vm.courseStatus.isName = false;
        } else {
          vm.courseStatus.isName = true;
        }

      },
      onChangeVideoId() {
        if (this.tempCourse.intro_video === this.currentCourse.intro_video) {
          this.courseStatus.isVideoId = false;
        } else {
          this.courseStatus.isVideoId = true;
        }
      },
      onChangeCommitment() {
        if (this.tempCourse.commitment === this.currentCourse.commitment) {
          this.courseStatus.isCommitment = false;
        } else {
          this.courseStatus.isCommitment = true;
        }

      },
      onChangeGoodFor() {
        let good_for = this.tempCourse.good_for;

        if (good_for.join(', ') === this.goodFor) {
          this.courseStatus.isGoodFor = false;
        } else {
          this.courseStatus.isGoodFor = true;
        }

      },
      onChangeDetail() {
        if (this.tempCourse.detail === this.currentCourse.detail) {
          this.courseStatus.isDetail = false;
        } else {
          this.courseStatus.isDetail = true;
        }

      },
      onChangeDescription() {
        if (this.tempCourse.description === this.currentCourse.description) {
          this.courseStatus.isDescription = false;
        } else {
          this.courseStatus.isDescription = true;
        }

      },
      onChangeIsComingSoon() {
        if (this.tempCourse.coming_soon === this.currentCourse.coming_soon) {
          this.courseStatus.isComingSoon = false;
        } else {
          this.courseStatus.isComingSoon = true;
        }
      },
      onChangeCheckboxEmail() {
        if (this.tempCourse.email_on_completion.checked === this.currentCourse.email_on_completion.checked) {
          this.courseStatus.emailCheck = false;
        } else {
          this.courseStatus.emailCheck = true;
        }
      },
      getFailureMessageEmailOnCompletion() {
        if (!this.currentCourse || !this.currentCourse.email_on_completion) return;
        if (!this.currentCourse.email_on_completion.email && !this.currentCourse.email_on_completion.subject) {
          return '';
        }
        if (AppHelper.isEmail(this.currentCourse.email_on_completion.email)) {
          if (!this.currentCourse.email_on_completion.subject) {
            return 'The email subject field is required when email address is present';
          } else {
            return '';
          }
        } else {
          return 'Please enter a valid email';
        }
      },
      onChangeEmailAddress() {
        if (this.tempCourse.email_on_completion.email === this.currentCourse.email_on_completion.email) {
          this.courseStatus.emailAddress = false;
        } else {
          this.courseStatus.emailAddress = true;
        }
      },
      onChangeEmailSubject() {
        if (this.tempCourse.email_on_completion.subject === this.currentCourse.email_on_completion.subject) {
          this.courseStatus.emailSubject = false;
        } else {
          this.courseStatus.emailSubject = true;
        }
      },
      onChangeIsShowOnSite() {
        if (this.tempCourse.show_on_site === this.currentCourse.show_on_site) {
          this.courseStatus.isShowOnSite = false;
        } else {
          this.courseStatus.isShowOnSite = true;
        }
      },

      onChangeIsShowOnHome() {
        if (this.tempCourse.show_on_homepage === this.currentCourse.show_on_homepage) {
          this.courseStatus.isShowHome = false;
        } else {
          this.courseStatus.isShowHome = true;
        }
      },
      onChangeCourseCategory(values) {
        const key = `roles`;
        const oldValue = this.tempCourse[key];
        if (!oldValue) {
          this.courseStatus.userTypeCategories = oldValue !== this.currentCourse[key];
        } else {
          const oldValue = this.tempCourse[key];
          const newValue = this.currentCourse[key];
          this.courseStatus.userTypeCategories = JSON.stringify(oldValue) != JSON.stringify(newValue);
        }
      },
      onChangeForOwner() {
        if (this.tempCourse.for_owner === this.currentCourse.for_owner) {
          this.courseStatus.for_owner = false;
        } else {
          this.courseStatus.for_owner = true;
        }
      },
      onChangeForHeadTrainer() {
        if (this.tempCourse.for_head_trainer === this.currentCourse.for_head_trainer) {
          this.courseStatus.for_head_trainer = false;
        } else {
          this.courseStatus.for_head_trainer = true;
        }
      },
      onChangeForMarketer() {
        if (this.tempCourse.for_marketer === this.currentCourse.for_marketer) {
          this.courseStatus.for_marketer = false;
        } else {
          this.courseStatus.for_marketer = true;
        }
      },
      onChangeForTrainer() {
        if (this.tempCourse.for_trainer === this.currentCourse.for_trainer) {
          this.courseStatus.for_trainer = false;
        } else {
          this.courseStatus.for_trainer = true;
        }
      },

      onChangeForStudioManager() {
        if (this.tempCourse.for_studio_manager === this.currentCourse.for_studio_manager) {
          this.courseStatus.for_studio_manager = false;
        } else {
          this.courseStatus.for_studio_manager = true;
        }
      },

      onChangeCourseOrder(indexCourse, order) {

        let vm = this;

        let course = vm.courses[indexCourse];

        if (course.order !== order) {
          course.order = order;
          switch (course.view_status) {
            case STATUS.origin:
              course.order_status = STATUS.update;
              break;
            case STATUS.update:
              let oldSCourse = vm.getCourseOld(course.id);
              if (oldSCourse && oldSCourse.order === course.order) {
                course.order_status = STATUS.origin;
              }
              break;
          }
        }
      },

      onChangeSectionName(indexSection) {

        let vm = this;
        let section = vm.currentCourse.sections[indexSection];
        switch (section.view_status) {
          case STATUS.origin:
            section.view_status = STATUS.update;
            break;
          case STATUS.update:
            let oldSection = vm.getSectionOld(section.id);
            if (oldSection && oldSection.name === section.name && oldSection.sequence === section.sequence) {
              section.view_status = STATUS.origin;
            }
            break;
        }
      },
      onChangeSectionOrder(indexSection, order) {

        let vm = this;
        let section = vm.currentCourse.sections[indexSection];
        if (section.sequence !== order) {
          section.sequence = order;
          switch (section.view_status) {
            case STATUS.origin:
              section.view_status = STATUS.update;
              break;
            case STATUS.update:
              let oldSection = vm.getSectionOld(section.id);
              if (oldSection && oldSection.name === section.name && oldSection.sequence === section.sequence) {
                section.view_status = STATUS.origin;
              }
              break;
          }
        }
      },

      onChangeLessonName(indexSection, indexLesson) {

        let vm = this;
        let section = vm.currentCourse.sections[indexSection];
        let lesson = vm.currentCourse.sections[indexSection].lessons[indexLesson];

        switch (lesson.view_status) {
          case STATUS.origin:
            lesson.view_status = STATUS.update;
            break;
          case STATUS.update:
            let oldLesson = vm.getLessonOld(section.id, lesson.id);
            if (oldLesson && oldLesson.name === lesson.name && oldLesson.video === lesson.video && oldLesson.sequence === lesson.sequence) {
              lesson.view_status = STATUS.origin;
            }
            break;
        }

        this.setCurrentCourse({
          ...vm.currentCourse
        });
      },
      onChangeLessonVideoId(indexSection, indexLesson) {

        let vm = this;
        let section = vm.currentCourse.sections[indexSection];
        let lesson = vm.currentCourse.sections[indexSection].lessons[indexLesson];

        switch (lesson.view_status) {
          case STATUS.origin:
            lesson.view_status = STATUS.update;
            break;
          case STATUS.update:
            let oldLesson = vm.getLessonOld(section.id, lesson.id);
            if (oldLesson && oldLesson.name === lesson.name && oldLesson.video === lesson.video && oldLesson.sequence === lesson.sequence) {
              lesson.view_status = STATUS.origin;
            }
            break;
        }

      },
      onChangeLessonOrder(indexSection, indexLesson, order) {

        let vm = this;
        let section = vm.currentCourse.sections[indexSection];
        let lesson = vm.currentCourse.sections[indexSection].lessons[indexLesson];

        if (lesson.sequence !== order) {
          lesson.sequence = order;
          switch (lesson.view_status) {
            case STATUS.origin:
              lesson.view_status = STATUS.update;
              break;
            case STATUS.update:
              let oldLesson = vm.getLessonOld(section.id, lesson.id);
              if (oldLesson && oldLesson.name === lesson.name && oldLesson.video === lesson.video && oldLesson.sequence === lesson.sequence) {
                lesson.view_status = STATUS.origin;
              }
              break;
          }
        }
      },

      onChangeQuestionName(indexSection, indexLesson, indexQuestion) {

        let vm = this;
        let section = vm.currentCourse.sections[indexSection];
        let lesson = vm.currentCourse.sections[indexSection].lessons[indexLesson];
        let question = vm.currentCourse.sections[indexSection].lessons[indexLesson].questions[indexQuestion];
        switch (question.view_status) {
          case STATUS.origin:
            question.view_status = STATUS.update;
            break;
          case STATUS.update:
            let oldQuestion = vm.getAnswerOld(section.id, lesson.id, question.id);
            if (oldQuestion && oldQuestion.name === question.name && oldQuestion.sequence === question.sequence) {
              question.view_status = STATUS.origin;
            }
            break;
        }

      },
      onChangeQuestionOrder(indexSection, indexLesson, indexQuestion, order) {

        let vm = this;
        let section = vm.currentCourse.sections[indexSection];
        let lesson = vm.currentCourse.sections[indexSection].lessons[indexLesson];
        let question = vm.currentCourse.sections[indexSection].lessons[indexLesson].questions[indexQuestion];

        if (question.sequence !== order) {
          question.sequence = order;
          switch (question.view_status) {
            case STATUS.origin:
              question.view_status = STATUS.update;
              break;
            case STATUS.update:
              let oldQuestion = vm.getAnswerOld(section.id, lesson.id, question.id);
              if (oldQuestion && oldQuestion.name === question.name && oldQuestion.sequence === question.sequence) {
                question.view_status = STATUS.origin;
              }
              break;
          }
        }

      },

      onChangeAnswerName(indexSection, indexLesson, indexQuestion, indexAnswer) {

        let vm = this;
        let section = vm.currentCourse.sections[indexSection];
        let lesson = vm.currentCourse.sections[indexSection].lessons[indexLesson];
        let question = vm.currentCourse.sections[indexSection].lessons[indexLesson].questions[indexQuestion];
        let answer = vm.currentCourse.sections[indexSection].lessons[indexLesson].questions[indexQuestion].answers[indexAnswer];

        switch (answer.view_status) {
          case STATUS.origin:
            answer.view_status = STATUS.update;
            break;
          case STATUS.update:
            let oldAnswer = vm.getAnswerOld(section.id, lesson.id, question.id, answer.id);
            if (oldAnswer && oldAnswer.name === answer.name && oldAnswer.is_right === answer.is_right && oldAnswer.sequence === answer.sequence) {
              answer.view_status = STATUS.origin;
            }
            break;
        }
      },
      onChangeAnswerIsRight(indexSection, indexLesson, indexQuestion, indexAnswer) {

        let vm = this;
        let section = vm.currentCourse.sections[indexSection];
        let lesson = vm.currentCourse.sections[indexSection].lessons[indexLesson];
        let question = vm.currentCourse.sections[indexSection].lessons[indexLesson].questions[indexQuestion];
        let answer = vm.currentCourse.sections[indexSection].lessons[indexLesson].questions[indexQuestion].answers[indexAnswer];

        switch (answer.view_status) {
          case STATUS.origin:
            answer.view_status = STATUS.update;
            break;
          case STATUS.update:
            let oldAnswer = vm.getAnswerOld(section.id, lesson.id, question.id, answer.id);
            if (oldAnswer && oldAnswer.name === answer.name && oldAnswer.is_right === answer.is_right && oldAnswer.sequence === answer.sequence) {
              answer.view_status = STATUS.origin;
            }
            break;
        }
      },
      onChangeAnswerOrder(indexSection, indexLesson, indexQuestion, indexAnswer, order) {

        let vm = this;
        let section = vm.currentCourse.sections[indexSection];
        let lesson = vm.currentCourse.sections[indexSection].lessons[indexLesson];
        let question = vm.currentCourse.sections[indexSection].lessons[indexLesson].questions[indexQuestion];
        let answer = vm.currentCourse.sections[indexSection].lessons[indexLesson].questions[indexQuestion].answers[indexAnswer];

        if (answer.sequence !== order) {
          answer.sequence = order;
          switch (answer.view_status) {
            case STATUS.origin:
              answer.view_status = STATUS.update;
              break;
            case STATUS.update:
              let oldAnswer = vm.getAnswerOld(section.id, lesson.id, question.id, answer.id);
              if (oldAnswer && oldAnswer.name === answer.name && oldAnswer.is_right === answer.is_right && oldAnswer.sequence === answer.sequence) {
                answer.view_status = STATUS.origin;
              }
              break;
          }
        }
      },

      checkingSave() {
        let vm = this;
        let isChange = false;

        if (!vm.currentCourse) return false;

        if (vm.currentCourse.sections.length) {
          for (let idxSection = 0; idxSection < vm.currentCourse.sections.length; idxSection++) {
            let section = vm.currentCourse.sections[idxSection];
            if (section.view_status !== STATUS.origin) {
              isChange = true;
              break;
            } else {
              if (section.lessons.length) {
                for (let idxLesson = 0; idxLesson < section.lessons.length; idxLesson++) {
                  let lesson = section.lessons[idxLesson];
                  if (lesson.view_status !== STATUS.origin) {
                    isChange = true;
                    break;
                  } else {
                    if (lesson.questions && lesson.questions.length) {
                      for (let idxQuestion = 0; idxQuestion < lesson.questions.length; idxQuestion++) {
                        let question = lesson.questions[idxQuestion];
                        if (question.view_status !== STATUS.origin) {
                          isChange = true;
                          break;
                        } else {
                          if (question.answers.length) {
                            for (let idxAnswer = 0; idxAnswer < question.answers.length; idxAnswer++) {
                              let answer = question.answers[idxAnswer];
                              if (answer.view_status !== STATUS.origin) {
                                isChange = true;
                                break;
                              }
                            }
                          }
                        }
                        if (isChange) break;
                      }
                    }
                  }
                  if (isChange) break;
                }
              }
            }
            if (isChange) break;
          }
        }
        return isChange;
      },
      checkValidate() {
        let vm = this;
        let isPass = true;

        if (!vm.currentCourse) return false;

        if (!vm.currentCourse.name) return false;
        if (!vm.currentCourse.commitment) return false;
        if (!vm.goodFor) return false;
        if (!vm.currentCourse.detail) return false;
        if (!vm.currentCourse.description) return false;
        console.log(vm.currentCourse);
        if (vm.currentCourse.sections.length) {
          for (let idxSection = 0; idxSection < vm.currentCourse.sections.length; idxSection++) {
            let section = vm.currentCourse.sections[idxSection];
            if (!section.name) {
              isPass = false;
              break;
            }
            if (section.lessons.length) {
              for (let idxLesson = 0; idxLesson < section.lessons.length; idxLesson++) {
                let lesson = section.lessons[idxLesson];

                if (!lesson.name) {
                  isPass = false;
                  break;
                }

                if (lesson.questions.length) {
                  for (let idxQuestion = 0; idxQuestion < lesson.questions.length; idxQuestion++) {
                    let question = lesson.questions[idxQuestion];
                    if (!question.name) {
                      isPass = false;
                      break;
                    }
                    if (question.answers.length) {
                      if (!question.answers.some(answer => !!answer.is_right)) {
                        isPass = false;
                        this.$toasted.error(`Question "${question.name}" must have at least 1 right answer`)
                        break;
                      }
                      for (let idxAnswer = 0; idxAnswer < question.answers.length; idxAnswer++) {
                        let answer = question.answers[idxAnswer];
                        if (!answer.content) {
                          isPass = false;
                          break;
                        }
                      }
                    }
                    if (!isPass) break;
                  }
                }
                if (!isPass) break;
              }
            }
            if (!isPass) break;
          }
        }
        return isPass;
      },


      // drag drop course change order
      async onEndCourse(evt) {
        let vm = this;
        if (vm.courses.length) {
          for (let idxCourse = 0; idxCourse < vm.courses.length; idxCourse++) {
            vm.onChangeCourseOrder(idxCourse, idxCourse);
          }
        }
        await vm.saveCourseOrder();
      },
      // detail Course
      onStart(evt) {
        let vm = this;
        vm.isDragging = true;
      },
      onMove({relatedContext, draggedContext}) {
        const relatedElement = relatedContext.element;
        const draggedElement = draggedContext.element;
        return (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      },
      onEnd: function (evt) {
        let vm = this;
        if (vm.currentCourse.sections.length) {
          for (let idxSection = 0; idxSection < vm.currentCourse.sections.length; idxSection++) {
            vm.onChangeSectionOrder(idxSection, idxSection);
          }
        }
      },

      onStartLesson(evt) {
        let vm = this;
        vm.isDragging = true;
      },
      onMoveLesson(relatedContext, draggedContext) {
        // const relatedElement = relatedContext.element;
        // const draggedElement = draggedContext.element;
        // return (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      },
      onEndLesson(indexSection) {
        let vm = this;
        let section = vm.currentCourse.sections[indexSection];
        if (section.lessons.length) {
          for (let idxlesson = 0; idxlesson < section.lessons.length; idxlesson++) {
            vm.onChangeLessonOrder(indexSection, idxlesson, idxlesson);
          }
        }
      },

      onStartQuestion(evt) {
        let vm = this;
        vm.isDragging = true;
      },
      onMoveQuestion(relatedContext, draggedContext) {
        const relatedElement = relatedContext.element;
        const draggedElement = draggedContext.element;
        return (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      },
      onEndQuestion(indexSection, indexLesson) {
        let vm = this;
        let lesson = vm.currentCourse.sections[indexSection].lessons[indexLesson];
        if (lesson.questions.length) {
          for (let idxQuestion = 0; idxQuestion < lesson.questions.length; idxQuestion++) {
            vm.onChangeQuestionOrder(indexSection, indexLesson, idxQuestion, idxQuestion);
          }
        }
      },

      onStartAnswer(evt) {
        let vm = this;
        vm.isDragging = true;
      },
      onMoveAnswer(relatedContext, draggedContext) {
        const relatedElement = relatedContext.element;
        const draggedElement = draggedContext.element;
        return (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      },
      onEndAnswer(indexSection, indexLesson, indexQuestion) {
        let vm = this;
        let question = vm.currentCourse.sections[indexSection].lessons[indexLesson].questions[indexQuestion];
        if (question.answers.length) {
          for (let idxAnswer = 0; idxAnswer < question.answers.length; idxAnswer++) {
            vm.onChangeAnswerOrder(indexSection, indexLesson, indexQuestion, idxAnswer, idxAnswer);
          }
        }
      },

      detailCourse: function (indexCourse) {

        let vm = this;
        let course = vm.courses[indexCourse];
        if (!vm.currentCourse || (vm.currentCourse && vm.currentCourse.id !== course.id)) {
          vm.currentIdxCourse = indexCourse;

          if (vm.currentCourse && vm.currentCourse.id !== course.id && vm.isSave) {
            vm.showModal();
          } else {
            vm.selectCourse(vm.currentIdxCourse);
          }

        } else {
        }
      },
      selectCourseConfirm() {
        let vm = this;
        vm.modalPrevent = false;
        vm.selectCourse(vm.currentIdxCourse);
      },
      selectCourse(indexCourse) {
        let vm = this;
        vm.clearImages();
        vm.setCurrentCourse(vm.courses[indexCourse]);
        vm.tempCourse = JSON.parse(JSON.stringify(vm.currentCourse));
        vm.resetCurrentCourse();
        vm.setCurrentCourseId(vm.currentCourse.id);

      },
      deleteCourse: function (indexCourse) {

        let vm = this;
        vm.deleteIdxCourse = indexCourse;
        vm.showModalDelete();

      },
      async duplicateCourse (event, indexCourse) {
        event.preventDefault();
        const vm = this;
        const course = vm.courses[indexCourse];
        if (course && !vm.duplicatingStages[course.id]) {
          vm.duplicatingStages = {
            ...vm.duplicatingStages,
            [course.id]: true
          };
          const res = await vm.$services.Course.duplicate(course.id);
          vm.duplicatingStages = {
            ...vm.duplicatingStages,
            [course.id]: false
          };

          const newCourse = this.courseItemObject(res.data.course, vm.courses.length);
          vm.courses.splice((indexCourse + 1), 0, newCourse);
          vm.selectCourse(indexCourse + 1);

        }
      },
      confirmDeleteCourse () {
        let vm = this;

        if (vm.deleteIdxCourse !== null) {
          if (vm.courses[vm.deleteIdxCourse].view_status !== STATUS.new) {
            vm.isLoading = true;
            vm.modalDelete = false;

            vm.$services.Course.delete(vm.courses[vm.deleteIdxCourse]).then((res) => {
              if (vm.currentCourse && vm.currentCourse.id === vm.courses[vm.deleteIdxCourse].id) {
                vm.setCurrentCourse(vm.courses[0]);

                vm.tempCourse = JSON.parse(JSON.stringify(vm.currentCourse));
                vm.clearImages();
                vm.resetCurrentCourse();
                vm.setCurrentCourseId(vm.currentCourse.id);
              }
              vm.courses.splice(vm.deleteIdxCourse, 1);
              vm.deleteIdxCourse = null;
              vm.isLoading = false;
            }, (err) => {
              vm.deleteIdxCourse = null;
              vm.modalDelete = false;
              vm.isLoading = false;
            });
          } else {
            if (vm.currentCourse && vm.currentCourse.id === vm.courses[vm.deleteIdxCourse].id) {
              vm.setCurrentCourse(vm.courses[0]);

              vm.tempCourse = JSON.parse(JSON.stringify(vm.currentCourse));
              vm.clearImages();
              vm.resetCurrentCourse();
              vm.setCurrentCourseId(vm.currentCourse.id);
            }
            vm.courses.splice(vm.deleteIdxCourse, 1);
            vm.deleteIdxCourse = null;
            vm.modalDelete = false;
          }
        }
      },
      cancelDeleteCourse() {
        let vm = this;
        vm.deleteIdxCourse = null;
        vm.modalDelete = false;
      },
      addCourse: function () {
        let vm = this;

        let lengthCourse = vm.courses.length;
        let orderIndex = 0;
        if (lengthCourse > 0) {
          orderIndex = vm.courses[lengthCourse - 1].order + 1;
        }
        let newIdCourse = uuid.v4();

        vm.courses.push({
          id: newIdCourse,
          name: 'Course name',
          intro_video: '',
          commitment: 0,
          description: '',
          detail: '',
          good_for: [],
          coming_soon: false,
          show_on_site: false,
          show_on_homepage: false,
          sections: [],
          thumbnail_path: null,
          feature_image_path: null,
          isActive: false,
          view_status: STATUS.new,
          order_status: STATUS.new,
          order: orderIndex,
          email_on_completion: {
            checked: false,
            email: '',
            subject: ''
          }
        });

        let currentCourseIdx = vm.getIdxCourse(newIdCourse);
        // vm.currentCourse = vm.courses[currentCourseIdx];
        vm.setCurrentCourse(vm.courses[currentCourseIdx]);

        vm.tempCourse = JSON.parse(JSON.stringify(vm.currentCourse));
        vm.clearImages();
        vm.resetCurrentCourse();
        vm.setCurrentCourseId(newIdCourse);

      },

      async saveCourseOrder() {
        let vm = this;
        vm.isLoading = true;

        if (vm.courses.length) {
          await vm.$services.Course.order({
            data: vm.courses.map(course => {
              return {
                course_id: course.id,
                order: course.order
              }
            })
          }).then(res => {
          }, err => {
          });
          for (let idxCourse = 0; idxCourse < vm.courses.length; idxCourse++) {
            vm.courses[idxCourse].order_status = STATUS.origin;
          }
        }

        vm.isLoading = false;

      },
      deleteSection(indexSection) {
        if (this.currentCourse.sections[indexSection].view_status === STATUS.new) {
          this.currentCourse.sections.splice(indexSection, 1);
        } else {
          this.currentCourse.sections[indexSection].view_status = STATUS.del;
        }
      },
      addSection(courseId) {
        let vm = this;
        let lengthSection = vm.currentCourse.sections.length;
        let orderIndex = 0;
        if (lengthSection > 0) {
          orderIndex = vm.currentCourse.sections[lengthSection - 1].sequence + 1;
        }
        let newIdSection = uuid.v4();
        vm.currentCourse.sections.push({
          id: newIdSection,
          name: '',
          course_id: vm.currentCourse.id,
          sequence: orderIndex,
          lessons: [],
          isActive: false,
          view_status: STATUS.new,
        });
      },

      deleteLesson(indexSection, indexLesson) {
        if (this.currentCourse.sections[indexSection].lessons[indexLesson].view_status === STATUS.new) {
          this.currentCourse.sections[indexSection].lessons.splice(indexLesson, 1);
        } else {
          this.currentCourse.sections[indexSection].lessons[indexLesson].view_status = STATUS.del;
        }
      },
      addLesson(indexSection) {

        let vm = this;
        let lengthLesson = 0;

        if (indexSection !== 0) {
          lengthLesson = vm.currentCourse.sections[indexSection].lessons.length;
        }

        let orderIndex = 0;
        if (lengthLesson > 0) {
          orderIndex = vm.currentCourse.sections[indexSection].lessons[lengthLesson - 1].sequence + 1;
        }
        let newIdLesson = uuid.v4();
        vm.currentCourse.sections[indexSection].lessons.push({
          id: newIdLesson,
          section_id: vm.currentCourse.sections[indexSection].id,
          name: '',
          sequence: orderIndex,
          questions: [],
          isActive: false,
          view_status: STATUS.new,
        });

      },

      deleteQuestions(indexSection, indexLesson, indexQuestion) {
        if (this.currentCourse.sections[indexSection].lessons[indexLesson].questions[indexQuestion].view_status === STATUS.new) {
          this.currentCourse.sections[indexSection].lessons[indexLesson].questions.splice(indexQuestion, 1);
        } else {
          this.currentCourse.sections[indexSection].lessons[indexLesson].questions[indexQuestion].view_status = STATUS.del;
        }
      },
      addQuestion(indexSection, indexLesson) {

        let vm = this;

        let lengthQuestion = vm.currentCourse.sections[indexSection].lessons[indexLesson].questions.length;
        let orderIndex = 0;
        if (lengthQuestion > 0) {
          orderIndex = vm.currentCourse.sections[indexSection].lessons[indexLesson].questions[lengthQuestion - 1].sequence + 1;
        }
        let newIdQuestion = uuid.v4();
        vm.currentCourse.sections[indexSection].lessons[indexLesson].questions.push({
          id: newIdQuestion,
          name: '',
          lesson_id: vm.currentCourse.sections[indexSection].lessons[indexLesson].id,
          sequence: orderIndex,
          answers: [],
          isActive: false,
          view_status: STATUS.new,
        })
      },

      deleteAnswer(indexSection, indexLesson, indexQuestion, indexAnswer) {
        if (this.currentCourse.sections[indexSection].lessons[indexLesson].questions[indexQuestion].answers[indexAnswer].view_status === STATUS.new) {
          this.currentCourse.sections[indexSection].lessons[indexLesson].questions[indexQuestion].answers.splice(indexAnswer, 1);
        } else {
          this.currentCourse.sections[indexSection].lessons[indexLesson].questions[indexQuestion].answers[indexAnswer].view_status = STATUS.del;
        }
      },
      addAnswer(indexSection, indexLesson, indexQuestion) {

        let vm = this;

        let lengthAnswer = vm.currentCourse.sections[indexSection].lessons[indexLesson].questions[indexQuestion].answers.length;
        let orderIndex = 0;
        if (lengthAnswer > 0) {
          orderIndex = vm.currentCourse.sections[indexSection].lessons[indexLesson].questions[indexQuestion].answers[lengthAnswer - 1].sequence + 1;
        }
        let newIdAnswer = uuid.v4();
        vm.currentCourse.sections[indexSection].lessons[indexLesson].questions[indexQuestion].answers.push({
          id: newIdAnswer,
          title: '',
          question_id: vm.currentCourse.sections[indexSection].lessons[indexLesson].questions[indexQuestion].id,
          is_right: false,
          sequence: orderIndex,
          isActive: false,
          view_status: STATUS.new,
        })
      },

      //upload file
      callBackSendingThumbnailPhoto() {
        this.uploadingThumbnailPhoto = true;
      },
      callBackAddedFileThumbnailPhoto(file, dataUrl) {
        if (file && file.status !== 'error') {
          this.newThumbnailPhoto = dataUrl
        }
      },
      callBackFileErrorThumbnailPhoto(file, message) {
        if (typeof message !== 'string') {
        } else {
        }
        this.newThumbnailPhoto = null
      },
      callBackUploadSuccessThumbnailPhoto(file, response) {
        this.currentCourse.thumbnail_path = response.data.path;
        this.uploadingThumbnailPhoto = false;
        this.courseStatus.isThumbnailPhoto = true;

      },
      callBackUploadCompleteThumbnailPhoto(file) {
        this.uploadingThumbnailPhoto = false;
      },

      callBackSendingFeaturePhoto() {
        this.uploadingFeaturePhoto = true;
      },
      callBackAddedFileFeaturePhoto(file, dataUrl) {
        if (file && file.status !== 'error') {
          this.newFeaturePhoto = dataUrl
        }
      },
      callBackFileErrorFeaturePhoto(file, message) {
        if (typeof message !== 'string') {
        } else {
        }
        this.newFeaturePhoto = null
      },
      callBackUploadSuccessFeaturePhoto(file, response) {
        this.currentCourse.feature_image_path = response.data.path;
        this.uploadingFeaturePhoto = false;

        this.courseStatus.isFeaturePhoto = true;

      },
      callBackUploadCompleteFeaturePhoto(file) {
        this.uploadingFeaturePhoto = false;
      },

      // upload pdf
      callBackSendingPDF() {
        this.uploadingPDF = true;
      },
      callBackAddedFilePDF(file, dataUrl) {
        if (file && file.status !== 'error') {
          this.newPDF = dataUrl
        }
      },
      callBackFileErrorPDF(file, message) {
        if (typeof message !== 'string') {
        } else {
        }
        this.newPDF = null
      },
      callBackUploadSuccessPDF(file, response) {
        this.currentCourse.pdf_description_id = response.data.file.id;
        this.uploadingPDF = false;
        this.courseStatus.isPDF = true;

      },
      callBackUploadCompletePDF(file) {
        this.currentCourse.pdfFileName = file.name;
        this.currentCourse.hasPDF = true;
        this.uploadingPDF = false;
      },

      removePDF(currentCourse) {
        this.currentCourse.pdf_description_id = null;
        delete this.currentCourse.pdfFileName;
        this.currentCourse.hasPDF = false;
        this.courseStatus.isPDF = true;
      },

      uploadingNote (key) {
        this.uploadingPDF = key;
      },

      uploadNoteSuccess (file, res, type, sectionIndex, lessonIndex) {
        switch (type) {
          case NOTE_TYPES.COURSE:
            this.addCourseNote('Download course notes', res.data.path);
            break;
          case NOTE_TYPES.SECTION:
            this.addSectionNote('Download section notes', res.data.path, sectionIndex);
            break;
          case NOTE_TYPES.LESSON:
            this.addLessonNote('Download lesson notes', res.data.path, sectionIndex, lessonIndex);
        }

      },

      uploadNoteComplete (file) {

      },

      uploadCourseNoteSuccess (file, res) {
        // this.addNote('Document', res.file)
      },

      uploadCourseNoteCompleted (file) {

      },

      getNoteNameFromNotePath(file_path) {
        if (!file_path) {
          return '';
        }
        return file_path.split('/').pop().slice(13);
      },

      addCourseNote (name, file_path) {
        const notes = [...this.currentCourse.notes];
        if (notes.length > 0) {
          notes[0] = Object.assign(notes[0], {
            label: name,
            file_path
          });
        } else {
          notes.push({
            label: name,
            file_path
          });
        }

        this.setCurrentCourse({
          ...this.currentCourse,
          notes
        });
      },

      removeCourseNote (index) {
        const notes = [...this.currentCourse.notes];
        notes.splice(index, 1);
        this.setCurrentCourse({
            ...this.currentCourse,
            notes
          });
      },
      addSectionNote (name, file_path, index) {
        const section = this.currentCourse.sections[index];
        if (section.notes === undefined) {
          section.notes = [];
        }
        if (section.notes.length > 0) {
          section.notes[0] = Object.assign(section.notes[0], {
            label: name,
            file_path
          });
        } else {
          section.notes.push({
            label: name,
            file_path
          });
        }
        if (section.id && section.view_status !== STATUS.new) {
          section.view_status = STATUS.update;
        }
        this.setCurrentCourse({
          ...this.currentCourse
        });
      },
      removeSectionNote (indexSection, indexNote) {
        const section = this.currentCourse.sections[indexSection];
        if (section.id && section.view_status !== STATUS.new) {
          section.view_status = STATUS.update;
        }
        section.notes.splice(indexNote, 1);
        this.setCurrentCourse({
          ...this.currentCourse
        });
      },
      addLessonNote (name, file_path, indexSection, indexLesson) {
        const section = this.currentCourse.sections[indexSection];
        const lesson = section.lessons[indexLesson];

        if (lesson.notes === undefined) {
          lesson.notes = [];
        }
        if (lesson.notes.length > 0) {
          lesson.notes[0] = Object.assign(lesson.notes[0], {
            label: name,
            file_path
          });
        } else {
          lesson.notes.push({
            label: name,
            file_path
          });
        }
        if (lesson.id && lesson.view_status !== STATUS.new) {
          lesson.view_status = STATUS.update;
        }
        this.setCurrentCourse({
          ...this.currentCourse
        });
      },
      removeLessonNote (indexSection, indexLesson, indexNote) {
        const section = this.currentCourse.sections[indexSection];
        const lesson = section.lessons[indexLesson];
        if (lesson.id && lesson.view_status !== STATUS.new) {
          lesson.view_status = STATUS.update;
        }
        lesson.notes.splice(indexNote, 1);
        this.setCurrentCourse({
          ...this.currentCourse
        });
      },
      onChangeNoteLabel (event, target, index, status = 'isNote') {
        const vm = this;
        const currentNote = target.notes[index];
        if (currentNote.id) {
          const tempNote = vm.getTempNote(currentNote.id);
          let hasChanged = false;
          if (currentNote.label !== tempNote.label) {
            hasChanged = true;
            // vm.courseStatus = {
            //   ...vm.courseStatus,
            //   [status]: true
            // };
          } else {
            target.notes.some((note) => {
              if (note.id) {
                const temp = vm.getTempNote(note.id);
                if (temp.label !== note.label) {
                  hasChanged = true;
                  return true;
                }
              }
            });
          }

          if (hasChanged && target.view_status !== STATUS.new) {
            target.view_status = STATUS.update;
          }

          vm.courseStatus = {
            ...vm.courseStatus,
            [status]: hasChanged
          };
        }
      },

      saveTempNote (note) {
        if (this.tempNotes === undefined) {
          this.tempNotes = {};
        }
        const tempNote = this.tempNotes[note.id];
        if (!tempNote) {
          this.tempNotes[note.id] = {...note};
        }
        else if (JSON.stringify(tempNote) !== JSON.stringify(note) && !this.courseStatus.isSectionNote) {
          this.tempNotes[note.id] = {...note};
        }
      },

      getTempNote(id) {
        return this.tempNotes[id];
      },

      onEditNoteLabel (target, type, index) {
        this.labelEdittingID = this.editingId(type, target.id, index);
        const note = target.notes[index];
        if (note.id) {
          this.saveTempNote(note);
        }
        setTimeout(() => {
          const input = this.$refs[this.labelEdittingID][0];
          input.focus();
          input.value = note.label + ' ';
        }, 100);
      },

      onBlurNoteLabel () {
        this.labelEdittingID = false;
      },

      editingId (type, id, index) {
        return type + ':' + id + ':' + index;
      },

      getEditingId () {
        const [type, index] = this.labelEdittingID.split(':');
        return {
          type, index
        };
      },

      // save course
      async saveCoure() {
        // check validate before
        let vm = this;

        vm.isSaving = true;

        // sections
        async function updateSection(idxSection) {

          let section = vm.currentCourse.sections[idxSection];
          const res = await vm.$services.Section.update(section);
            section.view_status = STATUS.origin;
            vm.currentCourse.sections[idxSection] = Object.assign(section, res.data.data.section);
        }

        async function addSection(idxSection) {
          let section = vm.currentCourse.sections[idxSection];
          await vm.$services.Section.create(section).then(res => {
            section.view_status = STATUS.origin;
            vm.currentCourse.sections[idxSection] = Object.assign(section, res.data.data.section);
          });
        }

        async function delSection(idxSection) {
          let section = vm.currentCourse.sections[idxSection];
          const res = await vm.$services.Section.delete(section);
          let idx = vm.currentCourse.sections.findIndex((s) => (s.id === section.id));
          if (idx !== -1) {
            vm.currentCourse.sections.splice(idx, 1);
          }
          idx = vm.tempCourse.sections.findIndex((s) => (s.id === section.id));
          if (idx !== -1) {
            vm.tempCourse.sections.splice(idx, 1);
          }
        }

        // Lessons
        async function updateLesson(idxSection, idxLesson) {
          let lesson = vm.currentCourse.sections[idxSection].lessons[idxLesson];
          await vm.$services.Lesson.update(lesson).then(res => {
            lesson.view_status = STATUS.origin;
            vm.currentCourse.sections[idxSection].lessons[idxLesson] = Object.assign(lesson, res.data.data.lesson);
          });
        }

        async function addLesson(idxSection, idxLesson) {
          const section = vm.currentCourse.sections[idxSection];
          const lesson = vm.currentCourse.sections[idxSection].lessons[idxLesson];

          lesson.section_id = section.id;
          await vm.$services.Lesson.create(lesson).then(res => {
            lesson.view_status = STATUS.origin;
            vm.currentCourse.sections[idxSection].lessons[idxLesson] = Object.assign(lesson, res.data.data.lesson);
          });
        }

        async function delLesson(idxSection, idxLesson) {

          let lesson = vm.currentCourse.sections[idxSection].lessons[idxLesson];

          await vm.$services.Lesson.delete(lesson);
          const lessons = vm.tempCourse.sections[idxSection].lessons;

          let idx = vm.currentCourse.sections[idxSection].lessons.findIndex((s) => (s.id === lesson.id));
          if (idx !== -1) {
            vm.currentCourse.sections[idxSection].lessons.splice(idx, 1);
          }
          idx = lessons.findIndex((s) => (s.id === lesson.id));
          if (idx !== -1) {
            vm.tempCourse.sections[idxSection].lessons.splice(idx, 1);
          }
        }

        // questions
        async function updateQuestion(idxSection, idxLesson, idxQuestion) {

          let question = vm.currentCourse.sections[idxSection].lessons[idxLesson].questions[idxQuestion];

          await vm.$services.Question.update(question).then(res => {
            question.view_status = STATUS.origin;
          });
        }

        async function addQuestion(idxSection, idxLesson, idxQuestion) {

          let question = vm.currentCourse.sections[idxSection].lessons[idxLesson].questions[idxQuestion];

          await vm.$services.Question.create(question).then(res => {
            let tempRes = JSON.parse(JSON.stringify(res));
            question.id = tempRes.data.data.question.id;
            question.view_status = STATUS.origin;
          });
        }

        async function delQuestion(idxSection, idxLesson, idxQuestion) {

          let question = vm.currentCourse.sections[idxSection].lessons[idxLesson].questions[idxQuestion];

          await vm.$services.Question.delete(question).then(res => {
            vm.currentCourse.sections[idxSection].lessons[idxLesson].questions.splice(idxQuestion, 1);
          });
        }

        // answers
        async function updateAnswer(idxSection, idxLesson, idxQuestion, idxAnswer) {

          let answer = vm.currentCourse.sections[idxSection].lessons[idxLesson].questions[idxQuestion].answers[idxAnswer];

          await vm.$services.Answer.update(answer).then(res => {
            answer.view_status = STATUS.origin;
          });
        }

        async function addAnswer(idxSection, idxLesson, idxQuestion, idxAnswer) {

          let answer = vm.currentCourse.sections[idxSection].lessons[idxLesson].questions[idxQuestion].answers[idxAnswer];

          await vm.$services.Answer.create(answer).then(res => {
            let tempRes = JSON.parse(JSON.stringify(res));
            answer.id = tempRes.data.data.answer.id;
            answer.view_status = STATUS.origin;
          });
        }

        async function delAnswer(idxSection, idxLesson, idxQuestion, idxAnswer) {

          let answer = vm.currentCourse.sections[idxSection].lessons[idxLesson].questions[idxQuestion].answers[idxAnswer];

          await vm.$services.Answer.delete(answer).then(res => {
            vm.currentCourse.sections[idxSection].lessons[idxLesson].questions[idxQuestion].answers.splice(idxAnswer, 1);
          });

        }

        let isUpdated = false, isCreated = false;

        switch (vm.currentCourse.view_status) {
          case STATUS.update:

            if (vm.courseStatus.isName ||
              vm.courseStatus.isVideoId ||
              vm.courseStatus.isCommitment ||
              vm.courseStatus.isGoodFor ||
              vm.courseStatus.isDetail ||
              vm.courseStatus.isDescription ||
              vm.courseStatus.isComingSoon ||
              vm.courseStatus.isShowOnSite ||
              vm.courseStatus.isShowHome ||
              vm.courseStatus.isThumbnailPhoto ||
              vm.courseStatus.isFeaturePhoto ||
              vm.courseStatus.for_owner ||
              vm.courseStatus.for_head_trainer ||
              vm.courseStatus.for_marketer ||
              vm.courseStatus.for_trainer ||
              vm.courseStatus.for_studio_manager ||
              vm.courseStatus.isPDF ||
              vm.courseStatus.isRestriction ||
              vm.courseStatus.isNote ||
              vm.courseStatus.isSectionNote ||
              vm.courseStatus.isChangeLanguage ||
              vm.courseStatus.isRequiredFor ||
              vm.courseStatus.userTypeCategories ||
              vm.courseStatus.emailCheck ||
              vm.courseStatus.emailAddress ||
              vm.courseStatus.emailSubject
            ) {

              vm.currentCourse.for_owner = vm.currentCourse.for_owner ? parseInt(vm.currentCourse.for_owner) : undefined
              vm.currentCourse.for_head_trainer = vm.currentCourse.for_head_trainer ? parseInt(vm.currentCourse.for_head_trainer) : undefined
              vm.currentCourse.for_marketer = vm.currentCourse.for_marketer ? parseInt(vm.currentCourse.for_marketer) : undefined
              vm.currentCourse.for_trainer = vm.currentCourse.for_trainer ? parseInt(vm.currentCourse.for_trainer) : undefined
              vm.currentCourse.for_studio_manager = vm.currentCourse.for_studio_manager ? parseInt(vm.currentCourse.for_studio_manager) : undefined
              let course = vm.currentCourse;
              if (!course.thumbnail_path) {
                delete  course.thumbnail_path;
              }
              if (!course.feature_image_path) {
                delete  course.feature_image_path;
              }
              await vm.$services.Course.update(course).then(res => {
                delete res.data.data.course.sections
                this.setCurrentCourse(Object.assign(vm.currentCourse, res.data.data.course));
                isUpdated = true;
              }, err => {
              });
            } else {
              isUpdated = true;
            }

            break;

          case STATUS.new:

            let course = JSON.parse(JSON.stringify(vm.currentCourse));

            if (!course.thumbnail_path) {
              delete  course.thumbnail_path;
            }
            if (!course.feature_image_path) {
              delete  course.feature_image_path;
            }

            await vm.$services.Course.create(course).then(res => {
              delete res.data.data.course.sections
              vm.setCurrentCourse(Object.assign(vm.currentCourse, res.data.data.course));
                vm.setCurrentCourseId(vm.currentCourse.id);

                isUpdated = true;
                isCreated = true;

              }, err => {
              });

        }

        if (vm.currentCourse.sections.length && isUpdated) {
              const sections = [], lessons = [], questions = [], answers = [];

              vm.currentCourse.sections.forEach((section, idxSection) => {
                if (isCreated) {
                  section.course_id = vm.currentCourse.id;
                }
                switch (section.view_status) {
                  case STATUS.update:
                    sections.push(updateSection(idxSection));
                    break;
                  case STATUS.new:
                    sections.push(addSection(idxSection));
                    break;
                  case STATUS.del:
                    sections.push(delSection(idxSection));
                    break;
                }
              });

              await Promise.all(sections);

              vm.currentCourse.sections.forEach((section, idxSection) => {
                if (section.lessons.length) {
                  section.lessons.forEach((lesson, idxLesson) => {
                    lesson.section_id = section.id;
                    switch (lesson.view_status) {
                      case STATUS.update:
                        lessons.push(updateLesson(idxSection, idxLesson));
                        break;
                      case STATUS.new:
                        lessons.push(addLesson(idxSection, idxLesson));
                        break;
                      case STATUS.del:
                        lessons.push(delLesson(idxSection, idxLesson));
                        break;
                    }
                  });
                }
              });

              await Promise.all(lessons);

              vm.currentCourse.sections.forEach((section, idxSection) => {
                if (section.lessons.length) {
                  section.lessons.forEach((lesson, idxLesson) => {
                    if (lesson.questions.length) {
                      lesson.questions.forEach((question, idxQuestion) => {
                        question.lesson_id = lesson.id;
                        switch (question.view_status) {
                          case STATUS.update:
                            questions.push(updateQuestion(idxSection, idxLesson, idxQuestion));
                            break;
                          case STATUS.new:
                            questions.push(addQuestion(idxSection, idxLesson, idxQuestion));
                            break;
                          case STATUS.del:
                            questions.push(delQuestion(idxSection, idxLesson, idxQuestion));
                            break;
                        }
                      });
                    }
                  });
                }
              });

              await Promise.all(questions);

              vm.currentCourse.sections.forEach((section, idxSection) => {
                if (section.lessons.length) {
                  section.lessons.forEach((lesson, idxLesson) => {
                    if (lesson.questions.length) {
                      lesson.questions.forEach((question, idxQuestion) => {
                        if (question.answers.length) {
                          question.answers.forEach((answer, idxAnswer) => {
                            answer.question_id = question.id;
                            switch (answer.view_status) {
                              case STATUS.update:
                                answers.push(updateAnswer(idxSection, idxLesson, idxQuestion, idxAnswer));
                                break;
                              case STATUS.new:
                                answers.push(addAnswer(idxSection, idxLesson, idxQuestion, idxAnswer));
                                break;
                              case STATUS.del:
                                answers.push(delAnswer(idxSection, idxLesson, idxQuestion, idxAnswer));
                            }
                          })
                        }
                      });
                    }
                  });
                }
              });

              await Promise.all(answers);

              vm.setCurrentCourse({
                ...vm.currentCourse
              });
            }

            if (isUpdated) {
              vm.tempCourse = JSON.parse(JSON.stringify(vm.currentCourse));
              vm.resetCurrentCourse();
              if (vm.currentCourse) {
                vm.currentCourse.view_status = STATUS.origin;
              }
            }

            vm.isSaving = false;

      },

      // control view
      resetCurrentCourse() {

        let vm = this;
        vm.courseStatus = {
          isName: false,
          isVideoId: false,
          isCommitment: false,
          isGoodFor: false,
          isDetail: false,
          isDescription: false,
          isComingSoon: false,
          isShowOnSite: false,
          isShowHome: false,
          isThumbnailPhoto: false,
          isFeaturePhoto: false,
          isSection: false,
          for_owner: false,
          for_head_trainer: false,
          for_marketer: false,
          for_trainer: false,
          for_studio_manager: false,
          isPDF: false,
          isRestriction: false,
          isNote: false,
          isSectionNote: false,
          isChangeLanguage: false,
          isRequiredFor: false,
          userTypeCategories: false,
          emailCheck: false,
          emailAddress: false,
          emailSubject: false
        }
      },
      clearImages() {
        this.newThumbnailPhoto = null;
        this.newFeaturePhoto = null;
      },
      submitForm(form) {

        let vm = this;

        if (vm.checkValidate()) {
          vm.saveCoure();
        } else {
          vm.formHasError = true;
        }

      },
      resetForm() {
        let vm = this;

      },
      showModal() {
        let vm = this;

        vm.modalPreventTitle = 'If you open other link, current data was lost?';
        vm.modalPrevent = true;

      },
      showModalDelete() {
        let vm = this;

        vm.modalDeleteTitle = 'Are you sure  delete this course?';
        vm.modalDelete = true;

      },

      setCurrentCourseId(currentCourseId) {
        let vm = this;
        vm.$localStorage.set('current_course_id', currentCourseId);
      },

      init() {
        let vm = this;
        if (vm.isLoadCourses) {
          vm.isReady = true;
        }
      },

      addRestriction (event) {
        let type = RESTRICTION_TYPES.COUNTRY;
        let defaultValue = '';
        this.countryOptions.some((opt) => {
          const idx = this.currentCourse.location_availabilities.findIndex((res) => res.value === opt);
          if (idx === -1) {
            defaultValue = opt;
            return true;
          }
        });

        if (defaultValue === '') {
          type = RESTRICTION_TYPES.STUDIO;
        }

        this.setCurrentCourse({
          ...this.currentCourse,
          location_availabilities: [
            ...this.currentCourse.location_availabilities,
            { id: uuid.v4(), type: type, value: defaultValue }
          ]
        });
      },
      addLanguage() {
        var available_languages = window.mcf.languages.filter((item, index) => {
          let count = 0;
          for (const language of this.currentCourse.languages) {
            if (language.language === item.code) {
              count++;
            }
          }

          if (count !== 1) {
            return item;
          }
        })
        if(!available_languages.length) return
        this.currentCourse.languages.push({
          language: available_languages[0].code
        });
        this.courseStatus.isChangeLanguage = true;
      },

      removeRestriction (index) {
        const location_availabilities = [
          ...this.currentCourse.location_availabilities
        ];

        location_availabilities.splice(index, 1);

        this.setCurrentCourse({
          ...this.currentCourse,
          location_availabilities
        })
      },
      removeLanguage (index) {
        this.currentCourse.languages.splice(index, 1)
        this.courseStatus.isChangeLanguage = true;
      },

      onChangeRestriction (index) {
        const vm = this;
        const restriction = this.currentCourse.location_availabilities[index];
        const tempRestriction = this.tempCourse.location_availabilities.find((res) => (res.id === restriction.id));
        let hasChanged = false;
        if (!tempRestriction) {
          hasChanged = true;
        }
        else if (JSON.stringify(restriction) !== JSON.stringify(tempRestriction)) {
          hasChanged = true;
        }

        if (hasChanged && vm.currentCourse.view_status !== STATUS.new) {
          vm.currentCourse.view_status = STATUS.update;
        }

        vm.courseStatus = {
          ...vm.courseStatus,
          ['isRestriction']: hasChanged
        }
      },

      onChangeLanguage (index) {
        this.courseStatus.isChangeLanguage = true
      },

      isDisabledLanguage(lang_code) {
        for (const language of this.currentCourse.languages) {
          if (language.language === lang_code) {
            return true
          }
        }
        return false;
      },

      setCurrentCourse (course) {
        if (course.notes === undefined) {
          course.notes = [];
        }

        if (course.location_availabilities === undefined) {
          course.location_availabilities = [];
        }

        if (course.languages === undefined) {
          course.languages = [];
        }

        this.currentCourse = course;
      },
      selectedCountry (value, restriction) {
        const vm = this;
        if (value === restriction.value) {
          return true;
        }
        else {
          const index = vm.currentCourse.location_availabilities.findIndex((res) => {
            return res.type === RESTRICTION_TYPES.COUNTRY && res.value === value;
          });
          return (index === -1);
        }
      },
      courseItemObject (courseItem, index) {
        courseItem.isActive = false;
        courseItem.view_status = STATUS.origin;
        courseItem.order_status = STATUS.origin;
        if (!courseItem.email_on_completion) {
          courseItem.email_on_completion = {
            checked: false,
            email: '',
            subject: ''
          }
        }

        if (courseItem.show_on_site === undefined) {
          courseItem.show_on_site = false;
        }

        if (courseItem.coming_soon === undefined) {
          courseItem.coming_soon = false;
        }

        if (courseItem.show_on_homepage === undefined) {
          courseItem.show_on_homepage = false;
        }

        if (courseItem.order === undefined) {
          courseItem.order = index;
        }

        if (courseItem.pdf_description && courseItem.pdf_description.description) {
          courseItem.pdfFileName = courseItem.pdf_description.description;
        }

        if (courseItem.pdf_description) {
          courseItem.hasPDF = true;
        }

        if (0 < (courseItem.sections &&courseItem.sections.length)) {

          courseItem.sections.sort(function (a, b) {
            return a.sequence - b.sequence
          });

          let sections = courseItem.sections.map(sectionItem => {

            sectionItem.isActive = false;
            sectionItem.view_status = STATUS.origin;

            if (0 < sectionItem.lessons.length) {

              sectionItem.lessons.sort(function (a, b) {
                return a.sequence - b.sequence
              });

              let lessons = sectionItem.lessons.map(lessonItem => {

                lessonItem.isActive = false;
                lessonItem.view_status = STATUS.origin;

                if (0 < lessonItem.questions.length) {

                  lessonItem.questions.sort(function (a, b) {
                    return a.sequence - b.sequence
                  });

                  let questions = lessonItem.questions.map(questionsItem => {

                    questionsItem.view_status = STATUS.origin;

                    if (0 < questionsItem.answers.length) {
                      questionsItem.answers.sort(function (a, b) {
                        return a.sequence - b.sequence
                      });

                      let answers = questionsItem.answers.map(answerItem => {
                        answerItem.view_status = STATUS.origin;
                        return answerItem;
                      });
                      questionsItem.answers = answers;
                    }
                    return questionsItem;
                  });
                  lessonItem.questions = questions;
                }
                return lessonItem;
              });
              sectionItem.lessons = lessons;
            }
            return sectionItem;
          });
          courseItem.sections = sections;
        }
        return courseItem;
      }
    },
    computed: {
      dragOptionsCourse() {
        return {
          animation: 150,
          group: 'course',
          disabled: !this.editable,
          ghostClass: 'ghost',
        };
      },
      dragOptions() {
        return {
          animation: 150,
          group: 'description',
          disabled: !this.editable,
          ghostClass: 'ghost',
        };
      },
      dragOptionsSection() {
        return {
          animation: 150,
          ghostClass: 'ghost',
          group: 'sections',
          disabled: !this.editable,
        };
      },
      dragOptionsLesson() {
        return {
          animation: 150,
          ghostClass: 'ghost',
          group: 'lessons',
          disabled: !this.editable,
        };
      },
      dragOptionsQuestion() {
        return {
          animation: 150,
          ghostClass: 'ghost',
          group: 'questions',
          disabled: !this.editable,
        };
      },
      dragOptionsAnswer() {
        return {
          animation: 150,
          ghostClass: 'ghost',
          group: 'answers',
          disabled: !this.editable,
        };
      },
      isSave() {

        let vm = this;

        let isChange = false;
        if (vm.getFailureMessageEmailOnCompletion()) {
          return false;
        }

        if (vm.currentCourse) {
          vm.courseStatus.isSection = vm.checkingSave();
        } else {
          vm.courseStatus.isSection = false;
        }

        if (this.courseStatus.isName ||
          this.courseStatus.isVideoId ||
          this.courseStatus.isCommitment ||
          this.courseStatus.isGoodFor ||
          this.courseStatus.isDetail ||
          this.courseStatus.isDescription ||
          this.courseStatus.isComingSoon ||
          this.courseStatus.isShowOnSite ||
          this.courseStatus.isShowHome ||
          this.courseStatus.isThumbnailPhoto ||
          this.courseStatus.isFeaturePhoto ||
          this.courseStatus.isSection ||
          this.courseStatus.for_owner ||
          this.courseStatus.for_head_trainer ||
          this.courseStatus.for_marketer ||
          this.courseStatus.for_trainer ||
          this.courseStatus.for_studio_manager ||
          this.courseStatus.isPDF ||
          this.courseStatus.isRestriction ||
          this.courseStatus.isNote ||
          this.courseStatus.isSectionNote ||
          this.courseStatus.isChangeLanguage ||
          this.courseStatus.isRequiredFor ||
          this.courseStatus.userTypeCategories ||
          this.courseStatus.emailCheck ||
          this.courseStatus.emailAddress ||
          this.courseStatus.emailSubject
        ) {
          return true;
        }
        return false;
      },
      commitment: {
        get: function () {
          return this.currentCourse.commitment + 'HRS';
        },
        set: function (newValue) {
          let number = newValue.replace(/[^\d.]/g, '');
          this.currentCourse.commitment = isNaN(number) ? 0 : number
        }
      },
      goodFor: {
        get: function () {
          let goodFor = [];
          if (this.currentCourse) {
            goodFor = this.currentCourse.good_for ? this.currentCourse.good_for : [];
          }
          return goodFor.join(', ');
        },
        set: function (newValue) {
          this.currentCourse.good_for = newValue.split(', ');
        }
      },
      countryOptions () {
        const vm = this;
        const countries = [...vm.$mcf.countries];
        return countries;
      }
    },
    beforeCreate() {
      let vm = this;

      vm.$services.Course.list().then(response => {
        // vm.courses = response.data.data.courses;
        vm.isLoading = false;

        if (0 < response.data.data.data.length) {

          let courses = response.data.data.data.map(this.courseItemObject);

          courses.sort(function (a, b) {
            return a.order - b.order
          });

          vm.courses = courses;
          vm.coursesBackup = JSON.parse(JSON.stringify(courses));

          let currentCourseId = vm.$localStorage.get('current_course_id');
          let currentCourseIdx = 0;

          if (currentCourseId === null) {
            currentCourseIdx = 0;
          } else {
            currentCourseIdx = vm.getIdxCourse(currentCourseId);
          }

          vm.setCurrentCourse(vm.courses[currentCourseIdx]);
          vm.tempCourse = JSON.parse(JSON.stringify(vm.currentCourse));
          vm.$localStorage.set('current_course_id', vm.currentCourse.id);
          vm.isLoading = false;

        }

        vm.isLoadCourses = true;
        vm.init()
      })
    },
    watch: {
      isDragging(newValue) {
        if (newValue) {
          this.delayedDragging = true;
        }
        this.$nextTick(() => {
          this.delayedDragging = false
        })
      },
      isSave(newValue) {

        let vm = this;

        if (newValue) {
          if (vm.currentCourse.view_status !== STATUS.new) {
            vm.currentCourse.view_status = STATUS.update;
          }
        }
      },
      currentCourse (newValue, oldValue) {
        const vm = this;
        if (!(newValue && oldValue)) {
          return;
        }

        const tempSections = JSON.parse(JSON.stringify(vm.tempCourse.sections)).map(s => {
          s.lessons.forEach((l) => { delete l.isActive; delete l.view_status; });
          delete s.isActive;
          delete s.view_status;
          return s;
        });
        const currentSections = JSON.parse(JSON.stringify(vm.currentCourse.sections)).map(s => {
          s.lessons.forEach((l) => { delete l.isActive; delete l.view_status; });
          delete s.isActive;
          delete s.view_status;
          return s;
        });

        const isSectionNote = (
          JSON.stringify(tempSections) !== JSON.stringify(currentSections)
        );

        if (!isSectionNote) {
          vm.currentCourse.sections.forEach((s) => {
            s.view_status = STATUS.origin;
            s.lessons.forEach((l) => { l.view_status = STATUS.origin; });
          });
        }

        vm.courseStatus = {
          ...vm.courseStatus,
          isNote: (
            JSON.stringify(newValue.notes) !== JSON.stringify(vm.tempCourse.notes)
          ),
          isSectionNote,
          isRestriction: (
            newValue.location_availabilities.length !== vm.tempCourse.location_availabilities.length ||
            JSON.stringify(newValue.location_availabilities) !== JSON.stringify(vm.tempCourse.location_availabilities)
          )
        };
      },
    }
  }
</script>
