import moment from 'moment'

export default {

  genUrl (url, data) {
    Object.keys(data).forEach(function (key, index) {
      url = url.replace('{' + key + '}', data[key])
    })

    return url
  },
  //====================================================================================================================
  //=====:     Utility
  //====================================================================================================================

  /**
   * Check Text is URL
   * @param str
   * @returns {boolean}
   */
  isURL (str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
    return pattern.test(str)
  },

  /**
   * Simple log
   */
  log (msg, type) {
    if (type) {
      msg = '--' + type + ': ' + msg
    } else {
      msg = '--' + msg
    }

    console.log(msg)
  },

  /**
   * Generate random uid
   * @returns {string}
   */
  guid () {
    function s4 () {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + s4() + s4()
  },

  /**
   *  ParseErrorMsg from api
   * @param error
   * @param defaultMsg
   * @returns {string}
   */
  parseErrorMsg (error, defaultMsg) {
    var msg = defaultMsg ? defaultMsg : ''

    if (error.response && error.response.data && error.response.data.data) {
      if (error.response.data.data.error && error.response.data.data.error.message) {
        msg = error.response.data.data.error.message
      } else if (error.response.data.data.errors && error.response.data.data.errors.length && error.response.data.data.errors[0] && error.response.data.data.errors[0].message) {
        msg = error.response.data.data.errors[0].message
      }
    }

    return msg
  },

  getCourseName (course) {
    if (course) {
      if (course.name) {
        return course.name
      }
    }
    return ''
  },
  //***---------------------------------------------------------------------------------------------------------------
  //-----     Vimeo
  //-------------------------------------------------------
  getVideoLinkById (videoId) {
    var url = 'https://player.vimeo.com/video/' + videoId

    if (this.isURL(videoId)) {
      var regExp = /http(s)?:\/\/(www\.)?vimeo.com\/(\d+)(\/)?(#.*)?/
      var match = videoId.match(regExp)

      if (match && match[3]) {
        url = 'https://player.vimeo.com/video/' + match[3]
      } else {
        url = videoId
      }
    }

    return url
  },

  getVideoFrameById (videoId) {
    return '<iframe src="' + this.getVideoLinkById(videoId) + '" width="640" height="360" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>'
  },

  getVideoId (str) {
    var videoId = str

    if (this.isURL(videoId)) {
      var regExp = /http(s)?:\/\/(www\.)?vimeo.com\/(\d+)(\/)?(#.*)?/
      var match = videoId.match(regExp)

      if (match && match[3]) {
        videoId = match[3]
      }
    }

    return videoId
  },

  isEmail (value) {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
  },

  isNullOrUndefined (value) {
    return value === undefined || value === null;
  }
}
