<template>
  <svg width="24px" height="22px" viewBox="0 0 24 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 46.2 (44496) - http://www.bohemiancoding.com/sketch -->
    <title>Group</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="CourseDetail_04A" transform="translate(-22.000000, -144.000000)" fill="#FFFFFF">
        <g id="Group" transform="translate(22.000000, 144.000000)">
          <path d="M8.5,4.9932 L23,4.9932 C23.552,4.9932 24,4.5462 24,3.9932 C24,3.4412 23.552,2.9932 23,2.9932 L8.5,2.9932 C7.947,2.9932 7.5,3.4412 7.5,3.9932 C7.5,4.5462 7.947,4.9932 8.5,4.9932" id="Fill-1171"></path>
          <path d="M23,10.9932 L8.5,10.9932 C7.947,10.9932 7.5,11.4412 7.5,11.9932 C7.5,12.5462 7.947,12.9932 8.5,12.9932 L23,12.9932 C23.552,12.9932 24,12.5462 24,11.9932 C24,11.4412 23.552,10.9932 23,10.9932" id="Fill-1172"></path>
          <path d="M23,18.9932 L8.5,18.9932 C7.947,18.9932 7.5,19.4412 7.5,19.9932 C7.5,20.5462 7.947,20.9932 8.5,20.9932 L23,20.9932 C23.552,20.9932 24,20.5462 24,19.9932 C24,19.4412 23.552,18.9932 23,18.9932" id="Fill-1173"></path>
          <path d="M4.5,0.9932 L0.5,0.9932 C0.224,0.9932 0,1.2172 0,1.4932 L0,5.4932 C0,5.7692 0.224,5.9932 0.5,5.9932 L4.5,5.9932 C4.776,5.9932 5,5.7692 5,5.4932 L5,1.4932 C5,1.2172 4.776,0.9932 4.5,0.9932" id="Fill-1174"></path>
          <path d="M4.5,8.9932 L0.5,8.9932 C0.224,8.9932 0,9.2172 0,9.4932 L0,13.4932 C0,13.7692 0.224,13.9932 0.5,13.9932 L4.5,13.9932 C4.776,13.9932 5,13.7692 5,13.4932 L5,9.4932 C5,9.2172 4.776,8.9932 4.5,8.9932" id="Fill-1175"></path>
          <path d="M4.5,16.9932 L0.5,16.9932 C0.224,16.9932 0,17.2172 0,17.4932 L0,21.4932 C0,21.7692 0.224,21.9932 0.5,21.9932 L4.5,21.9932 C4.776,21.9932 5,21.7692 5,21.4932 L5,17.4932 C5,17.2172 4.776,16.9932 4.5,16.9932" id="Fill-1176"></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'IconList'
  }
</script>
