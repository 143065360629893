<template>
    <div>
        <div class="moderation-component user-list">
            <div class="head clearfix">
                <div class="pull-right">
                    <div class="select-studio">
                        <!--<button v-if="$route.query.studio" class="btn btn-link clear-filter" @click="clearFilter()">Clear filter</button>-->
                        <select2
                            ref="select2"
                            v-bind:options="options"
                            v-bind:ajax="ajax"
                            v-on:change="onSelectStudio($event)"
                            placeholder="Filter by studio"
                        >
                        </select2>
                    </div>
                </div>
                <div class="pull-right">
                    <div class="select-studio owner">
                        <select2
                            ref="selectOwner"
                            v-bind:options="ownerOptions"
                            v-bind:ajax="ajaxOwner"
                            v-on:change="onSelectOwner($event)"
                            placeholder="Filter by owner"
                        >
                        </select2>
                    </div>
                </div>
                <div class="pull-right">
                  <div class="select-studio role">
                    <select2
                      ref="selectRole"
                      v-bind:options="options"
                      v-bind:ajax="ajaxRoles"
                      v-on:change="onSelectRole($event)"
                      placeholder="Filter by Role">
                    </select2>
                  </div>
                </div>
                <div class="pull-right incomplete-checkbox">
                  <label class="myCheckbox">
                    <div class="incomplete-checkbox__txt">Show Incomplete</div>

                    <input type="checkbox" name="show_incomplete"
                           v-model="isShowIncomplete"
                           v-on:change="onChangeIsShowIncomplete"
                           />
                    <span></span>
                  </label>
                </div>
                <div class="pull-right incomplete-checkbox">
                  <label class="myCheckbox">
                    <div class="incomplete-checkbox__txt">Induction Course Completion</div>

                    <input type="checkbox" name="show_incomplete"
                           v-model="isShowInductionCourse"
                           v-on:change="onChangeIsShowInductionCourse"
                    />
                    <span></span>
                  </label>
                </div>
                <div class="pull-right">
                  <button class="btn btn-link clear-filter" @click="clearFilter()">Clear filter</button>
                </div>
                <div class="pull-left">Users</div>
            </div>
            <div class="loading-div" v-show="isLoading" style="opacity: 0.8">
                <icon-loading></icon-loading>
            </div>
            <div class="moderation-component-body" v-if="isReady">
                <div class="moderation-item panel">
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-xs-12">
                                <div class="row">
                                    <div class="col-xs-5">
                                        <form class="search-form" @submit="onSearchSubmit($event)">
                                            <div class="input-group">
                                              <textarea v-model="searchText" class="form-control" placeholder="Search by name or email... (line break to multiple search)"></textarea>
                                                <span class="input-group-btn">
                                                    <button class="btn btn-default" type="submit">Search</button>
                                                </span>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <table cellspacing="2px" class="table tbl-user-list table-responsive" ref="userTable">
                                    <thead>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Type</th>
                                        <th>Studio</th>
                                        <th>Country</th>
                                        <th>Certifications</th>
                                        <th>Course Complete</th>
                                        <th v-if="isShowInductionCourse">Induction Course Completion</th>
                                        <th style="min-width: 110px">Show Entrepreneur</th>
                                        <th style="min-width: 110px">Show Military Entrepreneur</th>
                                        <th style="min-width: 110px">Show Online Induction</th>
                                        <th>Show Prodigy</th>
                                        <th v-if="studioCode">Active</th>
                                        <th></th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(user, idx) in users" v-bind:key="idx">
                                            <td>
                                              <div class="row">
                                                <div class="col-6" style="padding-left: 10px;">
                                                  <a href="" class="text-decoration-none" @click="openProfile($event, user)" title="Click to copy profile link">
                                                    <span>{{`${user.first_name} ${user.last_name}`}}</span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 16 16">
                                                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"></path>
                                                      <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"></path>
                                                    </svg>
                                                  </a>
                                                  <p v-bind:id="'user_'+user.id" style="height: 0; width: 0; overflow: hidden;">{{`${getAppUrl}/profile/public/${user.ref_uid}`}}</p>
                                                </div>
                                              </div>
                                            </td>
                                            <td>{{`${user.email}`}}</td>
                                            <td><span class="text-lowercase">{{getUserRoleName(user.role_id)}}</span></td>
                                            <td><span v-for="(studio, idx) in user.studios" v-bind:key="idx">{{ studio.studio_name }}<span v-if="idx !== user.studios.length - 1">, </span></span></td>
                                            <td>{{user.country}}</td>
                                            <td class="course-complete">
                                                <div>
                                                  <p class="course-complete-head">Mandatory</p>
                                                  <a href="#" v-for="(cert, idx) in certificationType" v-if="cert.isShow" v-bind:key="idx" class="mandatory-cert" v-bind:style="{ cursor: !user.certsHandling.mandatory[cert.key] ? 'auto' : 'pointer' }">
                                                    <span class="mandatory-name">{{cert.name}}
                                                      <span @click="downloadCertificate($event, user.certsHandling.mandatory[cert.key])" v-if="user.certsHandling.mandatory[cert.key]" class="mandatory-name-txt">({{user.certsHandling.mandatory[cert.key].name}})</span>
                                                    </span>
                                                    <span v-if="user.certsHandling.mandatory[cert.key]" style="margin-left: 5px">
                                                      <img v-if="user.certsHandling.mandatory[cert.key].status === certStates.approved" class="icon-certification" src="../../assets/icons/checkmark-green.svg" alt="" title="approved"/>
                                                      <span v-if="user.certsHandling.mandatory[cert.key].status === certStates.rejected || user.certsHandling.mandatory[cert.key].status === certStates.unmoderated">
                                                        <img v-if="!user.certsHandling.mandatory[cert.key].isExpired" class="icon-certification" src="../../assets/icons/checkmark-yellow.svg" alt="" title="not approved"/>
                                                        <img v-if="user.certsHandling.mandatory[cert.key].isExpired" class="icon-certification" src="../../assets/icons/expired.png" alt="" title="expired"/>
                                                      </span>
                                                    </span>

                                                    <img v-if="!user.certsHandling.mandatory[cert.key]" class="icon-certification" src="../../assets/icons/checkmark-red.png" alt="" title="not uploaded"/>
                                                  </a>
                                                </div>
                                                <div class="course-complete__show-more">
                                                  <p class="course-complete-head course-complete-other">Other ({{user.certsHandling.other.length}})</p>
                                                  <p class="show-more" v-bind:style="{visibility: user.certsHandling.other.length ? 'visible' : 'hidden'}" @click="handleOpenCertificationsModal(user)">Show more</p>
                                                </div>
                                            </td>
                                            <td class="course-complete">
                                                <div>
                                                  <p class="course-complete-head">Mandatory <span>({{user.coursesHandling.mandatoryCourses.length}})</span></p>
                                                  <a href="" @click="downloadCert($event, mandatoryCourse)" v-for="(mandatoryCourse, idx) in user.coursesHandling.mandatoryCourses" v-bind:key="idx" style="display: flex;justify-content: space-between" v-bind:class="{ inactiveMandatory: !mandatoryCourse.certificate_path }" v-if="mandatoryCourse.isShow">
                                                    <span>{{mandatoryCourse.name}}</span>
                                                    <img v-if="mandatoryCourse.progress === 100" class="icon-certification" src="../../assets/icons/checkmark-green.svg" alt="" title="completed"/>
                                                    <img v-if="mandatoryCourse.progress === 0" class="icon-certification" src="../../assets/icons/checkmark-red.png" alt="" title="not started"/>
                                                    <img v-if="mandatoryCourse.progress > 0 && mandatoryCourse.progress < 100" class="icon-certification" src="../../assets/icons/checkmark-yellow.svg" title="in-progress"/>
                                                  </a>
                                                  <div class="course-complete__show-mandatory" v-if="user.coursesHandling.mandatoryCourses.length > NUMBER_OF_COURSES_WILL_SHOW">
                                                    <p class="" @click="loadMoreMandatoryCourse(user.coursesHandling)">{{user.coursesHandling.isShowAllMandatoryCourses ? 'Show Less' : 'Show All'}}</p>
                                                  </div>
                                                </div>

                                                <div class="course-complete__show-more">
                                                  <p class="course-complete-head course-complete-other">Other ({{user.coursesHandling.otherCompleteCourses.length}})</p>
                                                  <p class="show-more" v-bind:style="{visibility: user.coursesHandling.otherCompleteCourses.length ? 'visible' : 'hidden'}" @click="handleOpenCourseCompleteModal(user)">Show more</p>
                                                </div>
                                            </td>
                                            <td class="course-complete" v-if="isShowInductionCourse">
                                              <div>
                                                <p class="course-complete-head">Mandatory <span>({{user.inductionCoursesHandling.mandatoryCourses.length}})</span></p>
                                                <a href="" @click="downloadCert($event, mandatoryCourse)" v-for="(mandatoryCourse, idx) in user.inductionCoursesHandling.mandatoryCourses" v-bind:key="idx" style="display: flex;justify-content: space-between" v-bind:class="{ inactiveMandatory: !mandatoryCourse.certificate_path }" v-if="mandatoryCourse.isShow">
                                                  <span>{{mandatoryCourse.name}}</span>
                                                  <img v-if="mandatoryCourse.progress === 100" class="icon-certification" src="../../assets/icons/checkmark-green.svg" alt="" title="completed"/>
                                                  <img v-if="mandatoryCourse.progress === 0" class="icon-certification" src="../../assets/icons/checkmark-red.png" alt="" title="not started"/>
                                                  <img v-if="mandatoryCourse.progress > 0 && mandatoryCourse.progress < 100" class="icon-certification" src="../../assets/icons/checkmark-yellow.svg" title="in-progress"/>
                                                </a>
                                                <div class="course-complete__show-mandatory" v-if="user.inductionCoursesHandling.mandatoryCourses.length > NUMBER_OF_COURSES_WILL_SHOW">
                                                  <p class="" @click="loadMoreMandatoryCourse(user.inductionCoursesHandling)">{{user.inductionCoursesHandling.isShowAllMandatoryCourses ? 'Show Less' : 'Show All'}}</p>
                                                </div>
                                              </div>
                                            </td>
                                            <td class="Trainer">
                                                <switch-box v-on:change="toggleShowEntrepreneur($event, idx)" v-bind:checked="user.show_entrepreneur" label="" checkedLabel=""></switch-box>
                                            </td>
                                            <td class="Trainer">
                                              <switch-box v-on:change="toggleShowMilitary($event, idx)" v-bind:checked="user.show_military" label="" checkedLabel=""></switch-box>
                                            </td>
                                            <td class="Trainer">
                                                <switch-box v-on:change="onShowOnlineInductionStaff($event, idx)" v-bind:checked="user.show_online_induction" label="" checkedLabel=""></switch-box>
                                            </td>
                                            <td class="Trainer">
                                                <switch-box v-on:change="onShowProdigy($event, idx)" v-bind:checked="user.show_prodigy" label="" checkedLabel=""></switch-box>
                                            </td>
                                            <td class="Trainer" v-if="studioCode">
                                                <switch-box v-on:change="onActiveStaff($event, idx)" v-bind:checked="user.user_trainer_status" label="" checkedLabel=""></switch-box>
                                            </td>
                                            <td class="actions">
                                                <b-dropdown id="dropdown-1" right>
                                                    <template slot="button-content">
                                                        <div class="content-center">
                                                            <span class="icon-draggable"></span>
                                                        </div>
                                                    </template>
                                                    <b-dropdown-item v-b-modal.courses-modal @click="showCourseModal(user)">
                                                        Complete courses
                                                    </b-dropdown-item>
                                                </b-dropdown>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12">
                                <pagination
                                        v-bind:active="!isLoading"
                                        v-bind:pagination="pagination"
                                        v-bind:onChangePage="onChangePage"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          <div style="overflow: hidden; height: 1px; width: 1px; opacity: 0;">
            <input type="text" id="temp_input" value="">
          </div>
            <b-modal id="courses-modal" title="Complete user's courses" v-on:hide="onCoursesModalHide()" v-on:show="onCoursesModal()" centered>
                <div v-if="!isLoadingCourses">
                    <ul class="course-list list-unstyled" v-if="courses.length > 0">
                        <li class="list-group-item" v-for="(course, idx) in courses" v-bind:key="idx">
                            <div>
                                <label class="myCheckbox un-float">
                                    <input type="checkbox"
                                           @change="onSelectCourse(course, $event)"
                                           v-bind:checked="course.selected"
                                           v-bind:disabled="isSaving"
                                    />
                                    <span></span>
                                    <div>{{course.name}}</div>
                                </label>
                            </div>
                        </li>
                    </ul>
                    <p v-if="courses.length === 0">
                        The user has no un-complete courses!
                    </p>
                </div>
                <template slot="modal-footer" slot-scope="{ ok, cancel, hide }">
                    <!--<b>Custom Footer</b>-->
                    <label class="myCheckbox" style="width: auto;">
                        <input type="checkbox"
                               @change="onSelectAllCourse($event)"
                               v-bind:checked="selectedAll"
                               v-bind:disabled="isSaving"
                        />
                        <span></span>
                        <div>Complete all</div>
                    </label>
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <!--<b-button size="sm" variant="success" @click="ok()">-->
                        <!--OK-->
                    <!--</b-button>-->
                    <b-button size="sm"
                              variant="danger"
                              @click="onCompleteUserCourses()"
                              v-bind:disabled="isSaving"
                    >
                        <span>OK</span>
                    </b-button>
                    <icon-loading v-show="isSaving || isLoadingCourses"></icon-loading>
                    <!-- Button with custom close trigger value -->
                    <!--<b-button size="sm" variant="outline-secondary" @click="hide('forget')">-->
                        <!--Forget it-->
                    <!--</b-button>-->
                </template>
            </b-modal>
            <modal class="modal-confirm" title="Other complete courses" v-if="currentUser" v-model="isShowModalCourseComplete" :footer="false">
              <i class="icon-close" @click="closeCourseCompleteModal"></i>
              <a href="" @click="downloadCert($event, courseComp)" v-for="(courseComp, idx) in currentUser.coursesHandling.otherCompleteCourses" v-bind:key="idx" style="display: block;text-decoration: underline">
                <span>{{courseComp.name}}</span>
              </a>
            </modal>
            <modal class="modal-confirm modal-other-cert" title="Other certifications" v-if="currentUser" v-model="isShowModalCertifications" :footer="false">
              <i class="icon-close" @click="closeCertificationsModal"></i>
              <a href="" @click="downloadCertificate($event, cert)" v-for="(cert, idx) in currentUser.certsHandling.other" v-bind:key="idx" class="cert-download">
                <span>{{cert.name}} {{cert.isExpired ? '(EXPIRED)' : ''}}</span>
                <span class="cert-download__expired"> </span>
              </a>
            </modal>
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import VideoPlay from '@/components/video/VideoPlay'
import IconLoading from '@/components/global/IconLoading'
import Pagination from '@/components/global/Pagination'
import ZigPlayer from '@/components/video/ZigPlayer'
import Select2 from '@/components/global/Select2'
import AppHelper from '@/helpers'
import SwitchBox from '@/components/global/SwitchBox';
import {rolesConstant} from '../../constants/constants';

// import { Dro } from 'bootstrap-vue'

export default {
  components: {IconLoading, Navbar, VideoPlay, ZigPlayer, Pagination, Select2, SwitchBox},
  name: 'UserList',
  data: function () {
    return {
      isLoading: true,
      users: [],
      pagination: {},
      isReady: false,
      isShowModalCourseComplete: false,
      isShowModalCertifications: false,
      isShowIncomplete: false,
      isShowInductionCourse: false,
      NUMBER_OF_COURSES_WILL_SHOW: 4,
      certStates: {
        unmoderated: 0,
        approved: 1,
        rejected: 2,
      },
      certificationType: {
        // main type
        aed: {
          key: 'aed',
          name: 'AED',
          value: 1,
          extraValue: [1],
          isShow: true
        },
        personalTraining: {
          key: 'personalTraining',
          name: 'Personal Training',
          value: 2,
          extraValue: [2],
          isShow: true
        },
        firstAid: {
          key: 'firstAid',
          name: 'First Aid',
          value: 3,
          extraValue: [3],
          isShow: true
        },
        cpr: {
          key: 'cpr',
          name: 'CPR',
          value: 4,
          extraValue: [4],
          isShow: true
        },

        // extra type
        cpr_aed: {
          key: 'cpr_aed',
          name: 'CPR/AED',
          value: 5,
          extraValue: [1, 4],
          isShow: false
        },
        cpt_cpr: {
          key: 'cpt_cpr',
          name: 'CPT/CPR',
          value: 6,
          extraValue: [2, 4],
          isShow: false
        },
        firstAid_cpr_aed: {
          key: 'firstAid_cpr_aed',
          name: 'First Aid/CPR/AED',
          value: 7,
          extraValue: [1, 3, 4],
          isShow: false
        },
        cpt_firstAid_cpr_aed: {
          key: 'cpt_firstAid_cpr_aed',
          name: 'CPT/First Aid/CPR/AED',
          value: 8,
          extraValue: [1, 2, 3, 4],
          isShow: false
        },
      },
      options: [
        { id: 1, text: 'Hello' },
        { id: 2, text: 'World' }
      ],
      ownerOptions: [],
      studio: null,
      currentUser: null,
      courses: [],
      selectedAll: false,
      isSaving: false,
      isLoadingCourses: false,
      searchText: null,
      studios: [],
      studioCode: undefined,
      roleCode: undefined,
      owner: undefined
    }
  },
  methods: {
    async loadUsers (page = 1, studio = undefined, role_id = undefined, searchText = undefined, owner = undefined, email = undefined, params = {}) {
      if (!page) page = 1;

      if (!searchText) {
        searchText = this.searchText ? this.searchText : undefined;
      }
      searchText = searchText && searchText.replace(/\n+/g, ','); // replace break line
      searchText = searchText && searchText.replace(/^,|,$/g, ''); // replace first and last char if is comma
      this.showLoading(true)
      if (this.isShowIncomplete) {
        params.incomplete = true;
      }
      params.with = 'studios,certifications';

      const res = await this.$services.User.list({ page, studio: studio, role: role_id, search: searchText, owner, ...params});
      const users = res.data.items;

      // case filter by studio
      if (studio) {
        users.forEach(user => {
          // at least 1 studio has status true
          user.user_trainer_status = !!user.studios.find(item => {
            return !!item.status;
          })
        })
      }
      this.handleCertifications(users);
      this.handleCourses(users);
      this.handleInductionCourses(users);
      this.users = users;

      this.pagination = {
        ...res.data,
        data: null
      }
      this.showLoading(false);
    },
    async searchUsers (page = 1, studioCode = undefined, searchText = undefined, email = undefined, params = {}) {
      if (!page) page = 1;

      if (searchText && AppHelper.isEmail(searchText)) {
        email = searchText;
        searchText = undefined;
      }
      const res = await this.$services.User.list({page, studio_code: studioCode, search: searchText, email, ...params});
      return res.data;
    },
    async searchStudio (search = null, page = 0, searchBy = undefined, params = {}) {
      const body = {page, search: searchBy, ...params};
      if (search) {
        body.query = search;
      }
      const res = await this.$services.Studio.list(body)
      return res.data.data;
    },
    async searchRole (search = null, searchBy = undefined, params = {}) {
      // const body = {search: searchBy, ...params};
      // if (search) {
      //   body.query = search;
      // }
      const res = await this.$services.User.getUserTypes()
      return res.data.data.roles;
    },
    async loadCourses (userId) {
      try {
        this.isLoadingCourses = true;
        const res = await this.$services.User.uncompleteUserCourses(userId);
        if (res) {
          this.courses = res.courses;
        }
      } finally {
        this.isLoadingCourses = false;
      }
    },
    showLoading (force) {
      this.isLoading = force;
    },
    onChangePage (pageNumber) {
      this.loadUsers(pageNumber, this.$route.query.studio, this.roleCode, undefined, this.$route.query.owner)
    },
    onSelectStudio (e) {
      this.$router.replace({ query: { ...this.$route.query, studio: e.params.data.code } });
    },
    onSelectOwner (e) {
      this.$router.replace({ query: { ...this.$route.query, owner: e.params.data.id } });
    },
    onSelectRole (e) {
      this.$router.replace({ query: { ...this.$route.query, role: e.params.data.code } });
    },
    onChangeIsShowIncomplete () {
      this.$router.replace({ query: { ...this.$route.query, incomplete: this.isShowIncomplete.toString() } });
    },
    onChangeIsShowInductionCourse (e) {
    },

    onCoursesModal () {
      this.loadCourses(this.currentUser.id);
    },
    onCoursesModalHide () {
      this.loadUsers(undefined, this.$route.query.studio, this.roleCode, this.searchText, this.$route.query.owner)
    },
    showCourseModal (user) {
      this.currentUser = user;
    },
    onSelectCourse (course) {
      course.selected = !course.selected;
    },
    onSelectAllCourse (e) {
      this.selectedAll = !this.selectedAll;
      this.courses = this.courses.map((course) => {
        course.selected = this.selectedAll;
        return course;
      });
    },
    getCertType (type) {
      return Object.values(this.certificationType).find(
        (certType) => certType.value === type
      )
    },
    handleCertifications (users) {
      users.forEach(user => {
        user.certsHandling = {
          mandatory: {},
          other: {}
        };

        // retrieve mandatory
        user.certifications.forEach(cert => {
          const certType = this.getCertType(cert.type);
          const expireDate = new Date(cert.expire_date);
          const now = Date.now();
          cert.isExpired = expireDate <= now;

          if (certType) {
            certType.extraValue.forEach(typeValue => {
              const type = this.getCertType(typeValue);
              if (user.certsHandling.mandatory[type.key]) {
                // cert has newer expired date
                if (expireDate >= new Date(user.certsHandling.mandatory[type.key].expire_date)) {
                  user.certsHandling.mandatory[type.key] = cert;
                }
              } else {
                user.certsHandling.mandatory[type.key] = cert;
              }
            })
          }
        })

        // retrieve other certificates
        user.certifications.forEach(cert => {
          Object.entries(user.certsHandling.mandatory).forEach(
            ([key, mandatoryCert]) => {
              if (cert.id === mandatoryCert.id) {
                cert.isMandatory = true;
              }
            })
        })
        user.certsHandling.other = user.certifications.filter(cert => !cert.isMandatory)
      })
    },
    handleCourses (users) {
      users.forEach(user => {
        user.coursesHandling = {
          mandatoryCourses: [],
          otherCompleteCourses: [],
          isShowAllMandatoryCourses: false
        };
        user.courses.forEach(course => {
          if (course.required) {
            if (user.coursesHandling.mandatoryCourses.length < this.NUMBER_OF_COURSES_WILL_SHOW) {
              course.isShow = true;
            }
            user.coursesHandling.mandatoryCourses.push(course);
          } else {
            if (course.progress === 100) {
              user.coursesHandling.otherCompleteCourses.push(course);
            }
          }
        })
      })
    },
    handleInductionCourses (users) {
      users.forEach(user => {
        user.inductionCoursesHandling = {
          mandatoryCourses: [],
          isShowAllMandatoryCourses: false
        };
        user.courses.forEach(course => {
          if (course.required_for_induction) {
            if (user.inductionCoursesHandling.mandatoryCourses.length < this.NUMBER_OF_COURSES_WILL_SHOW) {
              course.isShow = true;
            }
            user.inductionCoursesHandling.mandatoryCourses.push(course);
          }
        })
      })
    },
    closeCourseCompleteModal () {
      this.isShowModalCourseComplete = false;
    },
    handleOpenCourseCompleteModal (user) {
      this.currentUser = user;
      this.isShowModalCourseComplete = true;
    },
    closeCertificationsModal () {
      this.isShowModalCertifications = false;
    },
    handleOpenCertificationsModal (user) {
      this.currentUser = user;
      this.isShowModalCertifications = true;
    },
    loadMoreMandatoryCourse (coursesHandling) {
      coursesHandling.isShowAllMandatoryCourses = !coursesHandling.isShowAllMandatoryCourses;
      if (coursesHandling.isShowAllMandatoryCourses) {
        coursesHandling.mandatoryCourses.forEach(course => {
          course.isShow = true;
        })
      } else {
        coursesHandling.mandatoryCourses.forEach((course, i) => {
          course.isShow = i < this.NUMBER_OF_COURSES_WILL_SHOW;
        })
      }
    },
    async onCompleteUserCourses () {
      try {
        this.isSaving = true;
        const courseIds = this.courses.filter((c) => c.selected).map((c) => c.id);
        if (courseIds.length > 0) {
          const res = await this.$services.User.completeUserCourses(
            this.currentUser.id, courseIds
          );
          if (res) {
            // push new courses complete into user object
            if (res.user_courses.length > 0) {
              this.currentUser.coursesHandling.otherCompleteCourses = [
                ...this.currentUser.coursesHandling.otherCompleteCourses,
                ...res.user_courses
              ];
            }
            courseIds.forEach((id) => {
              const idx = this.courses.findIndex((c) => c.id === id);
              if (idx !== -1) {
                this.courses.splice(idx, 1);
              }
            });
          }
        }
      } finally {
        this.isSaving = false;
      }
    },
    closeModalAndReset () {
      this.$bvModal.hide('courses-modal');
      this.selectedAll = false;
      this.courses = [];
    },
    downloadCert (event, course) {
      event.preventDefault();
      event.stopPropagation();
      if (!course.certificate_path) return;
      window.open(course.certificate_path, '_blank');
    },
    downloadCertificate (event, cert) {
      event.preventDefault();
      window.open(cert.file_path, '_blank');
    },
    clearFilter () {
      const query = {...this.$route.query};
      if (query['studio']) {
        delete query['studio'];
      }
      if (query['role']) {
        delete query['role'];
      }
      if (query['owner']) {
        delete query['owner'];
      }
      if (query['incomplete']) {
        delete query['incomplete'];
      }
      this.studioCode = undefined;
      this.roleCode = undefined;
      this.owner = undefined;
      this.isShowInductionCourse = false;
      this.isShowIncomplete = false;
      this.$refs.select2.clear();
      this.$refs.selectOwner.clear();
      this.$refs.selectRole.clear();
      this.$router.replace({ query });
    },
    openProfile (event, user) {
      event.preventDefault();

      document.getElementById("temp_input").value = $('#user_'+user.id).text();
      // // console.log(document.getElementById("temp_input").value)
      var copyText = document.getElementById("temp_input");
      copyText.select();
      document.execCommand("Copy");

      this.$toasted.success('Copied profile link')

    },
    onSearchSubmit (event) {
      event.preventDefault();
      // if (this.searchText) {
      this.loadUsers(undefined, this.$route.query.studio, this.roleCode, undefined, this.$route.query.owner)
      // }
    },
    onActiveStaff: function (event, index) {
      this.users[index].user_trainer_status = event;
      const staff = this.users[index];
      if (event) {
          const res = this.$services.User.activeStaff(staff.id, event)
      } else {
          const res = this.$services.User.deactiveStaff(staff.id, event)
      }
    },
    onShowProdigy: function (event, index) {
      this.users[index].show_prodigy = event;
      this.$services.User.showProgdigy(this.users[index].id, event)
    },
    onShowOnlineInductionStaff: function (event, index) {
      this.users[index].show_online_induction = event;
      this.$services.User.showOnlineInduction(this.users[index].id, event)
    },
    toggleShowEntrepreneur: function (event, index) {
      this.users[index].show_entrepreneur = event;
      this.$services.User.toggleShowEntrepreneur(this.users[index].id, event)
    },
    toggleShowMilitary: function (event, index) {
      this.users[index].show_military = event;
      this.$services.User.toggleShowMilitary(this.users[index].id, event)
    },
    getUserRoleName (roleId) {
      for (var key of Object.keys(rolesConstant)) {
        if (rolesConstant[key].id === roleId) {
          return rolesConstant[key].name;
        }
      }
    }
  },
  watch: {
    '$route': {
      handler (newVal, oldVal) {
        if (newVal.query.studio !== oldVal.query.studio) {
            this.studioCode = newVal.query.studio;
          this.loadUsers(undefined, newVal.query.studio, newVal.query.role, undefined, newVal.query.owner)
        }
        if (newVal.query.role !== oldVal.query.role) {
          this.roleCode = newVal.query.role;
          this.loadUsers(undefined, newVal.query.studio, newVal.query.role, undefined, newVal.query.owner)
        }
        if (newVal.query.owner !== oldVal.query.owner) {
          this.owner = newVal.query.owner;
          this.loadUsers(undefined, newVal.query.studio, newVal.query.role, undefined, newVal.query.owner)
        }
        if (newVal.query.incomplete !== oldVal.query.incomplete) {
          this.loadUsers(undefined, newVal.query.studio, newVal.query.role, undefined, newVal.query.owner)
        }
      },
      deep: true
    },
  },
  computed: {
    getAppUrl: function (){
      return this.$mcf.appUrl
    }
  },
  beforeCreate () {},
  created () {},
  async beforeMount () {

    this.ajax = {
      delay: 1000,
      transport: async (params, success, failure) => {
        // if (params.data.q) {
          try {
            const res = await this.searchStudio(params.data.q, params.data.page, !!params.data.q ? 'NAME' : undefined);
            success(res)
          } catch (e) {
            failure(e)
          }
        // } else {
        //   success({studios: []});
        // }
      },
      processResults: (data) => {
        const results = data.studios.map((item) => ({
          id: item.id,
          code: item.code,
          text: item.name,
        }));
        // this.options = results;
        return {
          results,
          pagination: {
            more: data.page < (data.total_pages - 1)
          }
        }
      },
      cache: true
    }

    this.ajaxOwner = {
      delay: 1000,
      transport: async (params, success, failure) => {
        // if (params.data.q) {
        // console.log('Owner: ', params);
        try {
          const res = await this.searchUsers(params.data.page, undefined, params.data.q, undefined, {
            role: 1,
            with: 'studios,certifications'
          });
          success(res)
        } catch (e) {
          // console.log(e)
          failure(e)
        }
        // } else {
        //   success({studios: []});
        // }
      },
      processResults: (data) => {
        const results = data.items.map((item) => ({
          id: item.id,
          email: item.email,
          text: item.first_name + ' ' + item.last_name,
        }));
        // this.options = results;
        return {
          results,
          pagination: {
            more: data.current_page < data.last_page
          }
        }
      },
      cache: true
    }

    this.ajaxRoles = {
      delay: 1000,
      transport: async (params, success, failure) => {
        // if (params.data.q) {
        // console.log('Role: ', params);
        try {
          const res = await this.searchRole(params.data.page, undefined, params.data.q, undefined, {});
          success(res)
        } catch (e) {
          // console.log(e)
          failure(e)
        }
      },
      processResults: (data) => {
        const results = data.map((item) => ({
          id: item.id,
          code: item.id,
          text: item.name,
        }));
        return {
          results,
          pagination: {
            more: data.current_page < data.last_page
          }
        }
      },
      cache: true
    }
  },
  async mounted () {
    this.clearFilter();
    await this.loadUsers();
    this.isReady = true;
  }
}
</script>
