import AppHelper from '@/helpers'

export class StudioService {
  constructor (Vue) {
    this.Vue = Vue
  }

  get (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http

    var promise = $http.get(AppHelper.genUrl(vue.$mcf.api.section.get, {id: data.id}), {
      params: data
    })

    return promise
  }

  list (params) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.get(vue.$mcf.api.studio.list, {
      params,
      headers: {
        'X-Authorization': vue.$mcf.academyApiKey
      }
    })

    return promise
  }
}
