<template>
    <div>
        <Navbar></Navbar>
        <CertificateList/>
    </div>
</template>

<script>
import Navbar from '@/components/Navbar';
import CertificateList from '@/components/certificates/CertificateList';
export default {
  components: {Navbar, CertificateList},
  name: 'Users',
  data: function () {
    return {}
  },
  methods: {
  }
}
</script>
