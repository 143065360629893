/**
 * Main config for App:
 *  - api url
 *  - development mode
 *  - ...
 *
 * @type {}
 */
let mainConfig;
try {
  switch (process.env.CONFIG_ENV) {
    case 'F45_STAGING': mainConfig = require('../environments/f45-staging.env'); break;
    case 'F45': mainConfig = require('../environments/f45.env'); break;
    case 'FS8': mainConfig = require('../environments/fs8.env'); break;
    default: mainConfig = require('../environments/f45-staging.env');
  }
} catch (e) {
  mainConfig = require('../environments/f45-staging.env');
}

if (!mainConfig.isDevelopmentMode) {
  mainConfig.baseApiUrl = mainConfig.live.baseApiUrl;
  mainConfig.fireBase = mainConfig.live.fireBase;
}

mainConfig.api = {
  config: mainConfig.baseApiUrl + '/config',
  auth: {
    login: mainConfig.baseApiUrl + '/auth/admin_login',
    refreshToken: mainConfig.baseApiUrl + '/auth/refresh_token',
    cloudFlareToken: mainConfig.baseApiUrl + '/auth/cf_token'
  },
  user: {
    // authenticate: 'http://api.f45-academy.project-staging.com/oauth/access_token',
    create: mainConfig.baseApiUrl + '/users',
    update: mainConfig.baseApiUrl + '/admin/users/{id}',
    get: mainConfig.baseApiUrl + '/users/{id}',
    list: mainConfig.baseApiUrl + '/admin/users',
    profile: mainConfig.baseApiUrl + '/users/profile/{id}',
    getCurrentUser: mainConfig.baseApiUrl + '/users/current',
      activeStaff: mainConfig.baseApiUrl + '/admin/users/status/{id}',
      deactiveStaff: mainConfig.baseApiUrl + '/admin/users/status/{id}',
    forgot: mainConfig.baseApiUrl + '/users/forgot',
    reset: mainConfig.baseApiUrl + '/users/reset_password',
    changePassword: mainConfig.baseApiUrl + '/users/{id}/password',
    export: mainConfig.baseApiUrl + '/admin/users/export',
    types: mainConfig.baseApiUrl + '/user/roles',

    interview: {
      get: mainConfig.baseApiUrl + '/users/{user_id}/interview/{id}',
      update: mainConfig.baseApiUrl + '/users/{user_id}/interview/{id}',
      create: mainConfig.baseApiUrl + '/users/{user_id}/interview',
      list: mainConfig.baseApiUrl + '/users/{user_id}/interview',
      delete: mainConfig.baseApiUrl + '/users/{user_id}/interview/{id}'
    },

    fit_test: {
      get: mainConfig.baseApiUrl + '/users/{user_id}/fittest/{id}',
      update: mainConfig.baseApiUrl + '/users/{user_id}/fittest/{id}',
      create: mainConfig.baseApiUrl + '/users/{user_id}/fittest',
      list: mainConfig.baseApiUrl + '/users/{user_id}/fittest',
      delete: mainConfig.baseApiUrl + '/users/{user_id}/fittest/{id}'
    },

    user_fit_test: {
      get: mainConfig.baseApiUrl + '/admin/user-fit-tests',
      update: mainConfig.baseApiUrl + '/admin/user-fit-tests/approve/{user_fit_test_id}',
      // create: mainConfig.baseApiUrl + '/users/{user_id}/fittest',
      // list: mainConfig.baseApiUrl + '/users/{user_id}/fittest',
      // delete: mainConfig.baseApiUrl + '/users/{user_id}/fittest/{id}'
    },
    course: {
      create: mainConfig.baseApiUrl + '/users/{user_id}/courses',
      update: mainConfig.baseApiUrl + '/users/{user_id}/courses/{id}',
      get: mainConfig.baseApiUrl + '/users/{user_id}/courses/{id}',
      list: mainConfig.baseApiUrl + '/users/{user_id}/courses',
      info: mainConfig.baseApiUrl + '/users/{user_id}/courses/info',
      complete: mainConfig.baseApiUrl + '/admin/user-courses/complete/{user_id}',
      get_uncomplete: mainConfig.baseApiUrl + '/admin/user-courses/incomplete/{user_id}',
    },
    course_lesson: {

    },
    firebase: {
      create: mainConfig.baseApiUrl + '/users/firebase/accounts',
    },
  },

  course: {
    create: mainConfig.baseApiUrl + '/admin/courses',
    update: mainConfig.baseApiUrl + '/admin/courses/{id}',
    delete: mainConfig.baseApiUrl + '/admin/courses/{id}',
    duplicate: mainConfig.baseApiUrl + '/admin/courses/clone/{id}',
    get: mainConfig.baseApiUrl + '/courses/{id}',
    list: mainConfig.baseApiUrl + '/admin/courses',
    categories: mainConfig.baseApiUrl + '/admin/course-categories',
    order: mainConfig.baseApiUrl + '/admin/courses/order',
    // simpleList: mainConfig.baseApiUrl + '/courses/simple-list',
  },

  section: {
    create: mainConfig.baseApiUrl + '/admin/sections',
    update: mainConfig.baseApiUrl + '/admin/sections/{id}',
    delete: mainConfig.baseApiUrl + '/admin/sections/{id}',
    get: mainConfig.baseApiUrl + '/sections/{id}',
    list: mainConfig.baseApiUrl + '/sections',
  },

  lesson: {
    create: mainConfig.baseApiUrl + '/admin/lessons',
    update: mainConfig.baseApiUrl + '/admin/lessons/{id}',
    delete: mainConfig.baseApiUrl + '/admin/lessons/{id}',
    get: mainConfig.baseApiUrl + '/lessons/{id}',
    list: mainConfig.baseApiUrl + '/lessons',
  },

  question: {
    create: mainConfig.baseApiUrl + '/admin/questions',
    update: mainConfig.baseApiUrl + '/admin/questions/{id}',
    delete: mainConfig.baseApiUrl + '/admin/questions/{id}',
    get: mainConfig.baseApiUrl + '/questions/{id}',
    list: mainConfig.baseApiUrl + '/questions',
    check: mainConfig.baseApiUrl + '/questions/{id}/check',
    verify: mainConfig.baseApiUrl + '/questions/verify'
  },

  answer: {
    create: mainConfig.baseApiUrl + '/admin/answers',
    update: mainConfig.baseApiUrl + '/admin/answers/{id}',
    delete: mainConfig.baseApiUrl + '/admin/answers/{id}',
    get: mainConfig.baseApiUrl + '/answers/{id}',
    list: mainConfig.baseApiUrl + '/answers',
  },

  interview: {
    get: mainConfig.baseApiUrl + '/interview/{id}',
    list: mainConfig.baseApiUrl + '/interview',
  },

  fittest: {
    get: mainConfig.baseApiUrl + '/fittest/{id}',
    list: mainConfig.baseApiUrl + '/fittest',
  },

  file: {
    upload: mainConfig.baseApiUrl + '/files',
    uploadThumb: mainConfig.baseApiUrl + '/files?resize=small',
    uploadFeatured: mainConfig.baseApiUrl + '/files?resize=large',
  },
  video: {
    get: mainConfig.baseApiUrl + '/videos/{id}',
    create: mainConfig.baseApiUrl + '/videos',
    update: mainConfig.baseApiUrl + '/videos/{id}',
    list: mainConfig.baseApiUrl + '/videos'
  },
  playlist: {
    get: mainConfig.baseApiUrl + '/playlists/{id}',
    create: mainConfig.baseApiUrl + '/playlists',
    update: mainConfig.baseApiUrl + '/playlists/{id}',
    list: mainConfig.baseApiUrl + '/playlists'
  },
  studio: {
    get: mainConfig.studioApiUrl + '/studios/{id}',
    create: mainConfig.studioApiUrl + '/studios',
    update: mainConfig.studioApiUrl + '/studios/{id}',
    list: mainConfig.studioApiUrl + '/studios'
  },
  certificate: {
    get: mainConfig.baseApiUrl + '/certificates/{id}',
    create: mainConfig.baseApiUrl + '/certificates',
    update: mainConfig.baseApiUrl + '/admin/certificates/{id}',
    list: mainConfig.baseApiUrl + '/certificates',
    downloadExpiringSoon: mainConfig.baseApiUrl + '/admin/certificates/expiring'
  }
};

export default function (Vue) {
  window.mcf = Vue.mcf = mainConfig;

  Object.defineProperties(Vue.prototype, {
    $mcf: {
      get: function () {
        return mainConfig;
      }
    },
  })
}
