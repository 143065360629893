import AppHelper from '@/helpers'

export class QuestionService {
  constructor (Vue) {
    this.Vue = Vue
  }

  get (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http

    var promise = $http.get(AppHelper.genUrl(vue.$mcf.api.question.get, {id: data.id}), {
      params: data
    })

    return promise
  }

  list (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.get(vue.$mcf.api.question.list, {
      params: data
    })

    return promise
  }

  create (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.post(vue.$mcf.api.question.create, data)

    return promise
  }

  update (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.patch(AppHelper.genUrl(vue.$mcf.api.question.update, {id: data.id}), data)

    return promise
  }

  delete (data) {
    let sv = this;
    let vue = sv.Vue;
    let $http = sv.Vue.$http;
    let promise = $http.delete(AppHelper.genUrl(vue.$mcf.api.question.delete, {id: data.id}), data);
    return promise
  }

  check (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.post(AppHelper.genUrl(vue.$mcf.api.question.check, {id: data.id}), data)

    return promise
  }

  verify (data) {
    var sv = this
    var vue = sv.Vue
    var $http = sv.Vue.$http
    var promise = $http.post(vue.$mcf.api.question.verify, data)

    return promise
  }
}
