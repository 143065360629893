<template>
  <div class="frontend"></div>
</template>

<script>
  export default {
    name: 'Logout',
    data () {
      return {}
    },

    beforeCreate () {
      let vm = this;

      vm.$auth.logout()
      // remove old data from state
      // vm.$store.commit('updateCurrentUser', null);
      vm.$router.push({name: 'login'});

    }
  }
</script>
