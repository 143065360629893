export const rolesConstant = {
  INVESTOR_OWNER: {
    id: 1,
    name: 'INVESTOR OWNER',
  },
  HEAD_TRAINER: {
    id: 4,
    name: 'HEAD TRAINER',
  },
  MEMBERSHIP_MANAGER: {
    id: 5,
    name: 'MEMBERSHIP MANAGER',
  },
  TRAINER: {
    id: 2,
    name: 'TRAINER',
  },
  MODERATOR: {
    id: 3,
    name: 'MODERATOR',
  },
  STUDIO_MANAGER: {
    id: 6,
    name: 'STUDIO MANAGER',
  },
  ENTREPRENEUR_STUDIO_MANAGER: {
    id: 7,
    name: 'ENTREPRENEUR STUDIO MANAGER',
  },
  MILITARY_ENTREPRENEUR: {
    id: 8,
    name: 'MILITARY ENTREPRENEUR',
  },
  MILITARY_TRAINER: {
    id: 9,
    name: 'MILITARY TRAINER',
  },
  AFTER_PROGRAM: {
    id: 10,
    name: 'AFTER PROGRAM',
  },
  COLLEGE_FITNESS_MANAGER: {
    id: 11,
    name: 'COLLEGE FITNESS MANAGER',
  },
  COLLEGE_TRAINER: {
    id: 12,
    name: 'COLLEGE TRAINER',
  },
  PROSPECTIVE_COACH: {
    id: 8,
    name: 'PROSPECTIVE COACH',
  },
};

export const REFRESH_TOKEN_EXPIRED_TIME = 10 * 60 * 60 * 1000; // 10 hours
