import AppHelper from '@/helpers'

export class CertificateService {
  constructor (Vue) {
    this.Vue = Vue
  }

  get (id, params = {}) {
    let sv = this
    let vue = sv.Vue
    let $http = sv.Vue.$http

    return $http.get(vue.$mcf.api.certificate.get, { id }, {
      params: params
    })
  }

  list (params = {}) {
    let sv = this
    let vue = sv.Vue
    let $http = sv.Vue.$http

    return $http.get(vue.$mcf.api.certificate.list, {
      params
    }).then((res) => { return res.data; });
  }

  update (id, body) {
    let sv = this
    let vue = sv.Vue
    let $http = sv.Vue.$http
    return $http.patch(AppHelper.genUrl(vue.$mcf.api.certificate.update, { id }), body).then((res) => { return res.data });
  }

  reject (id, note) {
    let vue = this.Vue
    return this.update(id, {
      note: note,
      status: vue.$mcf.certificateStates.rejected,
    })
  }

  approve (id) {
    let vue = this.Vue
    return this.update(id, {
      status: vue.$mcf.certificateStates.approved,
    })
  }

  downloadExpiringSoon () {
    let sv = this
    let vue = sv.Vue
    let $http = sv.Vue.$http
    return $http.get(vue.$mcf.api.certificate.downloadExpiringSoon, {
      responseType: 'arraybuffer'
    })
  }
}
