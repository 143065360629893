import Vue from 'vue'
import Router from 'vue-router'
import NotFound from '@/components/exceptions/NotFound'

import AuthIndex from '@/components/auth/Index'
import Login from '@/components/auth/Login'
import Logout from '@/components/auth/Logout'
import Courses from '@/components/course/index'
import Game from '@/components/game/index'
import Users from '@/components/users/index'
import Certificates from '@/components/certificates/index'
import VideoPlay from '@/components/video/VideoPlay'
import Moderation from '@/components/moderation/Moderation'
import AppHelper from '@/helpers'

Vue.use(Router);

const router = new Router({
  mode: 'hash',
  routes: [

    {
      path: '*',
      name: '404',
      component: NotFound
    },
    {
      path: '/',
      name: 'home',
      component: Courses,
      beforeEnter: redirectIfNotLogin
    },

    {
      path: '/player/:vid',
      name: 'player',
      component: VideoPlay,
      beforeEnter: redirectIfNotLogin
    },
    {
      path: '/auth',
      name: 'auth',
      component: {
        template: '<router-view/>'
      },
      redirect: { name: 'login' },
      children: [
        {
          path: '',
          name: 'index',
          component: AuthIndex,
          children: [
            {
              path: '/login',
              name: 'login',
              component: Login,
              beforeEnter: skipIfAuthenticated
            },
            {
              path: '/logout',
              name: 'logout',
              component: Logout
            }
          ]
        }
      ]
    },
    {
        path: '/moderation',
        name: 'moderation',
        component: Moderation,
        beforeEnter: verifyPermission
    },
    // {
    //   path: '/game',
    //   name: 'game',
    //   component: Game,
    //   beforeEnter: verifyPermission
    // },
    {
      path: '/users',
      name: 'users',
      component: Users,
      beforeEnter: verifyPermission
    },
    {
      path: '/certificates',
      name: 'certificates',
      component: Certificates,
      beforeEnter: verifyPermission
    }
  ]
});

function redirectIfNotLogin (to, from, next) {

  let $auth = router.app && router.app.$auth;
  if ($auth) {
    let isAuthen = $auth.isAuthenticated()
    let isAdmin = $auth.isAuthenticatedAsAdmin()
    let isCoursesAdmin = $auth.isAuthenticatedAsCoursesAdmin()
    let isModerator = $auth.isAuthenticatedAsModerator()
    let isUsersAdmin = $auth.isAuthenticatedAsUsersAdmin()

    if(isAuthen){
      if (isMissingPermission()) {
        return router.push({name: 'logout'});
      }
      if (isCoursesAdmin || isAdmin || isModerator || isUsersAdmin) {
        switch (true) {
          case isCoursesAdmin: next(); break;
          case isModerator: router.push({name: 'moderation'}); break;
          case isUsersAdmin: router.push({name: 'users'}); break;
          case isAdmin: router.push({name: 'moderation'}); break;
        }
      } else {
        router.push({name: 'logout'})
      }
    }else{
      $auth.logout();
    }
  }
}

function skipIfAuthenticated (to, from, next) {
  let $auth = router.app && router.app.$auth;
  if (!$auth) return;

  let isAuthen = $auth.isAuthenticated()
  let isAdmin = $auth.isAuthenticatedAsAdmin()
  let isModerator = $auth.isAuthenticatedAsModerator()
  let isCoursesAdmin = $auth.isAuthenticatedAsCoursesAdmin()
  let isUsersAdmin = $auth.isAuthenticatedAsUsersAdmin()

  if (isCoursesAdmin || isAdmin || isModerator || isUsersAdmin) {
    switch (true) {
      case isCoursesAdmin: router.push({name: 'home'}); break;
      case isModerator: router.push({name: 'moderation'}); break;
      case isUsersAdmin: router.push({name: 'users'}); break;
      case isAdmin: router.push({name: 'moderation'}); break;
    }
  } else {
    next()
  }
}

function verifyPermission (to, from, next) {

  let $auth = router.app.$auth;

  let isAuthen = $auth.isAuthenticated()
  let isAdmin = $auth.isAuthenticatedAsAdmin()
  let isModerator = $auth.isAuthenticatedAsModerator()
  let isCoursesAdmin = $auth.isAuthenticatedAsCoursesAdmin()
  let isUsersAdmin = $auth.isAuthenticatedAsUsersAdmin()

  if(isAuthen){
    if (isMissingPermission()) {
      return router.push({name: 'logout'});
    }
    if (isCoursesAdmin || isAdmin || isModerator || isUsersAdmin) {
      next()
    } else {
      router.push({name: 'logout'})
    }
  }else{
    router.push({name: 'login'})
  }
}

function isMissingPermission () {
  let $auth = router.app && router.app.$auth;
  if (!$auth) return true;
  let isAdmin = $auth.isAuthenticatedAsAdmin()
  let isCoursesAdmin = $auth.isAuthenticatedAsCoursesAdmin()
  let isModerator = $auth.isAuthenticatedAsModerator()
  let isUsersAdmin = $auth.isAuthenticatedAsUsersAdmin()
  return AppHelper.isNullOrUndefined(isAdmin) ||
    AppHelper.isNullOrUndefined(isCoursesAdmin) ||
    AppHelper.isNullOrUndefined(isModerator) ||
    AppHelper.isNullOrUndefined(isUsersAdmin);
}

export default router
