<template>
    <div>
        <Navbar></Navbar>
        <UserList/>
    </div>
</template>

<script>
import Navbar from '@/components/Navbar';
import UserList from '@/components/users/UserList';
export default {
  components: {Navbar, UserList},
  name: 'Users',
  data: function () {
    return {}
  },
  methods: {
  }
}
</script>
