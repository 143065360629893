<template>
    <div>
        <div class="clear-btn">clear</div>
    </div>
</template>
<!-- using string template here to work around HTML <option> placement restriction -->
<!--<script type="text/x-template" id="demo-template">-->
<!--<div>-->
<!--<p>Selected: {{ selected }}</p>-->
<!--<select2 :options="options" v-model="selected">-->
<!--<option disabled value="0">Select one</option>-->
<!--</select2>-->
<!--</div>-->
<!--</script>-->

<!--<script type="text/x-template" id="select2-template">-->
<!--<select>-->
<!--<slot></slot>-->
<!--</select>-->
<!--</script>-->

<script>
import $ from 'jquery'
import 'select2';
import 'select2/dist/css/select2.min.css'
export default {
  name: 'Select2',
  props: ['options', 'value', 'ajax', 'placeholder', 'onselect'],
  data: () => {
    return {
      selectedValue: null
    }
  },
  // template: '#select2-template',
  mounted: function () {
    var vm = this;
    // init select2
    $(this.$el)
      .select2({
        placeholder: this.placeholder,
        // data: this.options,
        ajax: this.ajax,
        closeOnSelect: true,
        allowClear: false,
        // templateResult: function (company) {
        //   return 'selected';
          // return company.text;
        // },
        templateSelection: (company) => {
          // console.log('company', company, this.selectedValue);
          if (this.selectedValue) return this.selectedValue.text;
          return company.text;
        }
      })
      .val(this.value)
      .trigger('change')
      // emit event on change.
      .on('select2:select', (e) => {
        // console.log('vm.value', e.params.data.id);
        // vm.value = e.params.data;
        // $(this.$el).val(e.params.data.id).trigger('change');
        // $(this.$el).select2('val', e.params.data.id);
        this.selectedValue = e.params.data;
        $(this.$el).val(e.params.data).trigger('change');
        vm.$emit('change', e)
      });
  },
  watch: {
    value: function (value) {
      // update value
      console.log('vm.value', value);
      $(this.$el).val(value).trigger('change')
    },
    options: function (options) {
      // update option
      // $(this.$el).select2({data: options})
    }
  },
  methods: {
    clear () {
      this.selectedValue = null;
      $(this.$el).val(null).trigger('change');
    }
  },
  destroyed: function () {
    $(this.$el).off().select2('destroy')
  }
}
</script>
