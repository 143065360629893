import router from './router';
import { REFRESH_TOKEN_EXPIRED_TIME, rolesConstant } from './constants/constants';

export function objectExtend(a, b) {

  // Don't touch 'null' or 'undefined' objects.
  if (a == null || b == null) {
    return a;
  }

  Object.keys(b).forEach(function (key) {
    if (Object.prototype.toString.call(b[key]) == '[object Object]') {
      if (Object.prototype.toString.call(a[key]) != '[object Object]') {
        a[key] = b[key];
      } else {
        a[key] = objectExtend(a[key], b[key]);
      }
    } else {
      a[key] = b[key];
    }
  });

  return a;
};

/**
 * Auth Plugin
 *
 * (see https://vuejs.org/v2/guide/plugins.html for more info on Vue.js plugins)
 *
 * Handles login and token authentication using FireBase.
 */
export default {
  options: {
    tokenHeader: 'Authorization',
    tokenType: 'Bearer',
    cloudFlareToken: 'CF_Authorization',
    accessToken: 'accessToken',
    refreshToken: 'refreshToken',
    uidName: 'uidAdmin',
    isAdmin: 'isAdmin',
    isModerator: 'isModerator',
    isCoursesAdmin: 'isCoursesAdmin',
    isUsersAdmin: 'isUsersAdmin'
  },
  router: null,
  storage: null,
  http: null,
  store: null,

  install (Vue, options) {
    var $auth = this
    $auth.vue = Vue;
    $auth.storage = Vue.prototype.$localStorage
    $auth.http = Vue.prototype.$http
    $auth.options = objectExtend(this.options, options)

    $auth.bindRequestInterceptor()
    $auth.bindResponseInterceptor()
    Object.defineProperties(Vue.prototype, {
      $auth: {
        get: function () {
          return $auth
        }
      },
    })
  },

  /**
   * Login user using email and password
   * @param  {Object} user           User data
   * @param  {Object} requestOptions Request options
   * @return {Promise}               Request promise
   */
  login (user, requestOptions) { // deprecated
    var $auth = this
    const promise = $auth.http.post($auth.vue.mcf.api.auth.login, {
      email: user.email,
      password: user.password
    }).then(function (response) {
      $auth.setAccessToken(response.data.data.token)
      $auth.setRefreshToken(response.data.data.refresh_token, REFRESH_TOKEN_EXPIRED_TIME)
      return $auth.http.get($auth.vue.mcf.api.user.getCurrentUser).then(res => {
        return res.data;
      })
    }, error => {
    }).catch(function(error) {
      console.log('error', error);
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
    });

    return promise
  },

  /**
   * Logout current user
   * @param  {Object} requestOptions  Logout request options object
   * @return {Promise}                Request promise
   */
  logout (requestOptions) {
    this.removeToken();
    if (!this.getCloudFlareToken()) {
      router.push({name: 'login'});
    } else {
      window.location.replace(this.vue.mcf.cloudAccessUrl);
    }
  },

  isAuthenticated () {
    return !!this.getAccessToken();
  },

  isAuthenticatedAsCoursesAdmin () {
    return this.storage.get(this.options.isCoursesAdmin);
  },

  isAuthenticatedAsAdmin () {
    return this.storage.get(this.options.isAdmin);
  },

  isAuthenticatedAsModerator () {
    return this.storage.get(this.options.isModerator);
  },

  isAuthenticatedAsUsersAdmin () {
    return this.storage.get(this.options.isUsersAdmin);
  },

  /**
   * Get token if user is authenticated
   * @return {String} Authentication token
   */
  getAccessToken () {
    return this.storage.get(this.options.accessToken);
  },

  getRefreshToken () {
    return this.storage.get(this.options.refreshToken);
  },

  getCookie (name) {
    let cookie = {};
    document.cookie.split(';').forEach(function (el) {
      let [k, v] = el.split('=');
      cookie[k.trim()] = v;
    })
    return cookie[name];
  },

  getCloudFlareToken () {
    return this.getCookie(this.options.cloudFlareToken);
  },

  getAppTokenFromCloudFlareToken () {
    return this.http.post(this.vue.mcf.api.auth.cloudFlareToken, {
      cf_token: this.getCloudFlareToken() + ''
    })
  },

  initApp () {
    const $auth = this
    return this.http.get(this.vue.mcf.api.user.getCurrentUser).then(response => {
      const res = response.data;
      if (res && res.success) {
        const isAdmin = res.data.user.is_admin;
        const isCoursesAdmin = res.data.user.is_courses_admin;
        const isModerator = res.data.user.role_id === rolesConstant.MODERATOR;
        const isUsersAdmin = res.data.user.is_users_admin;

        if (isAdmin || isModerator || isCoursesAdmin || isUsersAdmin) {
          $auth.storage.set($auth.options.isAdmin, isAdmin);
          $auth.storage.set($auth.options.isModerator, isModerator);
          $auth.storage.set($auth.options.isCoursesAdmin, isCoursesAdmin);
          $auth.storage.set($auth.options.isUsersAdmin, isUsersAdmin);

          switch (true) {
            case isCoursesAdmin: router.push({name: 'home'}); break;
            case isModerator: router.push({name: 'moderation'}); break;
            case isUsersAdmin: router.push({name: 'users'}); break;
            case isAdmin: router.push({name: 'moderation'}); break;
          }
          return true;
        } else {
          alert('You have not permission access admin or moderator');
          $auth.logout();
        }
      } else {
        $auth.logout();
        alert('You have not permission access admin or moderator')
      }
      return false;
    })
  },

  /**
   * Set new authentication token
   * @param accessToken
   */
  setAccessToken (accessToken) {
    this.storage.set(this.options.accessToken, accessToken)
  },

  setRefreshToken (refreshToken, expiredTime) {
    if (expiredTime) {
      this.storage.set(this.options.refreshToken, refreshToken, expiredTime)
    } else {
      this.storage.set(this.options.refreshToken, refreshToken)
    }
  },

  /**
   * Remove authentication token
   * @param {String|Object} token
   */
  removeToken () {
    this.storage.remove(this.options.accessToken);
    this.storage.remove(this.options.refreshToken);
    this.storage.remove(this.options.uidName);
    this.storage.remove(this.options.isAdmin);
    this.storage.remove('current_course_id');
    this.storage.remove(this.options.isModerator);
    this.storage.remove(this.options.isCoursesAdmin);
    this.storage.remove(this.options.isUsersAdmin);
  },

  /**
   * Removes expired items
   */
  clearExpired () {
    this.storage.clear();
  },

  bindRequestInterceptor () {
    var $auth = this

    $auth.http.interceptors.request.use((config) => {
      if (!$auth.getCloudFlareToken()) {
        let expiredRefreshTokenTime;
        $auth.clearExpired();
        try {
          expiredRefreshTokenTime = JSON.parse(localStorage.getItem('vuels__' + this.options.refreshToken)).expire;
        } catch (e) {
          expiredRefreshTokenTime = 0;
        }
        if (!$auth.getRefreshToken() || expiredRefreshTokenTime === 0) {
          $auth.logout();
        }
      }
      const accessToken = this.getAccessToken();
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
      return Promise.resolve(config);
      })
  },

  bindResponseInterceptor () {
    var $auth = this
    $auth.http.interceptors.response.use(response => {
      return response
    }, error => {
      const originalRequest = error.config
      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        if ($auth.getCloudFlareToken()) {
          return $auth.getAppTokenFromCloudFlareToken().then(res => {
            this.setAccessToken(res.data.data.token)
            $auth.http.defaults.headers.common['Authorization'] = `Bearer ${res.data.data.token}`;
            return $auth.http(originalRequest)
          })
        } else {
          return $auth.http.post($auth.vue.mcf.api.auth.refreshToken, {
            refresh_token: this.getRefreshToken()
          }).then(res => {
            this.setAccessToken(res.data.data.token)
            this.setRefreshToken(res.data.data.refresh_token, REFRESH_TOKEN_EXPIRED_TIME)
            $auth.http.defaults.headers.common['Authorization'] = `Bearer ${res.data.data.token}`;
            return $auth.http(originalRequest)
          })
        }
      }
      return error;
    })
  },

}
