<template>
	<div>

  <div v-if="isStgApi" class="using-staging-tag">Using staging api</div>

    <!--content-->
    <router-view v-if="isReady"/>
    <div class="loading-div" v-show="!isReady" style="opacity: 0.8">
        <icon-loading></icon-loading>
    </div>
	</div>
</template>

<script>
  import IconLoading from '@/components/global/IconLoading'
  export default {
    name: 'App',
    components: {IconLoading},
    data: function () {
      return {
        isReady: true,
        refreshedToken: false,
        loadedConfig: false,
        isStgApi: false
      }
    },
    methods: {
      init () {
        let vm = this;
        // if (vm.refreshedToken && vm.loadedConfig) {
        //   vm.isReady = true;
        // }
      }
    },
    created () {
      let vm = this;
      vm.isStgApi = !!vm.$mcf.isStgApi;
    },
    async beforeCreate () {
      let vm = this;
    }
  }
</script>

<style>
	#app {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		/*margin-top: 60px;*/
	}
</style>
