<template>
  <div>
    <h2 class="heading">LOG IN TO YOUR ACCOUNT</h2>
    <div class="form-login">
      <form @submit.prevent="submit()">
        <div class="inputs-group">
          <div class="form-group has-border-bottom" :class="{'input': true, 'is-invalid': errors.has('email') }">
            <label>Email<btn class="input-error-popover" id="pp-email"><i-exclamation></i-exclamation></btn></label>
            <popover target="#pp-email" append-to="#popover-error" trigger="hover">
              <template slot="popover">Must be a valid email</template>
            </popover>
            <input type="text" tabindex="1" placeholder="abc@youremail.xyz" v-validate="'required|email'" data-vv-name="email" class="form-control app-input-text" v-model="email"/>
          </div>
          <div class="form-group" :class="{'input': true, 'is-invalid': errors.has('password') }">
            <label>Password<btn class="input-error-popover" id="pp-password"><i-exclamation></i-exclamation></btn></label>
            <popover target="#pp-password" append-to="#popover-error" trigger="hover">
              <template slot="popover">This field is required</template>
            </popover>
            <input type="password" tabindex="2" placeholder="********" v-validate="'required'" data-vv-name="password" class="form-control app-input-text" v-model="password"/>
          </div>
        </div>
        <button type="submit" tabindex="3" class="btn btn-primary btn-lg btn-block" v-show="!isLoading">LOG IN</button>
        <button type="button" class="btn btn-primary btn-lg btn-loading svg-loading-white" style="width: 100%" v-show="isLoading" disabled>
          <span class="txt-loading">LOG IN</span>
          <icon-loading></icon-loading>
        </button>
        <error-message v-show="errorMsg">{{errorMsg}}</error-message>
        <!--<error-message v-show="hasNotAcademyAccount">-->
          <!--We can see you have a valid F45 challenge / F45 LionHeart account, but this account hasn't yet been used to register you for F45 Academy. <router-link :to="{name: 'register'}" class="a-browser-setting" style="font-weight: bold">Click here to register now</router-link>.-->
        <!--</error-message>-->
      </form>
      <!--<div class="link-center forgot-link">-->
        <!--<router-link tabindex="3" :to="{name: 'forgotPassword'}">Forgot your password?</router-link>-->
      <!--</div>-->
      <!--<div class="link-center link-in-text">Don’t have a F45 account?-->
        <!--<router-link tabindex="4" :to="{name: 'register'}">Create your profile</router-link>-->
      <!--</div>-->
    </div>
  </div>
</template>
<script>
  import AppHelper from '@/helpers'
  import IconLoading from '@/components/global/IconLoading'
  import ErrorMessage from '../global/ErrorMessage'
  import {rolesConstant} from '../../constants/constants';
  import router from '../../router';

  export default {
    name: 'Login',
    components: {
      ErrorMessage,
      IconLoading
    },
    data () {
      return {
        email: '',
        password: '',
        errorMsg: '',
        hasNotAcademyAccount: false,

        isLoading: false
      }
    },
    methods: {
      submit () {
        let vm = this;

        vm.$validator.validateAll().then((result) => {
          if (result) {
            vm.isLoading = true;
            vm.errorMsg = '';
            vm.hasNotAcademyAccount = false;
            vm.$auth.login({
              email: vm.email,
              password: vm.password
            }).then(function (res) {
              vm.isLoading = false;
              if (res && res.success) {

                const isAdmin = res.data.user.is_admin;
                const isCoursesAdmin = res.data.user.is_courses_admin;
                const isModerator = res.data.user.role_id === rolesConstant.MODERATOR;
                const isUsersAdmin = res.data.user.is_users_admin;

                if (isAdmin || isModerator || isCoursesAdmin || isUsersAdmin) {
                  vm.$localStorage.set(vm.$auth.options.isAdmin, isAdmin);
                  vm.$localStorage.set(vm.$auth.options.isModerator, isModerator);
                  vm.$localStorage.set(vm.$auth.options.isCoursesAdmin, isCoursesAdmin);
                  vm.$localStorage.set(vm.$auth.options.isUsersAdmin, isUsersAdmin);

                  switch (true) {
                    case isCoursesAdmin: vm.$router.push({name: 'home'}); break;
                    case isModerator: vm.$router.push({name: 'moderation'}); break;
                    case isUsersAdmin: vm.$router.push({name: 'users'}); break;
                    case isAdmin: vm.$router.push({name: 'moderation'}); break;
                  }
                }

                vm.errorMsg = 'You have not permission access admin or moderator';

              } else {
                vm.errorMsg = 'You have not permission access admin or moderator';
              }

              AppHelper.log('Login success');
            }, (error) => {
              vm.isLoading = false;
              vm.errorMsg = error.data;
            })
          }
        })
      }
    },

    created () {
      if (this.$mcf.isDevelopmentMode) {
        this.email = 'testing@f45.com';
        this.password = 'aa123456'
      }
    }
  }
</script>
