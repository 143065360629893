<template>
  <div class="error-message">
    <div class="error-message__wrap">
      <i-exclamation></i-exclamation>
      <slot></slot>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ErrorMessage'
  }
</script>
