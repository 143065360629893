<template>
    <div>
        <form @submit.prevent="submitForm('formCourse')" v-bind:class="{'form-has-error': formHasError}">
            <div class="wrap-sections game-changers">
                <div class="left-main-section">
                    <div class="nav-left">
                        <div class="head">Video playlist</div>
                        <draggable
                            class="ul ul_courses"
                            v-model="playlists.data"
                            element="ul" v-if="isReady"
                            :options="dragOptionsPlaylist"
                            @end="onEndDragPlaylist"
                        >
                            <transition-group type="transition" :name="'flip-list'">
                                <li
                                    v-for="(playlist, indexPlaylist) in playlists.data"
                                    :key="playlist.id"
                                    v-bind:class="{ active : (currentPlaylist !== null && currentPlaylist.id === playlist.id)}"
                                    v-show="playlist.view_status !== STATUS.del"
                                >
                                    <span class="icon-draggable"></span>
                                    <div class="detail-course" v-on:click="selectPlaylist(indexPlaylist)">
                                        {{playlist.title}}
                                    </div>
                                    <a href="#" class="delete-course"
                                       v-on:click="deletePlaylist($event, indexPlaylist)">
                                        <i class="fa fa-delete"></i>
                                    </a>
                                </li>
                            </transition-group>
                        </draggable>
                        <div class="div-cta">
                            <button type="button" class="btn bt-big" v-on:click="addLocalPlaylist()">Add playlist
                            </button>
                        </div>
                    </div>
                </div>
                <div class="right-main-section">
                    <div>
                        <div class="course-info" v-if="currentPlaylist.id">
                            <div class="head">Info</div>
                            <div class="course-info-detail">
                                <div class="course-frame">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="frm-ad-course">
                                                <div class="form-group"
                                                     v-bind:class="{'is-invalid': !currentPlaylist.title}">
                                                    <label>Name</label>
                                                    <input tabindex="1" type="text" name="course_name"
                                                           v-model="currentPlaylist.title" class="form-control"/>
                                                </div>
                                                <div class="form-group">
                                                    <div class="status-course clearfix">
                                                        <label class="myCheckbox">
                                                            <input type="checkbox" name="coming_soon"
                                                                   v-model="currentPlaylist.coming_soon"/>
                                                            <span></span>
                                                            <div>Coming soon</div>
                                                        </label>
                                                        <label class="myCheckbox">
                                                            <input type="checkbox" name="show_on_site"
                                                                   v-model="currentPlaylist.show_on_site"/>
                                                            <span></span>
                                                            <div>Show on site</div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="form-group"
                                                     v-bind:class="{'is-invalid': !currentPlaylist.description}">
                                                    <label>Description</label>
                                                    <textarea class="area-description" name="course_description"
                                                              v-model="currentPlaylist.description"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="generate-course-info">
                                                <ul class="ul clearfix ul-photo">
                                                    <li>
                                                        <div class="wrap-img-thumb"><img
                                                                v-bind:src="(currentPlaylist.thumbnail_photo && currentPlaylist.thumbnail_photo.url)"
                                                                class="img-thumb"/></div>
                                                        <h5 class="name-images">Thumbnail</h5>
                                                        <p class="descript-images">This image will be used on the game changers page.</p>
                                                        <div class="div-cta">
                                                            <button type="button" class="btn-transparent"
                                                                    v-show="!uploadingThumbnailPhoto">Upload a photo
                                                                <vue-dropzone
                                                                        class="btn-dropzone dropzone_thumbnail_photo"
                                                                        id="dropzone_thumbnail_photo"
                                                                        :options="dropzoneOptionsThumb"
                                                                        v-on:vdropzone-sending="callBackSendingThumbnailPhoto"
                                                                        v-on:vdropzone-thumbnail="callBackAddedFileThumbnailPhoto"
                                                                        v-on:vdropzone-error="callBackFileErrorThumbnailPhoto"
                                                                        v-on:vdropzone-success="callBackUploadSuccessThumbnailPhoto"
                                                                        v-on:vdropzone-complete="callBackUploadCompleteThumbnailPhoto"
                                                                        :destroyDropzone="true">
                                                                </vue-dropzone>
                                                            </button>
                                                            <button class="btn-transparent"
                                                                    v-show="uploadingThumbnailPhoto">Upload a photo
                                                                <icon-loading></icon-loading>
                                                            </button>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <div class="user-type">
                                                    <div class="clearfix">
                                                        <div class="pull-left">Owner</div>
                                                        <div class="user-type-select pull-left">
                                                            <div class="ui-select">
                                                                <select v-model="currentPlaylist.for_owner">
                                                                    <option value="1">Required</option>
                                                                    <option value="2">Available</option>
                                                                    <option value="3">Hidden</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="clearfix">
                                                        <div class="pull-left">Trainer</div>
                                                        <div class="user-type-select pull-left">
                                                            <div class="ui-select">
                                                                <select v-model="currentPlaylist.for_trainer">
                                                                    <option value="1">Required</option>
                                                                    <option value="2">Available</option>
                                                                    <option value="3">Hidden</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="clearfix">
                                                        <div class="pull-left">Head trainer</div>
                                                        <div class="user-type-select pull-left">
                                                            <div class="ui-select">
                                                                <select v-model="currentPlaylist.for_head_trainer">
                                                                    <option value="1">Required</option>
                                                                    <option value="2">Available</option>
                                                                    <option value="3">Hidden</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="clearfix">
                                                        <div class="pull-left">Marketer</div>
                                                        <div class="user-type-select pull-left">
                                                            <div class="ui-select">
                                                                <select v-model="currentPlaylist.for_marketer">
                                                                    <option value="1">Required</option>
                                                                    <option value="2">Available</option>
                                                                    <option value="3">Hidden</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="course-info" v-if="currentPlaylist.id">
                            <div class="head">Videos</div>
                            <div class="course-info-detail">
                                <draggable class="ul ul_section" v-model="currentPlaylist.videos" element="ul"
                                           :options="dragOptionsSection" :move="onMove" @end="onEndDragVideo" @start="onStart">
                                    <transition-group type="transition">
                                        <li class="li-section" v-for="(section, indexSection) in currentPlaylist.videos"
                                            :key="section.id" v-bind:class="{ active: section.isActive}"
                                            v-show="section.view_status !== STATUS.del">
                                            <div class="collapse-wrapper">
                                                <!-- Section -->
                                                <div class="header header-section">
                                                    <span class="icon-draggable"></span>
                                                    <div class="detail-course row">
                                                        <div class="col-sm-7 video-title"
                                                             v-bind:class="{'is-invalid': !section.title}">
                                                            <input type="text" v-model="section.title"
                                                                   v-on:focus="onChangeVideo(indexSection)"
                                                                   class="form-control input-section"/>
                                                        </div>
                                                        <div class="col-sm-5 vimeo-video"
                                                             v-bind:class="{'is-invalid': !section.vimeo_video_id}">
                                                            <span for="vimeo-id"
                                                                  class="col-xs-5 col-sm-5 text-right text-label">Vimeo id:</span>
                                                            <div class="col-xs-7 col-sm-7">
                                                                <input id="vimeo-id" type="text" v-model="section.vimeo_video_id"
                                                                       v-on:focus="onChangeVideo(indexSection)"
                                                                       class="form-control input-section"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <a class="state-hover delete-course"
                                                       v-on:click="removeLocalVideo(indexSection)">
                                                        <i class="fa fa-delete"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </li>
                                    </transition-group>
                                </draggable> <!--/ draggable section -->
                                <div class="div-add-answer">
                                    <button type="button" class="bt-big pull-right btn-small"
                                            v-on:click="addLocalVideo(currentPlaylist.id)">Add video
                                    </button>
                                </div>
                            </div> <!--/ course-info-detail -->
                        </div><!--/ End course content-->
                    </div>
                </div>
            </div>
            <div class="nav-submit" v-if="currentPlaylist.id" v-show="isReady && isChanged">
                <!--<button v-show="!isSaving" class="btn bt-big" type="button" v-on:click="saveCoure">Save</button>-->
                <button v-show="!isSaving" class="btn bt-big" type="button" v-on:click="submitForm('formCourse')">Save
                </button>
                <button v-show="isSaving" class="btn bt-big" type="button">
                    <icon-loading></icon-loading>
                </button>
            </div>
            <modal class="modal-confirm center" v-model="modal.display" :title="modal.message" :footer="false">
                <i class="icon-close" @click="modal.cancel"></i>
                <button type="button" class="btn btn-default left" v-on:click="modal.ok">OK</button>
                <button type="button" class="btn btn-default left" v-on:click="modal.cancel">CANCEL</button>
            </modal>
        </form>
        <div class="loading-div" v-show="isLoading">
            <icon-loading></icon-loading>
        </div>
    </div>

</template>

<script>
  import draggable from 'vuedraggable'
  import vue2Dropzone from 'vue2-dropzone'
  import IconLoading from '@/components/global/IconLoading'

  const STATUS = {
    origin: 0,
    new: 1,
    update: 2,
    del: 3
  };

  const FLAGS = {
    REQUIRED: 1,
    AVAILABLE: 2,
    HIDDEN: 3
  }

  export default {
    components: {
      draggable,
      vueDropzone: vue2Dropzone,
      IconLoading
    },
    name: 'GameList',
    data() {
      return {
        isLoading: true,
        STATUS: STATUS,
        isSectionActive: false,
        isDragging: false,
        isSaving: false,
        editable: true,
        delayedDragging: false,
        isReady: false,

        dropzoneOptions: {
          url: this.$mcf.api.file.upload,
          maxFilesize: 5,
          headers: {
            "Cache-Control": false
          },
          autoProcessQueue: true,
          acceptedFiles: ".jpg,.jpeg,.png,.gif",
        },

        dropzoneOptionsThumb: {
          url: this.$mcf.api.file.uploadThumb,
          maxFilesize: 5,
          headers: {
            "Cache-Control": false
          },
          autoProcessQueue: true,
          acceptedFiles: ".jpg,.jpeg,.png,.gif",
        },

        uploadingThumbnailPhoto: false,
        newThumbnailPhoto: null,

        playlists: {},
        currentPlaylist: {
          videos: []
        },
        tempPlaylists: {
          // "id" : playlist
        },

        currentIndex: 0,

        isSaved: true,
        modal: {
          display: false,
          cancel: () => {
          },
          ok: () => {
          }
        },
        rawVideos: {},
        rawPlaylists: {},
        currentVideo: {}
      }
    },
    methods: {
      init() {
        let vm = this;
        if (vm.playlists.data !== undefined) {
          vm.isReady = true
        }

        const info = this.localPlaylistInfo
        let index = 0
        let playlist = vm.playlists.data[0]
        if (info && info.id) {
          let idx = vm.playlists.data.findIndex((p) => p.id === info.id)
          if (vm.playlists.data[idx]) {
            index = idx
            playlist = vm.playlists.data[idx]
          }
        }
        playlist && this.setCurrentPlaylist(playlist, index)
      },

      async getPlaylists(params) {
        this.isLoading = true
        const {data: {data: paginate}} = await this.$services.Playlist.list(params)
        if (paginate) {
          paginate.data = paginate.data.map(this.parsePlaylist)
          this.playlists = paginate
        }
        this.isLoading = false
      },

      onEndDragPlaylist(event) {
        const vm = this
        const playlist = vm.playlists.data[event.newIndex];
        if (playlist.id === this.currentPlaylist.id) {
          this.currentIndex = event.newIndex
        }
        if (playlist.view_status === STATUS.new) {
          playlist.order = event.newIndex
          return
        }

        vm.syncOrderPlaylist(playlist, event.oldIndex, event.newIndex);
      },

      async syncOrderPlaylist(playlist, oldIndex, newIndex) {
        const vm = this
        vm.$services.Playlist.update({
          id: playlist.id,
          order: newIndex
        })
      },

      onEndDragVideo(event) {
        const vm = this
        if (event.newIndex !== event.oldIndex) {
          const video = vm.currentPlaylist.videos[event.newIndex];
          this.onChangeVideo(event.newIndex)
          vm.syncOrderVideo(video, event.oldIndex, event.newIndex);
        }
      },

      async syncOrderVideo(video, oldIndex, newIndex) {
        const vm = this
        const videos = [...this.currentPlaylist.videos]
        videos[newIndex].order = newIndex
        Object.assign(this.currentPlaylist, {
          videos
        })
        // vm.$services.PlaylistVideo.update({
        //   id: video.id,
        //   order: newIndex
        // })
      },

      // detail Course
      onStart(evt) {
        let vm = this;
        vm.isDragging = true;
      },

      onMove({relatedContext, draggedContext}) {
        const relatedElement = relatedContext.element;
        const draggedElement = draggedContext.element;
        return (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      },

      onPlaylistChange() {

      },

      async selectPlaylist(index) {
        let vm = this;

        // vm.clearImages();
        if (index === this.currentIndex) {
          return
        }

        const playlist = this.playlists.data[index]
        if (vm.isChanged) {
          await this.showModal('If you open other link, current data was lost?')
          // this.addPlaylistTemp(playlist)
        }
        // const temp = this.getPlaylistTemp(playlist.id)

        this.setCurrentPlaylist(playlist, index)

        this.saveLocalPlaylistInfo(playlist)
      },

      setCurrentPlaylist(playlist, index) {
        const p = this.playlists.data[index]
        this.currentIndex = index
        this.currentPlaylist = p || {videos: []}
        // this.currentPlaylist = playlist
        // this.currentPlaylist = Object.assign(playlist, {
        //   for_owner: playlist.for_owner.toString(),
        //   for_trainer: playlist.for_trainer.toString(),
        //   for_head_trainer: playlist.for_head_trainer.toString(),
        //   for_marketer: playlist.for_marketer.toString()
        // })
        // this.currentIndex = (
        //   (index !== undefined) ? index : this.playlists.data.findIndex((p) => p.id === playlist.id)
        // )
      },

      async deletePlaylist(event, index) {
        event.preventDefault()
        try {
          await this.showModal('Are you sure  delete this playlist?')
          if (this.playlists.data[index].view_status !== STATUS.new) {
            await this.$services.Playlist.delete(this.playlists.data[index].id)
          }
          this.removeLocalPlaylist(index)
        } catch (e) {
          console.log(e)
        }
      },

      addLocalPlaylist() {
        const index = this.playlists.data.length
        const newPlaylist = {
          _id: Date.now().toString(),
          view_status: STATUS.new,
          title: 'Playlist Name',
          description: 'Playlist description',
          order: index,
          for_owner: FLAGS.REQUIRED.toString(),
          for_trainer: FLAGS.REQUIRED.toString(),
          for_head_trainer: FLAGS.REQUIRED.toString(),
          for_marketer: FLAGS.REQUIRED.toString(),
          videos: [],
          thumbnail_photo: null,
          coming_soon: false,
          show_on_site: true
        }
        this.playlists.data.push(newPlaylist)

        this.setCurrentPlaylist(newPlaylist, index)
      },

      removeLocalPlaylist(index) {
        const next = this.playlists.data[index + 1]
        const prev = this.playlists.data[index - 1]
        this.playlists.data.splice(index, 1)

        if (next) {
          this.setCurrentPlaylist(next, index)
          return
        }
        if (prev) {
          this.setCurrentPlaylist(prev, index - 1)
          return
        }

        this.setCurrentPlaylist({ videos: [] }, -1)
      },

      addPlaylistTemp(playlist) {
        this.tempPlaylists[playlist.id] = playlist
      },

      getPlaylistTemp(id) {
        return this.tempPlaylists[id]
      },

      saveLocalPlaylistInfo(playlist) {
        this.$localStorage.set('current_playlist_info', JSON.stringify({
          id: playlist.id,
          total_loaded: this.playlists.data.length
        }))
      },

      onChangeVideo(index) {
        const video = this.currentPlaylist.videos[index]
        if (!this.rawVideos[video.id]) {
          this.rawVideos[video.id] = JSON.stringify(video)
        }
      },

      addLocalVideo(id) {
        const newVideo = {
          _id: Date.now().toString(),
          title: 'Video Name',
          view_status: STATUS.new,
          playlist_id: id,
          order: this.currentPlaylist.videos.length,
          vimeo_video_id: 'Video ID'
        }
        this.currentPlaylist.videos.push(newVideo)
      },

      removeLocalVideo(index) {
        const videos = [...this.currentPlaylist.videos]
        const video = videos[index]

        if (video.view_status === STATUS.new) {
          this.deleteLocalVideo(video.id, index)
          return
        }

        video.view_status = STATUS.del
        Object.assign(this.currentPlaylist, {
          videos
        })
      },

      async updatePlaylist() {
        const body = this.playlistBody()
        if (body.view_status === STATUS.new) {
          const res = await this.$services.Playlist.create(body)
          delete this.currentPlaylist['view_status']

          return this.parsePlaylist(res.data.data)
        }
        const res = await this.$services.Playlist.update(body)
        return this.parsePlaylist(res.data && res.data.data)
      },

      updateVideos(videos = [], playlist) {
        const svc = this.$services.PlaylistVideo
        return Promise.all(videos.map(async (video) => {

          switch (video.view_status) {
            case STATUS.new:
              video.playlist_id = playlist.id
              const res = await svc.create(video)
              if (res.data && res.data.data) {
                delete video['view_status']
                Object.assign(video, res.data.data)
              }
              break
            case STATUS.del:
              await svc.delete(video.id)
              this.deleteLocalVideo(video.id)
              break
            default:
              await svc.update(video)
          }
        }))
      },

      deleteLocalVideo(id, index) {
        const p = this.currentPlaylist
        index = (
          index !== undefined ? index : p.videos.findIndex((v) => (v.id === id))
        )
        if (index > -1) {
          p.videos.splice(index, 1)
        }
      },

      async submitForm() {
        if (this.formHasError) {
          return
        }
        this.isSaving = true
        try {
          const playlist = await this.updatePlaylist()
          await this.updateVideos(this.videoBody(), playlist)

          const plist = JSON.parse(JSON.stringify(this.currentPlaylist))
          this.currentPlaylist = Object.assign({}, plist, playlist)
          this.playlists.data[this.currentIndex] = this.currentPlaylist
          // this.currentPlaylist['_id'] = playlist.id
          // this.currentPlaylist.id = playlist.id
          // Object.assign(this.currentPlaylist, playlist)
          // console.log(this.currentPlaylist)
          this.saveRawCurrentPlaylist(true)

          // this.isSaved = true
        }
        catch(e) {
          console.log(e)
        }
        finally {
          this.isSaving = false
        }

      },

      showModal(message) {
        const vm = this

        vm.modal = {
          display: true,
          message,
          promise: new Promise((resolve, reject) => {
            vm.modal.counter = setInterval(() => {
              if (vm.modal.canceled !== undefined) {
                if (!vm.modal.canceled) {
                  resolve(true)
                } else {
                  reject(new Error('Cancel'))
                }
                clearInterval(vm.modal.counter)
              }
            }, 500)
          })
        }

        vm.modal.ok = (event) => {
          vm.modal.display = false
          vm.modal.canceled = false
        }

        vm.modal.cancel = (event) => {
          vm.modal.display = false
          vm.modal.canceled = true
        }
        return vm.modal.promise
      },

      saveRawCurrentPlaylist(force = false) {
        if (this.currentPlaylist) {
          const rawPlaylist = this.rawPlaylists[this.currentPlaylist.id]
          if (force || !rawPlaylist) {
            const raw = JSON.stringify(this.currentPlaylist)
            console.log(raw)
            this.rawPlaylists[this.currentPlaylist.id] = raw
          }
        }
      },

      exportDiffObject(obj1, obj2, includeFields = []) {
        let hasDiff = false
        const diff = {}
        Object.keys(obj1).forEach((key) => {
          if (obj2[key] !== obj1[key]) {
            hasDiff = true
            diff[key] = obj1[key]
          }
        })

        if (includeFields.length > 0) {
          includeFields.forEach((field) => { diff[field] = obj1[field] })
        }
        return hasDiff && diff
      },

      //upload file
      callBackSendingThumbnailPhoto() {
        this.uploadingThumbnailPhoto = true;
      },
      callBackAddedFileThumbnailPhoto(file, dataUrl) {
        if (file && file.status !== 'error') {
          this.newThumbnailPhoto = dataUrl
        }
      },
      callBackFileErrorThumbnailPhoto(file, message) {
        if (typeof message !== 'string') {
          // this.$toasted.show('Can not upload your image.')
          console.log('Can not upload your image.');
        } else {
          // this.$toasted.show(message)
          console.log(message)
        }
        this.newThumbnailPhoto = null
      },
      callBackUploadSuccessThumbnailPhoto(file, { data }) {
        Object.assign(this.currentPlaylist, {
          thumbnail_photo_id: data.file.id,
          thumbnail_photo: data.file
        })
        this.uploadingThumbnailPhoto = false;
      },
      callBackUploadCompleteThumbnailPhoto(file) {
        this.uploadingThumbnailPhoto = false;
      },

      parsePlaylist(playlist) {
        Object.assign(playlist, {
          for_owner: playlist.for_owner.toString(),
          for_trainer: playlist.for_trainer.toString(),
          for_head_trainer: playlist.for_head_trainer.toString(),
          for_marketer: playlist.for_marketer.toString()
        })
        return playlist
      },

      playlistBody() {
        const currentPlaylist = {...this.currentPlaylist}
        Object.assign(currentPlaylist, {
          for_owner: Number(currentPlaylist.for_owner),
          for_trainer: Number(currentPlaylist.for_trainer),
          for_head_trainer: Number(currentPlaylist.for_head_trainer),
          for_marketer: Number(currentPlaylist.for_marketer)
        })
        if (currentPlaylist.view_status === STATUS.new) {
          return currentPlaylist
        }

        const playlist = JSON.parse(this.rawPlaylists[this.currentPlaylist.id])
        const body = this.exportDiffObject(currentPlaylist, playlist, ['_id'])
        delete body['videos']
        return body
      },

      videoBody() {
        const body = []
        const currentPlaylist = this.currentPlaylist
        if (currentPlaylist.view_status === STATUS.new) {
          return currentPlaylist.videos
        }
        currentPlaylist.videos.forEach((video) => {
          switch (video.view_status) {
            case STATUS.new:
              body.push(video)
              break
            case STATUS.del:
              body.push(video)
              break
            default:
              const raw = this.rawVideos[video.id]
              if (raw) {
                const diff = this.exportDiffObject(video, JSON.parse(raw), ['_id'])
                if (diff) {
                  body.push(diff)
                }
              }
          }
        })
        return body
      }
    },
    computed: {
      dragOptionsPlaylist() {
        return {
          animation: 150,
          group: 'course',
          disabled: !this.editable,
          ghostClass: 'ghost',
        };
      },
      dragOptions() {
        return {
          animation: 150,
          group: 'description',
          disabled: !this.editable,
          ghostClass: 'ghost',
        };
      },
      dragOptionsSection() {
        return {
          animation: 150,
          ghostClass: 'ghost',
          group: 'sections',
          disabled: !this.editable,
        };
      },

      isChanged () {
          const isChanged = (
            this.currentPlaylist.view_status === STATUS.new ||
            JSON.stringify(this.currentPlaylist) !== this.rawPlaylists[this.currentPlaylist.id]
          )

        // this.isSaved = !isChanged

        return isChanged
      },

      localPlaylistInfo() {
        return JSON.parse(this.$localStorage.get('current_playlist_info'))
      },


      formHasError() {
        const p = this.currentPlaylist
        let invalid = !p.title
        !invalid && p.videos.forEach((video) => {
          if (!video.title || !video.vimeo_video_id) {
            invalid = true
            return false
          }
        })
        return invalid
      }
    },
    beforeCreate() {
      let vm = this;
    },

    async created() {
      let perPage = 10
      const info = this.localPlaylistInfo
      if (info && info.total_loaded) {
        perPage = info.total_loaded !== perPage ? perPage : info.total_loaded
      }
      await this.getPlaylists({paginate: 0})
      this.init()
    },

    watch: {
      currentPlaylist: function (newValue) {
        console.log('this.currentPlaylist', this.currentPlaylist)
        this.saveRawCurrentPlaylist()
      },
      currentIndex: function (newValue) {
        // this.isSaved = !(
        //   this.currentPlaylist.view_status === STATUS.new ||
        //   JSON.stringify(this.currentPlaylist) !== this.rawPlaylists[this.currentPlaylist.id]
        // )
        console.log(newValue, this.currentIndex, this.currentPlaylist)
        // this.currentPlaylist = this.playlists.data[newValue]
      }
    }
  }
</script>
