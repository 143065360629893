<template>
    <div>
        <Navbar is_fittest="true"></Navbar>
        <!--<div class="message notification" v-bind:class="{active: isShowNotify}" v-if="isShowNotify">-->
            <!--<div class="alert alert-success" role="alert">-->
                <!--{{notificationMessage}}-->
            <!--</div>-->
        <!--</div>-->
        <div class="loading-div" v-show="isLoading" style="opacity: 0.8">
            <icon-loading></icon-loading>
        </div>
        <div class="moderation-component">
            <div class="head clearfix">
                <div class="pull-right">
                    <div class="select2-options filter-cert">
                        <button v-if="$route.query.user" class="btn btn-link clear-filter" @click="clearFilter()">Clear filter</button>

                        <select2
                                ref="selectUser"
                                v-bind:options="userOptions"
                                v-bind:ajax="ajaxUser"
                                v-bind:searchText="searchText"
                                v-on:change="onSelectUser($event)"
                                placeholder="Filter by user"
                        >
                        </select2>
                    </div>
                    <button class="btn btn-link" v-on:click="downloadExpiringSoon()" style="color:white;">Expiring soon</button>
                </div>
                <div class="pull-left">CERTIFICATIONS</div>
            </div>
            <div class="moderation-component-body" v-if="isReady">
                <div class="moderation-item panel" v-for="certificate in certificates">
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-xs-12 col-sm-10">
                                <div class="moderation-options">
                                    <h4 class="moderation-title">{{`${certificate.user.first_name} ${certificate.user.last_name} (${certificate.user.email})`}}</h4>
                                    <div class="table">
                                        <div class="table-cell">
                                            <div>
                                                <label>{{certificate.name || certificate.description}}</label>
                                            </div>
                                            <div>
                                                <label>Expiry: {{displayExpireDate(certificate.expire_date)}}</label>
                                            </div>
                                            <div>
                                                <label>Date added: {{displayDateAdded(certificate.created_at)}}</label>
                                            </div>
                                            <div>
                                                <a href="#" v-on:click="onViewCertificate($event, certificate)">
                                                    View certificate evidence
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-2">
                                <div class="moderation-status">
                                    <i v-if="!certificate.status"> Not yet moderated</i>
                                    <i v-if="certificate.status == states.approved">Approved</i>
                                    <i v-if="certificate.status == states.rejected">Rejected</i>
                                    <span></span>
                                </div>
                                <div class="moderation-actions">
                                    <button class="btn btn-success btn-md"
                                            v-bind:class="{'disabled': certificate.status == states.approved}"
                                            v-on:click="onApproved(certificate)">Approve</button>
                                    <button class="btn btn-danger btn-md"
                                            v-bind:class="{'disabled': certificate.status == states.rejected}"
                                            v-on:click="onRejected(certificate)">Reject</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <pagination
                            v-bind:active="!isLoading"
                            v-bind:pagination="pagination"
                            v-bind:onChangePage="onChangePage"
                    />
                </div>
            </div>
        </div>

        <modal class="modal-confirm modal-cert-reject" v-model="showModalReject" :footer="false" :header="false">
            <i class="icon-close" @click="onRejected()"></i>
            <p class="mgb-20">Please provide a description for the rejection. This description will be sent to the user
                so please make it as descriptive as possible outlining what they need to do to resolve the issue.</p>
            <div class="form-group">
                <label class="label-title">Enter message</label>
                <textarea class="form-control ui-form-control" v-bind:class="{'is-invalid': !msg_reject}" v-model="msg_reject"></textarea>
            </div>
            <button type="button" class="btn btn-primary left" v-on:click="onConfirmReject()">REJECT</button>
        </modal>
    </div>
</template>

<script>
  import Navbar from '@/components/Navbar'
  import VideoPlay from '@/components/video/VideoPlay'
  import IconLoading from '@/components/global/IconLoading'
  import ZigPlayer from '@/components/video/ZigPlayer'
  import Pagination from '@/components/global/Pagination'
  import AppHelper from '@/helpers'
  import Select2 from '@/components/global/Select2'

  const states = {
    unmoderated: 0,
    approved: 1,
    rejected: 2,
  };

  export default {
    components: {IconLoading, Navbar, VideoPlay, ZigPlayer, Pagination, Select2},
    name: 'CertificateList',
    data: function () {
      return {
        states,
        isLoading: true,
        certificates: [],
        pagination: {},
        isReady: false,
        userOptions: [],
        studio: null,
        currentUser: null,
        courses: [],
        selectedAll: false,
        isSaving: false,
        isLoadingCourses: false,
        searchText: null,
        studios: [],
        caches: {},
        showModalReject: false,
        msg_reject: '',
        currentCertificate: null,
      }
    },
    methods: {
      async loadUsers (page = 1, userId, params = {}) {
        if (!page) page = 1;

        this.showLoading(true)

        const res = await this.$services.Certificate.list({ page, user_id: userId, ...params});
        const data = res.data.items;
        // console.log('dddd', res, data)

        if (data.length > 0) {
          this.certificates = data;
        } else {
          this.certificates = [];
        }
        this.pagination = {
          ...res.data,
          data: null
        }
        this.showLoading(false);
      },
      async searchUsers (page = 1, studioCode = undefined, searchText = undefined, email = undefined, params = {}) {
        if (!page) page = 1;

        if (searchText && AppHelper.isEmail(searchText)) {
          email = searchText;
          searchText = undefined;
        }
        const res = await this.$services.User.list({page, studio_code: studioCode, search: searchText, email, ...params});
        return res.data;
      },
      async searchStudio (search = null, page = 0, searchBy = undefined, params = {}) {
        const body = {page, search: searchBy, ...params};
        if (search) {
          body.query = search;
        }
        const res = await this.$services.Studio.list(body)
        return res.data.data;
      },
      async loadCourses (userId) {
        try {
          this.isLoadingCourses = true;
          const res = await this.$services.User.uncompleteUserCourses(userId);
          if (res) {
            this.courses = res.courses;
          }
        } finally {
          this.isLoadingCourses = false;
        }
      },
      showLoading (force) {
        this.isLoading = force;
      },
      onChangePage (pageNumber) {
        this.loadUsers(pageNumber, this.$route.query.user);
      },
      onSelectStudio (e) {
        // console.log('ddd', e.params.data);
        this.$router.replace({ query: { ...this.$route.query, studio: e.params.data.code } });
        // this.loadUsers(undefined, e.params.data.code);
      },
      onSelectUser (e) {
        this.$router.replace({ query: { ...this.$route.query, user: e.params.data.id } });
      },
      showCourseModal (user) {
        this.currentUser = user;
      },
      onSelectCourse (course) {
        course.selected = !course.selected;
      },
      onSelectAllCourse (e) {
        this.selectedAll = !this.selectedAll;
        this.courses = this.courses.map((course) => {
          course.selected = this.selectedAll;
          return course;
        });
      },
      closeModalAndReset () {
        this.$bvModal.hide('courses-modal');
        this.selectedAll = false;
        this.courses = [];
      },
      downloadCert (event, course) {
        event.preventDefault();
        event.stopPropagation();
        window.open(course.certificate.url, '_blank');
      },
      onViewCertificate (event, certificate) {
        if (!certificate.file_path) return;
        event.preventDefault();
        event.stopPropagation();
        window.open(certificate.file_path, '_blank');
      },
      clearFilter () {
        const query = {...this.$route.query};
        if (query['user']) {
          delete query['user'];
        }
        this.$refs.selectUser.clear();
        this.$router.replace({ query });
      },
      onSearchSubmit (event) {
        event.preventDefault();
        // if (this.searchText) {
        this.loadUsers(undefined, undefined);
        // }
      },
      async onApproved (certificate) {
        if (certificate.status == states.approved) return;
        this.showLoading(true);
        const res = await this.$services.Certificate.approve(certificate.id);
        // console.log('ddddd', res);
        certificate.status = res.data.cert.status;
        this.showLoading(false);
      },
      onRejected (certificate) {
        if (certificate && certificate.status == states.rejected) return;
        this.currentCertificate = certificate;
        this.showModalReject = !this.showModalReject;
      },
      async onConfirmReject () {
        this.showLoading(true);
        const res = await this.$services.Certificate.reject(this.currentCertificate.id, this.msg_reject);
        this.currentCertificate.status = res.data.cert.status;
        this.onRejected(undefined);
        this.showLoading(false);
      },
      displayDateAdded (date) {
        return this.$moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY');
      },
      displayExpireDate (date) {
        if (!date) return 'n/a';
        return this.$moment(date, 'YYYY-MM-DD').format('DD MMM YYYY');
      },
      forceFileDownload (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a');
        const date = this.$moment();
        link.href = url
        link.setAttribute('download', `Certificates_expiring_soon_${date.format('YYYYMMDD')}.csv`) // or any other extension
        document.body.appendChild(link)
        link.click()
      },
      async downloadExpiringSoon () {
        this.showLoading(true)
        try {
          const res = await this.$services.Certificate.downloadExpiringSoon()
          this.forceFileDownload(res);
        } catch (e) {
          // console.log(e)
        } finally {
          this.showLoading(false)
        }
      }
    },
    watch: {
      '$route': {
        handler (newVal, oldVal) {
          if (newVal.query.user !== oldVal.query.user) {
            this.loadUsers(1, newVal.query.user)
          }
        },
        deep: true
      }
    },
    computed: {},
    beforeCreate () {},
    created () {},
    async beforeMount () {
      this.ajaxUser = {
        delay: 1000,
        transport: async (params, success, failure) => {
          // if (params.data.q) {
          if (!params.data.q && this.searchText && this.caches[this.searchText]) {
            success(this.caches[this.searchText]);
            return;
          }
          if (params.data.q && this.caches[params.data.q]) {
            success(this.caches[this.searchText]);
            return;
          }
          if (!params.data.q) {
            success();
            return;
          }
          try {
            const res = await this.searchUsers(params.data.page, undefined, params.data.q, undefined, {with: 'studios'});
            this.searchText = params.data.q;
            this.caches[params.data.q] = res;
            success(res)
          } catch (e) {
            // console.log(e)
            failure(e)
          }
          // } else {
          //   success({studios: []});
          // }
        },
        processResults: (data) => {
          // console.log('data', data);
          if (!data) {
            return {
              results: [],
              more: false,
            }
          }
          const results = data.items.map((item) => ({
            id: item.id,
            email: item.email,
            text: item.first_name + ' ' + item.last_name,
          }));

          // this.options = results;
          return {
            results,
            pagination: {
              more: data.current_page < data.last_page
            }
          }
        },
        cache: true
      }
    },
    async mounted () {
      const currentPage = this.$route.query.page;
      const currentUser = this.$route.query.user;
      await this.loadUsers(currentPage, currentUser);
      this.isReady = true;
    }
  }

</script>
