<template>
    <div class="switch-box">
        <label class="switch">
            <input v-on:change="onChange($event)" type="checkbox" v-bind:checked="checked">
            <span class="slider round"></span>
        </label>
        <span class="label">{{ checked && checkedLabel || label }}</span>
    </div>
</template>

<script>
  export default {
    name: 'SwitchBox',
    props: {
      label: {
        type: String
      },
      checkedLabel: {
        type: String
      },
      checked: {
        type: Boolean,
        default: false
      },
    },
    data: function () {
      return {};
    },
    methods: {
      onChange(event) {
        this.$emit('change', !this.checked, event);
      }
    }
  }
</script>

<style scoped>

</style>
