<template>
  <div>
    <div class=" video-control playvideo-full">
      <div class="frame-video-full">
        <div class="video-box embed-responsive embed-responsive-16by9">
          <div class="embed-responsive-item">
            <div v-bind:id="divIdVideo" class="ziggeo-player"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="loading-div" v-show="isLoading">
      <icon-loading></icon-loading>
    </div>
  </div>

</template>

<script>

  import IconLoading from '@/components/global/IconLoading'

  export default {
    components: {IconLoading},
    name: 'VideoPlay',
    data: function () {
      return {
        isLoading: true,
        divIdVideo: 'ziggeo-player-vd',
        video_id: '',
        isMounted: false,
        loadedConfig: false
      }
    },
    methods: {
      init (){
        let vm = this;
        setTimeout(function () {
          if(vm.isMounted && vm.loadedConfig){
            vm.initZiggeo();
          }
        }, 1000);
      },

      initZiggeo: function () {

        let vm = this;
        vm.ziggeoApp = ZiggeoApi.V2.Application.getDefault();
        if (!vm.ziggeoApp) {
          console.log('vm.$mcf.ziggeo.token video play: ', vm.$mcf.ziggeo.token);
          vm.ziggeoApp = new ZiggeoApi.V2.Application({token: vm.$mcf.ziggeo.token})
        }
        vm.video_id = vm.$route.params.vid;
        vm.ziggeoApp.on('ready', function () {
          setTimeout(function () {
            let video = new ZiggeoApi.V2.Player({
              element: document.getElementById(vm.divIdVideo),
              attrs: {
                width: '100%',
                height: 'auto',
                theme: 'modern',
                themecolor: 'red',
                video: vm.video_id
              }
            });
            video.activate();
            vm.isLoading = false;
          }, 1000)
        })

      }
    },
    computed: {

    },
    beforeCreate () {
      let vm = this;

    },
    created () {

      let vm = this;

      if (!vm.$mcf.isDevelopmentMode) {
        vm.$http.get(vm.$mcf.api.config).then(response => {
          vm.$mcf.ziggeo.token = response.data.data.ziggeo.app_token;

          console.log('vm.$mcf.ziggeo.token: ', vm.$mcf.ziggeo.token);

          vm.loadedConfig = true;
          vm.init()
        }, () => {
          vm.loadedConfig = true;
          vm.init()
        })
      } else {
        vm.loadedConfig = true;
        vm.init()
      }

    },
    mounted () {
      let vm = this;
      vm.isMounted = true;
      vm.init();
    }
  }

</script>
