<template>
  <div class="page-not-found">
    <div class="container">
      <div class="not-found-container">
        <router-link :to="{name: 'home'}">
          <img class="img-responsive center-block" src="../../assets/images/logo-2.svg" alt=""/>
        </router-link>
        <h1>404!</h1>
        <p>We couldn’t find what you’re looking for :(</p>
        <h2>NOTHING IS IMPOSSIBLE.<br/>TRY AGAIN.</h2>
        <router-link :to="{name: 'home'}" class="btn btn-primary btn-lg btn-block">START FROM THE HOMEPAGE</router-link>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NotFound'
  }
</script>
