<template>
	<div>
    <Navbar></Navbar>
    <course-list></course-list>
	</div>
</template>

<script>
  import Navbar from '@/components/Navbar'
  import CourseList from '@/components/course/CourseList'

  export default {
    components: {Navbar, CourseList},
    name: 'Courses',
    data: function () {
      return {

      }
    },
    methods: {

    }
  }
</script>

<style>
	#app {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		margin-top: 60px;
	}
</style>
