<template>
  <div :class="getPageClass">
    <div class="container">
      <div class="login-container center-block">
        <router-link :to="{name: 'home'}" class="site-logo">
          <img class="img-responsive center-block" src="../../assets/images/logo-light-blue.svg" alt=""/>
        </router-link>

        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Auth',
    components: {},
    computed: {
      getPageClass () {
        var cls = '';

        switch (this.$route.name) {
          case 'login':
            cls = 'login-page';
            break;
          case 'forgotPassword':
            cls = 'login-page forgot-password';
            break;
          case 'resetPassword':
            cls = 'login-page reset-password';
            break;
          default:
            cls = 'login-page';
            break;
        }
        return cls
      }
    },
    data () {
      return {

      }
    },

    methods: {

    },

    created() {

    }
  }
</script>

