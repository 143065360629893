<template>
	<div>
    <Navbar is_fittest="true"></Navbar>
		<div class="message notification" v-bind:class="{active: isShowNotify}" v-if="isShowNotify">
			<div class="alert alert-success" role="alert">
				{{notificationMessage}}
			</div>
		</div>
		<div class="moderation-component user-list">
			<div class="head clearfix">
        <div class="pull-right"><input class="form-control moderation-search-email" type="text" placeholder="Search..." v-model="search" v-on:change="searchByEmail"/></div>
        <div class="pull-right">
          <div class="select-studio">
            <button v-if="$route.query.status!==undefined" class="btn btn-link clear-filter" @click="clearFilter()">Clear filter</button>
            <select2
              ref="select2"
              v-bind:ajax="getStatus"
              v-on:change="onSelectStatus($event)"
              placeholder="Filter by Status"
            >
            </select2>
          </div>
        </div>
        <div class="pull-left">FITTEST MODERATION</div>
      </div>
			<div class="moderation-component-body" v-if="isReady">
				<div class="moderation-item panel"  v-bind:key="fitTest.id" v-for="fitTest in userFitTest">
					<div class="panel-body">
						<div class="row">
							<div class="col-xs-12 col-sm-4">
								<!--<div class="embed-responsive embed-responsive-16by9 video-box">-->
									<!--<img class="embed-responsive-item" src="../../assets/images/video1.png">-->
									<div style="position: relative">
                    <zig-player v-if="fitTest.video_id" v-bind:pr-video-id="fitTest.video_id" v-bind:pr-container-id="'video-id-' + fitTest.video_id"></zig-player>
                    <div class="zg-player-overlay" @click="openVideoModal(fitTest.video_id)"></div>
                  </div>
								<!--</div>-->
							</div>
							<div class="col-xs-12 col-sm-6">
								<div class="moderation-options">
									<h4 class="moderation-title">{{fitTest.user.info}}</h4>
									<div class="table">
                    <div class="table-cell">
                      <div class="form-group">
                        <label>Burpees</label>
                        <input type="number" class="form-control" v-on:change="onChangeScore(fitTest)" v-model="fitTest.tests.burpees.score"/>
                      </div>
                      <div class="form-group">
                        <label>Jump squats</label>
                        <input type="number" class="form-control" v-on:change="onChangeScore(fitTest)" v-model="fitTest.tests.jump_squats.score"/>
                      </div>
                      <div class="form-group">
                        <label>Push ups</label>
                        <input type="number" class="form-control" v-on:change="onChangeScore(fitTest)" v-model="fitTest.tests.push_ups.score"/>
                      </div>
                    </div>
                    <div class="table-cell">
                      <div class="form-group">
                        <label>Mountain climbers </label>
                        <input type="number" class="form-control" v-on:change="onChangeScore(fitTest)" v-model="fitTest.tests.mountain_climbers.score"/>
                      </div>
                      <div class="form-group">
                        <label>Lateral shoot throughs</label>
                        <input type="number" class="form-control" v-on:change="onChangeScore(fitTest)" v-model="fitTest.tests.lateral_shoot_throughs.score"/>
                      </div>
                      <div class="form-group">
                        <label>Plank</label>
                        <input type="number" class="form-control" v-on:change="onChangeScore(fitTest)" v-model="fitTest.tests.plank.score"/>
                      </div>
                    </div>
									</div>
									<div class="table total">
										<div class="table-cell">
											<div class=" form-group">
												<label>Total</label>
												<div class="value form-control">{{fitTest.total}}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-xs-12 col-sm-2">
								<div class="moderation-status" v-bind:class="getClassModerationStatus(fitTest.mod_status)">
									<i v-if="fitTest.mod_status == STATUS.unmoderated"> Not yet moderated</i>
									<i v-if="fitTest.mod_status == STATUS.review"> Review</i>
									<i v-if="fitTest.mod_status == STATUS.approved">Approved</i>
									<i v-if="fitTest.mod_status == STATUS.rejected">rejected</i>
									<span></span>
								</div>
								<div class="moderation-actions">
									<button class="btn btn-warning btn-md"  v-on:click="review(fitTest)">Review</button>
									<button class="btn btn-success btn-md"  v-on:click="approved(fitTest)">Approved</button>
									<button class="btn btn-danger btn-md" v-on:click="reject(fitTest)">Reject</button>
									<button class="btn btn-danger btn-md" v-on:click="save(fitTest)">Save</button>
								</div>
							</div>
						</div>
					</div>
				</div>
        <div class="row">
          <div class="col-xs-12">
            <pagination
              v-bind:active="!isLoading"
              v-bind:pagination="pagination"
              v-bind:onChangePage="onChangePage"
            />
          </div>
        </div>
			</div>
		</div>

    <!------ Model Video -------->
    <modal class="modal-video" v-model="showModalZiggeo" :header="false" :footer="false" size="lg">
      <i class="icon-close" @click="closeModal"></i>
      <div style="position: relative">
        <zig-player v-if="showModalZiggeo && currentZiggeoId" v-bind:pr-video-id="currentZiggeoId" v-bind:pr-container-id="'modal-i-detail-video'"></zig-player>
      </div>
    </modal>
    <!------ / Model Video -------->

		<modal class="modal-confirm modal-reject" v-model="modalReject" :footer="false" :header="false">
			<i class="icon-close" @click="cancelReject"></i>
			<p class="mgb-20">Please provide a description for the rejection. This description will be sent to the user
				so please make it as descriptive as possible outlining what they need to do to resolve the issue.</p>
			<div class="form-group">
				<label class="label-title">Enter message</label>
				<textarea class="form-control ui-form-control" v-bind:class="{'is-invalid': !msg_reject}" v-model="msg_reject"></textarea>
			</div>
			<button type="button" class="btn btn-primary left" v-on:click="confirmReject()">REJECT</button>
		</modal>
    <div class="loading-div" v-show="isLoading" style="opacity: 0.8">
      <icon-loading></icon-loading>
    </div>
	</div>
</template>

<script>
  import Navbar from '@/components/Navbar'
  import VideoPlay from '@/components/video/VideoPlay'
  import IconLoading from '@/components/global/IconLoading'
  import Pagination from '@/components/global/Pagination'
  import ZigPlayer from '@/components/video/ZigPlayer'
  import Select2 from '@/components/global/Select2'

  const STATUS = {
    unmoderated: 0,
    approved: 1,
    rejected: 2,
    review: 3,
  };

  export default {
    components: {IconLoading, Navbar, VideoPlay, ZigPlayer, Select2, Pagination},
    name: 'Moderation',
    data: function () {
      return {
        // global
        STATUS: STATUS,
        pagination: {},
        // flag for app
        isLoading: true,
        isReady: false,
        isLoadUserFitTest: false,
        loadedConfig: false,

        isMounted: false,

        currentZiggeoId: null,
        videoIframe: '',
        showModalZiggeo: false,
        modalReject: false,

        msg_reject: null,

        isShowNotify: false,
        notificationMessage: '',

        currentUserFitTest: null,
        userFitTest: [],
        userFitTestMeta: {
          total: null,
          last_page: null,
          current_page: null,
          has_pages: null,
          has_more_pages: null,
          is_empty: null,
        },
        search: '',
        status: null,
        page: 1,
        isOnChangePage: false
      }
    },
    methods: {

      init () {

        let vm = this
        if(vm.isLoadUserFitTest && vm.isMounted && vm.loadedConfig){
          vm.isReady = true
          vm.isLoading = false
        }

      },

      openVideoModal: function (videoId) {

        this.currentZiggeoId = videoId
        this.showModalZiggeo = true

      },

      closeModal () {
        this.currentZiggeoId = null
        this.showModalZiggeo = false
      },


      reject: function (userFitTest) {
        let vm = this
        vm.currentUserFitTest = userFitTest
        vm.showModalReject()
      },

      getFitTestBase () {
        let obj = {
          'burpees': {
            target: 25,
            max_points: 100
          },
          'jump_squats': {
            target: 45,
            max_points: 100
          },
          'push_ups': {
            target: 50,
            max_points: 100
          },
          'mountain_climbers': {
            target: 150,
            max_points: 100
          },
          'lateral_shoot_throughs': {
            target: 60,
            max_points: 100
          },
          'plank': {
            target: 60,
            max_points: 50
          }
        }

        return obj
      },

      getUserFitTestScore (tests) {

        let userPoint = 0
        let base = this.getFitTestBase()

        for (let i = 0; i < tests.length; i++) {
          if (base.hasOwnProperty(tests[i].key)) {
            userPoint += parseFloat(tests[i].score || 0) * base[tests[i].key].max_points / base[tests[i].key].target
          }
        }

        return Math.round(userPoint)
      },

      async save (userFitTest) {
        console.log(userFitTest);
        let vm = this

        let tests = []

        tests.push(userFitTest.tests.burpees)
        tests.push(userFitTest.tests.jump_squats)
        tests.push(userFitTest.tests.push_ups)
        tests.push(userFitTest.tests.mountain_climbers)
        tests.push(userFitTest.tests.lateral_shoot_throughs)
        tests.push(userFitTest.tests.plank)

        let data = {
          user_id: userFitTest.user_id,
          fit_test_id: userFitTest.fit_test_id,
          video_id: userFitTest.video_id,
          mod_status: userFitTest.mod_status,
          tests: tests,
          user_fit_test_id: userFitTest.id
        }

        vm.isLoading = true;
        await vm.$services.User.updateUserFitTest(data).then(res => {
          vm.isLoading = false;
          vm.showMessage('Save Successfully!')
        }, err => {
          vm.isLoading = false;
          vm.showMessage('Save Fail!')
        });

      },
      onChangePage (pageNumber){
        let vm = this
        vm.page = pageNumber;
        const query = {...this.$route.query};

        query['page'] = pageNumber

        this.$router.replace({ query: { ...query} });

        // this.$router.replace({ query: { ...this.$route.query, page: pageNumber } });
      },
      onSelectStatus (e) {
        let vm = this;

        // console.log('status: ', e.params.data);

        let fitTestStatus = e.params.data.id;

        const query = {...this.$route.query};
        if (typeof query['page'] !== "undefined") {
          delete query['page'];
        }

        this.$router.replace({ query: { ...query, status: fitTestStatus } });

      },

      async review(userFitTest){

        let vm = this

        userFitTest.mod_status = STATUS.review

        let tests = []

        tests.push(userFitTest.tests.burpees)
        tests.push(userFitTest.tests.jump_squats)
        tests.push(userFitTest.tests.push_ups)
        tests.push(userFitTest.tests.mountain_climbers)
        tests.push(userFitTest.tests.lateral_shoot_throughs)
        tests.push(userFitTest.tests.plank)

        let data = {
          user_id: userFitTest.user_id,
          fit_test_id: userFitTest.fit_test_id,
          video_id: userFitTest.video_id,
          mod_status: userFitTest.mod_status,
          tests: tests,
          user_fit_test_id: userFitTest.id
        }

        vm.isLoading = true
        await vm.$services.User.updateUserFitTest(data).then(res => {
          vm.isLoading = false
          vm.showMessage('Change status to review!')
        }, err => {
          vm.isLoading = false
          vm.showMessage('Change status to review Fail!')
        });
      },
      clearFilter () {
        const query = {...this.$route.query};
        if (typeof query['status'] !== "undefined") {
          delete query['status'];
        }
        if (query['page']) {
          delete query['page'];
        }
        this.status = undefined;
        this.search = '';
        this.page = 1;
        this.$refs.select2.clear();
        this.$router.replace({ query: query });
      },
      async approved (userFitTest) {

        let vm = this

        userFitTest.mod_status = STATUS.approved

        let tests = []

        tests.push(userFitTest.tests.burpees)
        tests.push(userFitTest.tests.jump_squats)
        tests.push(userFitTest.tests.push_ups)
        tests.push(userFitTest.tests.mountain_climbers)
        tests.push(userFitTest.tests.lateral_shoot_throughs)
        tests.push(userFitTest.tests.plank)

        let data = {
          user_id: userFitTest.user_id,
          fit_test_id: userFitTest.fit_test_id,
          video_id: userFitTest.video_id,
          mod_status: userFitTest.mod_status,
          tests: tests,
          user_fit_test_id: userFitTest.id
        }

        vm.isLoading = true
        await vm.$services.User.updateUserFitTest(data).then(res => {
          vm.isLoading = false
          vm.showMessage('Approved Successfully!')
        }, err => {
          vm.isLoading = false
          vm.showMessage('Approved Fail!')
        });

      },

      cancelReject () {
        let vm = this
        vm.modalReject = false
      },

      showModalReject () {
        let vm = this
        vm.modalReject = true
      },

      async confirmReject () {
        let vm = this

        if(vm.msg_reject){

          let userFitTest = vm.currentUserFitTest
          userFitTest.mod_status = STATUS.rejected

          let tests = []

          tests.push(userFitTest.tests.burpees)
          tests.push(userFitTest.tests.jump_squats)
          tests.push(userFitTest.tests.push_ups)
          tests.push(userFitTest.tests.mountain_climbers)
          tests.push(userFitTest.tests.lateral_shoot_throughs)
          tests.push(userFitTest.tests.plank)

          let data = {
            user_id: userFitTest.user_id,
            fit_test_id: userFitTest.fit_test_id,
            video_id: userFitTest.video_id,
            mod_status: userFitTest.mod_status,
            tests: tests,
            user_fit_test_id: userFitTest.id,
            mod_comment: vm.msg_reject
          }

          vm.cancelReject()
          vm.isLoading = true;
          await vm.$services.User.updateUserFitTest(data).then(res => {
            vm.isLoading = false
            // vm.cancelReject()
            vm.msg_reject = null;
            vm.currentUserFitTest = null
            vm.showMessage('Reject Successfully!')
          }, err => {
            vm.isLoading = false
            vm.showModalReject()
            vm.currentUserFitTest = null
            vm.showMessage('Reject Fail!')
          });
        }else{
          vm.showMessage('Please input reason reject fit test')
        }

      },

      showMessage (msg){
        let vm = this
        vm.isShowNotify = true
        this.notificationMessage = msg
        $('.notification').show()

        setTimeout(function () {
          vm.isShowNotify = false
          $('.notification').removeClass('active').hide()
        }, 3000)
      },

      getClassModerationStatus(status){

        switch (parseInt(status)){
          case 0:
            return 'unmoderated'
            break
          case 1:
            return 'approved'
            break
          case 2:
            return 'rejected'
            break
          case 3:
            return 'review'
            break
          default:
            return 'unmoderated'
        }
     },

      onChangeScore(userFitTest){

        let vm = this
        let tests = []

        tests.push(userFitTest.tests.burpees)
        tests.push(userFitTest.tests.jump_squats)
        tests.push(userFitTest.tests.push_ups)
        tests.push(userFitTest.tests.mountain_climbers)
        tests.push(userFitTest.tests.lateral_shoot_throughs)
        tests.push(userFitTest.tests.plank)

        userFitTest.total = vm.getUserFitTestScore(tests)

      },

      initUserFitTest (pageNumber, options = null){

        if (!pageNumber) pageNumber = 1;

        let defaultOptions = {
          init: false,
          search: null,
          status: null,
        }

        options = Object.assign(defaultOptions, options);

        let vm = this

        let page = (!isNaN(parseInt(pageNumber))) ? parseInt(pageNumber): 1

        let data = {
          is_paging: true,
          per_page: 10,
          page: page
        }

        if(options && options.search && options.search.length > 0){
          data.email = options.search
        }

        if(options && options.status !== null){
          data.status = options.status
        }

        vm.isLoading = true
        vm.$services.User.getUserFitTestList(data).then(response => {

          vm.userFitTestMeta.total = response.data.data.total
          vm.userFitTestMeta.last_page = response.data.data.last_page
          vm.userFitTestMeta.current_page = response.data.data.current_page
          vm.userFitTestMeta.has_pages = response.data.data.has_pages
          vm.userFitTestMeta.has_more_pages = response.data.data.has_more_pages
          vm.userFitTestMeta.is_empty = response.data.data.is_empty

          if(0 < response.data.data.items.length){


            vm.userFitTest = response.data.data.items.map((userFitTestItem, index) =>{

              if(userFitTestItem.mod_status === undefined){
                userFitTestItem.mod_status = STATUS.unmoderated;
              }

              if(!userFitTestItem.user){
                userFitTestItem.user = {}
                userFitTestItem.user.email = ''
                userFitTestItem.user.first_name = ''
                userFitTestItem.user.last_name = ''
              }

              userFitTestItem.user.info = ''
              if(userFitTestItem.user.email !== ''){
                userFitTestItem.user.info = userFitTestItem.user.first_name + ' '
              }

              if(userFitTestItem.user.last_name !== ''){
                userFitTestItem.user.info += userFitTestItem.user.last_name + ' '
              }

              if(userFitTestItem.user.email !== ''){
                userFitTestItem.user.info += '(' + userFitTestItem.user.email + ')'
              }

              userFitTestItem.tests.sort(function(a, b){return a.key-b.key});

              if(userFitTestItem.total === undefined){
                userFitTestItem.total = 0;
              }

              userFitTestItem.total = vm.getUserFitTestScore(userFitTestItem.tests)

              if(0 < userFitTestItem.tests.length){
                  let tests = {
                  burpees:{
                    key: 'burpees',
                    name: 'BURPEES',
                    score: 0
                  },
                  jump_squats:{
                    key: 'jump_squats',
                    name: 'JUMP SQUATS',
                    score: 0
                  },
                  push_ups:{
                    key: 'push_ups',
                    name: 'PUSH UPS',
                    score: 0
                  },
                  mountain_climbers:{
                    key: 'mountain_climbers',
                    name: 'MOUNTAIN CLIMBERS',
                    score: 0
                  },
                  lateral_shoot_throughs:{
                    key: 'lateral_shoot_throughs',
                    name: 'LATERAL SHOOT THROUGHS',
                    score: 0
                  },
                  plank:{
                    key: 'plank',
                    name: 'PLANK (SECONDS)',
                    score: 0
                  }
                }

                userFitTestItem.tests.map(testItem =>{

                  if(testItem.score === undefined){
                    testItem.score = 0
                  }

                  switch(testItem.key) {
                    case 'burpees':
                      tests.burpees = testItem
                      break;
                    case 'jump_squats':
                      tests.jump_squats = testItem
                      break;
                    case 'push_ups':
                      tests.push_ups = testItem
                      break;
                    case 'mountain_climbers':
                      tests.mountain_climbers = testItem
                      break;
                    case 'lateral_shoot_throughs':
                      tests.lateral_shoot_throughs = testItem
                      break;
                    case 'plank':
                      tests.plank = testItem
                      break;
                  }
                })

                userFitTestItem.tests = tests

              }

              return userFitTestItem;

            });


          }else{
            vm.userFitTest = []
          }

          this.pagination = {
            ...response.data.data,
            data: null
          }
          vm.isLoading = false

          if(vm.isOnChangePage === true){
            vm.isOnChangePage = false
          }

          if(options && options.init){
            vm.isLoadUserFitTest = true
            vm.init()
          }

        })
      },

      searchByEmail (){

        let vm = this

        let page = 1

        this.isOnChangePage = true;

        const query = {...this.$route.query};
        if (typeof query['page']) {
          delete query['page'];
        }

        this.$router.replace({ query: query });

        vm.initUserFitTest(page, {init:false, search: vm.getEmail, status: this.$route.query.status})

      },
      jumpToPage(pageNumber) {
        let vm = this
        let page = (!isNaN(parseInt(pageNumber))) ? parseInt(pageNumber): 1

        let email = vm.search
        email = email.replace(/^\s+|\s+$/gm,'')

        if(email&&email.length > 0){
          vm.initUserFitTest(page, {init:false, search: email, status: this.$route.query.status})
        }else{
          vm.initUserFitTest(page, {init:false, status: this.$route.query.status})
        }

      },

    },
    watch: {
      '$route': {
        handler (newVal, oldVal) {
          if(this.isOnChangePage !== true){
            if (newVal.query.status !== oldVal.query.status) {
              this.status = newVal.query.status;
              this.initUserFitTest(this.page, {init:false, status: this.status, search:this.getEmail})
            }
            if (newVal.query.page !== oldVal.query.page) {
              // console.log('newVal.query.page', newVal.query.page)
              this.page = parseInt(newVal.query.page);
              this.initUserFitTest(this.page, {init:false, status: this.status, search:this.getEmail})
            }
          }
        },
        deep: true
      }
    },
    computed: {
      getEmail: function (){
        let vm = this;
        let email = vm.search
        return email.replace(/^\s+|\s+$/gm,'')
      }
    },

    beforeCreate () {
      let vm = this
      console.log('beforeCreate')
    },
    created () {

      let vm = this
      console.log('created')

      if (!vm.$mcf.isDevelopmentMode) {
        vm.$http.get(vm.$mcf.api.config).then(response => {
          vm.$mcf.ziggeo.token = response.data.data.ziggeo.app_token;

          // console.log('vm.$mcf.ziggeo.token: ', vm.$mcf.ziggeo.token);

          vm.loadedConfig = true;
          vm.initUserFitTest(1, {init: true})
        }, () => {
          vm.loadedConfig = true;
          vm.initUserFitTest(1, {init: true})
        })
      } else {
        vm.loadedConfig = true;
        vm.initUserFitTest(1, {init: true})
      }
    },
    beforeMount(){
      let vm = this
      console.log('beforeMount')
      this.getStatus = {
        delay: 1000,
        transport: async (params, success, failure) => {
          try {
            const res = [
              {id: 0, name: 'Not yet Moderated'},
              {id: 1, name: 'Approved'},
              {id: 2, name: 'Rejected'},
              {id: 3, name: 'Review'},
            ]
            success(res)
          } catch (e) {
            failure(e)
          }
        },
        processResults: (data) => {
          const results = data.map((item) => ({
            id: item.id,
            text: item.name,
          }));
          return {
            results,
            pagination: {
              more: false
            }
          }
        },
        cache: true
      }
    },
    mounted () {

      let vm = this
      vm.isMounted = true
      vm.init()

      console.log('mounted')

    }
  }

</script>
